import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsService } from '../../../../shared/services/settings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-lier-profil',
  templateUrl: './lier-profil.component.html',
  styleUrls: ['./lier-profil.component.scss']
})
export class LierProfilComponent implements OnInit {
  listProfile: any = [];
  listAgence: any = [];
  listInspection: any = [];
  listDirection: any = [];
  arrayInspection: any = [];
  arrayAgence: any = [];
  arrayDirection: any = [];
  listUsers: any;
  response: any;
  selectedUtilisateur: any;
  idUserSelected: any;
  selectedProfile: any;
  profileSelected: any;
  selectedAgence: any;
  selectedInspection: any;
  selectedDirection: any;
  agenceSelected: any;
  loaderText: string;
  settingsProfile = {};
  settingsAgence = {};
  settingsUtilisateurs = {};
  settingsInspection = {};
  settingsDirection = {};
  slugProfil: any;
  is_agence: boolean = false;
  constructor(private _settings: SettingsService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getAllProfile();
    this.getAllUsers();
    this.getAllDirection();
    this.settingsProfile = {
      singleSelection: true,
      text: 'Sélectionner le profil',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'title',
      enableSearchFilter: true,
      searchBy: ['title'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucun profil disponible'
    };
    this.settingsAgence = {
      singleSelection: false,
      text: 'Sélectionner l\'agence',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_agence',
      enableSearchFilter: true,
      searchBy: ['nom_agence'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune agence disponible'
    };
    this.settingsUtilisateurs = {
      singleSelection: true,
      text: 'Sélectionner l\'utilisateur',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_prenoms',
      enableSearchFilter: true,
      searchBy: ['nom_prenoms'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucun utilisateur disponible'
    };
    this.settingsDirection = {
      singleSelection: false,
      text: 'Sélectionner direction',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_code',
      enableSearchFilter: true,
      searchBy: ['nom_code'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune direction disponible'
    };
    this.settingsInspection = {
      singleSelection: false,
      text: 'Sélectionner l\' inspection',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_code',
      enableSearchFilter: true,
      searchBy: ['nom_code'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune inspection disponible'
    };
  }

  lierProfil() {
    const data = {
      'id': this.idUserSelected,
      'roles_id': this.profileSelected.id,
      'directions' : this.arrayDirection,
      'agences': this.arrayAgence
    }
      this._settings.lierProfile(data)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
            this.selectedAgence = [];
            this.selectedInspection = [];
            this.selectedDirection = [];
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    }

  onSelectUtilisateur(item) {
    this.idUserSelected = item.id;
  }

  onSelectProfil(item) {
    this.is_agence = item.is_agence;
    this.profileSelected = item;
    this.slugProfil = item.slug;
    // this.selectedProfile = item;
  }

  onSelectAgence(item) {
    this.agenceSelected = item;
    this.arrayAgence.push(item.id);
  }

  onSelectInspection(item) {
    this.arrayInspection.push(item.id);
    const data = {
      'inspections' : this.arrayInspection
    }
    // this.getAllDirection(data);
  }

  onDeSelectItem(item, type) {
    if (type === 'profil') {
      this.profileSelected.id = ''
    } else if (type === 'direction') {
      for( var i = 0; i < this.arrayDirection.length; i++){ 
        if ( this.arrayDirection[i] === item.id) { 
          this.arrayDirection.splice(i, 1); 
        }
      }
      const data = {
        'directions' : this.arrayDirection
      }
      this.getAllAgence(data);
    } else {
      for( var i = 0; i < this.arrayAgence.length; i++){ 
        if ( this.arrayAgence[i] === item.id) { 
          this.arrayAgence.splice(i, 1); 
        }
      }
    }
  }

  onDeselectAllItem(item, type) {
    if (type === 'profil') {
      this.profileSelected.id = ''
    } else if (type === 'direction') {
      this.arrayDirection = [];
    } else {
      this.arrayAgence = [];
    }
  }

  onSelectDirection(item) {
    this.arrayDirection.push(item.id);
    const data = {
      'directions' : this.arrayDirection
    }
    this.getAllAgence(data);
  }



   // Get all profile
  getAllProfile() {
      this._settings.getAllProfil().subscribe((data: {}) => {
        this.response = data;
        this.listProfile = this.response.data;
      });
  }
  
    // Get all agence by Direction
    getAllAgence(data) {
      this._settings.getListAgenceByDirection(data).subscribe((data: {}) => {
        this.response = data;
        this.listAgence = this.response.data;
      });
    }

    // Get all inspection 
    getAllInspection() {
      this._settings.getAllInspection().subscribe((data: {}) => {
        this.response = data;
        this.listInspection = this.response.data;
        this.listInspection.forEach(element => {
          element.nom_code = `${element.nom} [${element.code}]`;
        });
      });
    }

    // Get all direction by Inspection
    getAllDirection() {
      this._settings.getAllDirection().subscribe((data: {}) => {
        this.response = data;
        this.listDirection = this.response.data;
        this.listDirection.forEach(element => {
          element.nom_code = `${element.nom} [${element.code}]`;
        });
      });
    }

  // Get all users
  getAllUsers() {
    this._settings.getAllUsers().subscribe((data: {}) => {
      this.response = data;
      this.listUsers = this.response.data;
    });
  }

}
