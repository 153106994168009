import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-deposit-details",
  templateUrl: "./deposit-details.component.html",
})
export class DepositDetailsComponent {
  @Input()
  public deposit;

  response: any;
  listAgence: any = [];
  constructor(private activeModal: NgbActiveModal) {}

  closeModal() {
    this.activeModal.close();
  }
}
