import {Typecompte} from './typecompte';

export class Compte {

  /*
   idCompte : number;
    typeCompte : Typecompte;
    codeIdentif : string ;

  codeInterneIdentif : string ;
  dateAncienSolde : string ;
  dateCreation : string ;
  dateDepot : string ;
  dateNewSolde : string ;
  dateValidite : string ;
  solde : string ;
  soldeAvant : string ;
  token : string ;
  sysJeuToken : string ;
  autresInfo : string ;
  autreSInfos : string ;
  systJeuToken : string ;
  type : string ;
  */
 libele : string ;
 codelibele : string ;
}
