import { Subscription } from 'rxjs';
import { AgenceService } from './../agence.service';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-popupagence',
  templateUrl: './popupagence.component.html',
  styleUrls: ['./popupagence.component.scss']
})
export class PopupagenceComponent implements OnInit {


  public itemForm: FormGroup;
  public getItemSub: Subscription;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupagenceComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private agenceService: AgenceService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
  }

  OnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe();
    }
  }

  buildItemForm(item) {

    this.itemForm = this.fb.group({
      agenceid: [item.agenceid || ''],
      agencecode: [item.agencecode || '', [Validators.required]],
      agencezoneregionale: [item.agencezoneregionale || '', [Validators.required]],
      //agencedesignation: [ item.agencedesignation || '', [Validators.required]],
      agencenom: [item.agencenom || '', [Validators.required]],
      agenceemail: [item.agenceemail || '', [Validators.required]],
      agencetelephone: [item.agencetelephone || '', [Validators.required]],
      agencelocalisation: [item.agencelocalisation || '', [Validators.required]]
    });
  }

  submit() {
    // Verifier si Ajout ou mise a jour
    if (this.data.payload.agenceid) {


      this.getItemSub = this.agenceService.updateAgence(this.itemForm.value)
        .subscribe(data => {

          switch (data.status) {

            case 201: {
              /*
              this.snackbar.open('Agence modifié', 'OK',
                  { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                  */
              this.openDialog("La modification a été effectuée avec succes");
              this.dialogRef.close(true);

              break;
            }

            case 204: {
              
              this.snackbar.open('Agence non trouvé, reessayé SVP', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close();

              break;
            }

            default: {

              break;
            }


          }
        },
          error => {

            switch (error.status) {

              case 409: {
                this.snackbar.open('Agence existe deja', 'OK',
                  { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                break;
              }
              default: {
                this.snackbar.open('Un erreur est survenue', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });


    } else {


      this.getItemSub = this.agenceService.createAgence(this.itemForm.value)
        .subscribe(data => {

          switch (data.status) {

            case 201: {
              this.snackbar.open('Agence crée', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close(true);

              break;
            }

            case 204: {
              this.snackbar.open('Agence non trouvé, reessayé SVP', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close();

              break;
            }

            default: {

              break;
            }


          }
        },
          error => {

            switch (error.status) {

              case 409: {
                this.snackbar.open('Agence existe deja', 'OK',
                  { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                break;
              }
              default: {
                this.snackbar.open('Un erreur est survenue', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });
    }
  }


  openDialog(value: string): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: value
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }

}
