import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EventEmitterService {
  invokeOnGetAllUsers = new EventEmitter();
  invokeOnGetAllInspection = new EventEmitter();
  invokeOnGetAllManager = new EventEmitter();
  invokeOnGetAllCaissier = new EventEmitter();
  invokeOnGetAllAgence = new EventEmitter();
  subsVar: Subscription;
  constructor() {
  }

  onGetAllUsers() {
    this.invokeOnGetAllUsers.emit();
  }

  onGetAllInspection() {
    this.invokeOnGetAllInspection.emit();
  }

  onGetAllManager() {
    this.invokeOnGetAllManager.emit();
  }

  onGetAllCaissier() {
        this.invokeOnGetAllCaissier.emit();
  }

  onGetAllAgence() {
    this.invokeOnGetAllAgence.emit();
  }

}
