export class GestionProfils {
  creation_profil: boolean;
  modification_profil: boolean;
  suppression_profil: boolean;
  visualisation_liste_profil: boolean;
  exportation_profil: boolean;
  activation_desactivation_profil: boolean;
  constructor(creation_profil: boolean, modification_profil: boolean, suppression_profil: boolean,
    visualisation_liste_profil: boolean, exportation_profil: boolean, activation_desactivation_profil: boolean) {
    this.creation_profil = creation_profil;
    this.modification_profil = modification_profil;
    this.suppression_profil = suppression_profil;
    this.visualisation_liste_profil = visualisation_liste_profil;
    this.exportation_profil = exportation_profil;
    this.activation_desactivation_profil = activation_desactivation_profil;
  }
}

export class GestionDirections {
  creation_direction: boolean;
  creation_masse_direction: boolean;
  modification_direction: boolean;
  suppression_direction: boolean;
  visualisation_liste_direction: boolean;
  exportation_direction: boolean;
  activation_desactivation_direction: boolean;
  constructor(creation_direction: boolean, creation_masse_direction:boolean, modification_direction: boolean, suppression_direction: boolean,
    visualisation_liste_direction: boolean, exportation_direction: boolean, activation_desactivation_direction: boolean) {
    this.creation_direction = creation_direction;
    this.creation_masse_direction = creation_masse_direction;
    this.modification_direction = modification_direction;
    this.suppression_direction = suppression_direction;
    this.visualisation_liste_direction = visualisation_liste_direction;
    this.exportation_direction = exportation_direction;
    this.activation_desactivation_direction = activation_desactivation_direction;
  }
}

export class GestionInspections {
  creation_inspection: boolean;
  creation_masse_inspection: boolean;
  modification_inspection: boolean;
  suppression_inspection: boolean;
  visualisation_liste_inspection: boolean;
  exportation_inspection: boolean;
  activation_desactivation_inspection: boolean;
  constructor(creation_inspection: boolean, creation_masse_inspection:boolean, modification_inspection: boolean, suppression_inspection: boolean,
    visualisation_liste_inspection: boolean, exportation_inspection: boolean, activation_desactivation_inspection: boolean) {
    this.creation_inspection = creation_inspection;
    this.creation_masse_inspection = creation_masse_inspection;
    this.modification_inspection = modification_inspection;
    this.suppression_inspection = suppression_inspection;
    this.visualisation_liste_inspection = visualisation_liste_inspection;
    this.exportation_inspection = exportation_inspection;
    this.activation_desactivation_inspection = activation_desactivation_inspection;
  }
}

export class GestionPisteAudite {
  visualisation_piste_audit: boolean;
  constructor(visualisation_piste_audit: boolean) {
    this.visualisation_piste_audit = visualisation_piste_audit;
  }
}

export class GestionUtilisateurs {
  creation_utilisateur: boolean;
  modification_utilisateur: boolean;
  suppression_utilisateur: boolean;
  creation_masse_utilisateur: boolean;
  visualisation_liste_utilisateur: boolean;
  exportation_utilisateur: boolean;
  activation_desactivation_utilisateur: boolean;
  lier_utilisateur: boolean;
  constructor(creation_utilisateur: boolean, modification_utilisateur: boolean, suppression_utilisateur: boolean,
    creation_masse_utilisateur: boolean, visualisation_liste_utilisateur: boolean, exportation_utilisateur: boolean,
    activation_desactivation_utilisateur: boolean, lier_utilisateur: boolean) {
    this.creation_utilisateur = creation_utilisateur;
    this.modification_utilisateur = modification_utilisateur;
    this.suppression_utilisateur = suppression_utilisateur;
    this.creation_masse_utilisateur = creation_masse_utilisateur;
    this.visualisation_liste_utilisateur = visualisation_liste_utilisateur;
    this.exportation_utilisateur = exportation_utilisateur;
    this.activation_desactivation_utilisateur = activation_desactivation_utilisateur;
    this.lier_utilisateur = lier_utilisateur;
  }
}

export class GestionAgences {
  creation_agence: boolean;
  modification_agence: boolean;
  suppression_agence: boolean;
  creation_masse_agence: boolean;
  visualisation_liste_agence: boolean;
  exportation_agence: boolean;
  activation_desactivation_agence: boolean;
  constructor(creation_agence: boolean, modification_agence: boolean, suppression_agence: boolean,
    creation_masse_agence: boolean, visualisation_liste_agence: boolean, exportation_agence: boolean,
    activation_desactivation_agence: boolean) {
    this.creation_agence = creation_agence;
    this.modification_agence = modification_agence;
    this.suppression_agence = suppression_agence;
    this.creation_masse_agence = creation_masse_agence;
    this.visualisation_liste_agence = visualisation_liste_agence;
    this.exportation_agence = exportation_agence;
    this.activation_desactivation_agence = activation_desactivation_agence;
  }
}

export class GestionGestionnaires {
  creation_gestionnaire: boolean;
  modification_gestionnaire: boolean;
  suppression_gestionnaire: boolean;
  creation_masse_gestionnaire: boolean;
  visualisation_liste_gestionnaire: boolean;
  exportation_gestionnaire: boolean;
  activation_desactivation_gestionnaire: boolean;
  constructor(creation_gestionnaire: boolean, modification_gestionnaire: boolean, suppression_gestionnaire: boolean,
    creation_masse_gestionnaire: boolean, visualisation_liste_gestionnaire: boolean, exportation_gestionnaire: boolean,
    activation_desactivation_gestionnaire: boolean) {
    this.creation_gestionnaire = creation_gestionnaire;
    this.modification_gestionnaire = modification_gestionnaire;
    this.suppression_gestionnaire = suppression_gestionnaire;
    this.creation_masse_gestionnaire = creation_masse_gestionnaire;
    this.visualisation_liste_gestionnaire = visualisation_liste_gestionnaire;
    this.exportation_gestionnaire = exportation_gestionnaire;
    this.activation_desactivation_gestionnaire = activation_desactivation_gestionnaire;
  }
}

export class GestionCaissier {
  creation_caissier: boolean;
  modification_caissier: boolean;
  suppression_caissier: boolean;
  creation_masse_caissier: boolean;
  visualisation_liste_caissier: boolean;
  exportation_caissier: boolean;
  activation_desactivation_caissier: boolean;
  constructor(creation_caissier: boolean, modification_caissier: boolean, suppression_caissier: boolean,
    creation_masse_caissier: boolean, visualisation_liste_caissier: boolean, exportation_caissier: boolean,
    activation_desactivation_caissier: boolean) {
    this.creation_caissier = creation_caissier;
    this.modification_caissier = modification_caissier;
    this.suppression_caissier = suppression_caissier;
    this.creation_masse_caissier = creation_masse_caissier;
    this.visualisation_liste_caissier = visualisation_liste_caissier;
    this.exportation_caissier = exportation_caissier;
    this.activation_desactivation_caissier = activation_desactivation_caissier;
  }
}

export class VueGlobale {
  visualisation_liste_agence: boolean;
  visualisation_liste_gestionnaire: boolean;
  visualisation_liste_caissier: boolean;
  constructor(visualisation_liste_agence: boolean, visualisation_liste_gestionnaire: boolean,
  visualisation_liste_caissier: boolean) {
    this.visualisation_liste_agence = visualisation_liste_agence;
    this.visualisation_liste_gestionnaire = visualisation_liste_gestionnaire;
    this.visualisation_liste_caissier = visualisation_liste_caissier;
  }
}

export class OperationPaiement {
  infos_assure: boolean;
  liste_assure_couvert: boolean;
  liste_cotisation_assure: boolean;
  effectuer_paiement: boolean;
  generer_recu_paiement: boolean;
  constructor(infos_assure: boolean, liste_assure_couvert: boolean, liste_cotisation_assure: boolean,
    effectuer_paiement: boolean, generer_recu_paiement: boolean) {
    this.infos_assure = infos_assure;
    this.liste_assure_couvert = liste_assure_couvert;
    this.liste_cotisation_assure = liste_cotisation_assure;
    this.effectuer_paiement = effectuer_paiement;
    this.generer_recu_paiement = generer_recu_paiement;
  }
}

export class HistoriqueVentes {
  visualisation_historique_vente: boolean;
  recherche_vente_date: boolean;
  exportation_vente: boolean;
  reedition_recu_paiement: boolean;
  constructor(visualisation_historique_vente: boolean, recherche_vente_date: boolean, exportation_vente: boolean,
    reedition_recu_paiement: boolean) {
    this.visualisation_historique_vente = visualisation_historique_vente;
    this.recherche_vente_date = recherche_vente_date;
    this.exportation_vente = exportation_vente;
    this.reedition_recu_paiement = reedition_recu_paiement;
  }
}
