import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Transactionitem} from '../../shared/models/transactionitem';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Wallet} from '../../shared/models/wallet';
import {Servicce} from '../../shared/models/servicce';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {DashboardService} from '../../shared/services/dashboard.service';
import {AppConfirmService} from '../../shared/services/app-confirm/app-confirm.service';
import {AppLoaderService} from '../../shared/services/app-loader/app-loader.service';
import {Router} from '@angular/router';
import {Utilisateur} from '../../shared/models/utilisateur';
import {Granularite} from '../../shared/models/granularite';
import {DatePipe} from '@angular/common';
import {Pmurequest} from '../../shared/models/pmurequest';
import {Genreoperation} from '../../shared/models/genreoperation';
import {Valeur} from '../../shared/models/valeur';
import {Statrequest} from '../../shared/models/statrequest';
import {Compte} from '../../shared/models/compte';

import {Statresult} from '../../shared/models/statresult';
import {Transactiontotal} from '../../shared/models/transactiontotal';
import {Fonctionnalite} from '../../shared/models/fonctionnalite';
import {UserIdleService} from 'angular-user-idle';
import { Servicse } from '../../shared/models/servicse';
import { Walet } from '../../shared/models/walet';

@Component({
  selector: 'app-statop',
  templateUrl: './statop.component.html',
  styleUrls: ['./statop.component.scss']
})
export class StatopComponent implements OnInit {



  mois : string = "month" ;

  granularitejour : Boolean = false ;
  granularitemois : Boolean = false ;
  granulariteannee : Boolean = false ;

  itemServicse : Servicse[] = [] ;

  itemWal : Walet[] = [] ;



  stattranche : number = 0 ;
  statLabel : string[] = [];
  statDatarecu : number[] =  [];
  statDataenv : number[] = [];

  operateur : string = " ";

  dataSource: MatTableDataSource<Transactionitem>= new MatTableDataSource([]);
  renderedData: any;


  public itemsTransactionGenerale: Transactionitem[]=[];
  public total: Transactiontotal = new Transactiontotal();
  temp = [];
  public itemFormDay: FormGroup;
  public itemFormMonth: FormGroup;
  public itemFormYear: FormGroup;

  public itemsWallet: Wallet[];
  public itemsServicce: Servicce[];
  public itemsGranularite: Granularite[];
  public statday : boolean = false ;
  public statyear : boolean = false ;
  public statmonth : boolean = false ;
  public statchoose : string = "";

  public dropdownGran : Compte[] =[];

  public selectedCompte : Compte = new Compte ();

  public typegran : string ="date";

  constructor(private dialog: MatDialog,
              private snack: MatSnackBar,
              private dashboardService: DashboardService,
              private confirmService: AppConfirmService,
              private loader: AppLoaderService,
              private cdr: ChangeDetectorRef,
              public router: Router,
              private fb: FormBuilder,
              private datePipe: DatePipe,
              private userIdle: UserIdleService
  ) {
    this.getService();
    this.getWallet();
    this.getGranularity();
    this.getGranulityForDropdown();
    this.getItemGetServces() ;
  }

  getItemGetServces() {
    let services : Servicse[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservics"));
    this.itemServicse=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservics"))
    ;
  }

  sharedChartOptions: any = {
    responsive: true,
    // maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'bottom'
    }
  };
  chartColors: Array <any> = [{
    backgroundColor: '#3f51b5',
    borderColor: '#3f51b5',
    pointBackgroundColor: '#3f51b5',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }, {
    backgroundColor: 'red',
    borderColor: '#e0e0e0',
    pointBackgroundColor: '#e0e0e0',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(77,83,96,1)'
  }, {
    backgroundColor: 'rgba(148,159,177,0.2)',
    borderColor: 'rgba(148,159,177,1)',
    pointBackgroundColor: 'rgba(148,159,177,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  }];
  /*
  * Bar Chart
  */
  barChartLabels = this.statLabel;
  barChartType = 'bar';
  barChartLegend = true;
  barChartData: any[] = [{
    data: this.statDatarecu,
    label: 'Recus',
    borderWidth: 0
  }, {
    data: this.statDataenv,
    label: 'Envoye',
    borderWidth: 0

  }];
  barChartOptions: any = Object.assign({
    scaleShowVerticalLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.02)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'rgba(0,0,0,0.02)',
          zeroLineColor: 'rgba(0,0,0,0.02)'
        },
        position: 'left',
        ticks: {
          beginAtZero: true,
          //suggestedMax: 20,
          stepSize: this.stattranche,
        }
      }]
    }
  }, this.sharedChartOptions);

  ngOnInit() {

    let c1 : Compte = new Compte();
    c1.libele="JOUR";
    c1.codelibele="day";
    this.selectedCompte=c1 ;

    this.getGranulityForDropdown();
    this.getItemGetServces() ;

    this.itemFormDay = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'debut': [null, Validators.compose([Validators.required])],
      'fin': [null, Validators.compose([Validators.required])],
      'granularite': [null, Validators.compose([Validators.required])],
    });

    this.itemFormMonth = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'debut': [null, Validators.compose([Validators.required])],
      'fin': [null, Validators.compose([Validators.required])],
      'granularite': ['month', Validators.compose([Validators.required])],

    });

    this.itemFormYear = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'debut': [null, Validators.compose([Validators.required])],
      'fin': [null, Validators.compose([Validators.required])],
      'granularite': ['year', Validators.compose([Validators.required])],

    });





    this.itemFormDay.controls['granularite'].valueChanges.subscribe((value) => {
      console.log(" granularitelibele " );
      console.log( this.itemFormDay.get('granularite').value);
      let c : Compte = this.itemFormDay.get('granularite').value ;
      console.log(" compte ");
      console.log(c);
      if(c.codelibele=="day"){
        this.typegran="date";
      }
      if(c.codelibele=="month"){
        this.typegran="month";
      }
      if(c.codelibele=="year"){
        this.typegran="number";
      }

    })

    this.itemFormDay.controls['services'].valueChanges.subscribe((value) => {
      console.log(" services " ) ;
      console.log(this.itemFormDay.get('services').value);
      let srv : Servicse = this.itemFormDay.get('services').value ;
      console.log(" active_wallets " ) ;
     let ws : Walet[] = srv.active_wallets ;
     this.itemWal=[]     ;
      this.itemWal=ws ;
      let w : Walet = new Walet ;
      w.name="Tous les wallets";
      w.authentication_token="all" ;
      this.itemWal.push(w);
      console.log(this.itemWal);
    })

    this.getService();
    this.getWallet();
    this.getGranularity();

    this.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      console.log(count);
    });

    this.userIdle.onTimeout().subscribe(() => {
      console.log('Time is up!');
      // Rediriger l'utilisateur sur le login et le deconnecter
      this.router.navigate(['/seconnecter']);
      // stop
      this.stopWatching();
      this.restart();
      this.stop();
    });
  }


  exportCsv(){
    if(this.itemsTransactionGenerale.length>0){
      this.dataSource.connect().subscribe(d => this.renderedData = d);
      new Angular5Csv(this.renderedData,'Report');
      this.snack.open('statistique succes ! ', 'OK', { duration: 4000 });
    }else{
      this.snack.open('aucune statistique disponible ! ', 'OK', { duration: 4000 });
    }
  }


  getService(){
    let services : Servicce[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservice"));
    this.itemsServicce=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservice"));
  }

  getWallet(){
    let wallets : Wallet[] ;
    wallets = [] ;
    wallets=JSON.parse(localStorage.getItem("allwallet"));
    this.itemsWallet=wallets;
    let w : Wallet ;
    w = new Wallet();
    w.walletlibele="Tous les wallets"
    w.wallettoken="all";
    wallets.push(w);
    console.log("wallet taille : "+this.itemsWallet.length);
  }

  getGranularity(){
    let granularites : Granularite[] ;
    granularites = [] ;
    granularites=JSON.parse(localStorage.getItem("allgranularity"));
    this.itemsGranularite=granularites;
    console.log(" taille des granularite : "+this.itemsGranularite.length);
  }

  getGranulityForDropdown(){
    let cptes : Compte[];
    cptes=[];
    let c : Compte = new Compte();
    c.libele="JOUR";
    c.codelibele="day";

    let c1 : Compte = new Compte();
    c1.libele="MOIS";
    c1.codelibele="month";

    let c2 : Compte = new Compte();
    c2.libele="ANNEE";
    c2.codelibele="year";

    cptes.push(c);
    cptes.push(c1);
    cptes.push(c2);
    this.dropdownGran= cptes;
  }

  reloadall(){

  }


  sendRequestGranularityDay(){

    this.statLabel=[];
    this.statDataenv=[];
    this.statDatarecu=[];
    this.stattranche=0;
    this.statday=false;
    this.statmonth=false;
    this.statyear=false;

    console.log(this.itemFormDay.value);
    if (this.itemFormDay.valid) {

      let date = new Date(this.itemFormDay.get("debut").value);
      let date1 = new Date(this.itemFormDay.get("fin").value);

      console.log("debut : "+date+" ; fin  : "+date1);

      let from : string ="";
      let to : string ="";



      if((date.toString()!='Invalid Date')){
        from  = this.itemFormDay.get("debut").value;
      }

      if(date1.toString()!=null){
        to  = this.itemFormDay.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);


      console.log(" date "+newDate);
      console.log(" date1 "+newDate1);

      if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      }else{
        if(newDate1>=newDate){
          console.log(" debut : "+this.datePipe.transform(newDate,"dd/MM/yyyy"));
          console.log(" fin : "+this.datePipe.transform(newDate1,"dd/MM/yyyy"));
          let req : Statrequest = new Statrequest();

          req.from=this.datePipe.transform(newDate,"dd/MM/yyyy");
          req.to=this.datePipe.transform(newDate1,"dd/MM/yyyy");

         // let wall : Wallet = this.itemFormDay.get("wallet").value;
          //let serv : Servicce = this.itemFormDay.get("services").value;

          req.granularity="day";

           let wall : Walet = this.itemFormDay.get("wallet").value;
          let serv : Servicse = this.itemFormDay.get("services").value;
          req.service_token=serv.token;
          req.wallet_token=wall.authentication_token;

          this.loader.open();
          console.log(req);
          if(req!=null){
            this.dashboardService.sendStatistiqueOperation(req)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      this.statchoose="day";
                      console.log(data);
                      let ts : Statresult = new Statresult();
                      ts=data.body;
                      if(ts!=null){
                        this.itemsTransactionGenerale=[];
                        this.itemsTransactionGenerale=ts.transactions;
                        this.total=ts.total;
                        this.dataSource = new MatTableDataSource(this.itemsTransactionGenerale);

                      }
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }
                    case 201: {
                      this.statchoose="day";
                      console.log(data);
                      let ts : Statresult = new Statresult();
                      ts=data.body;
                      if(ts!=null){
                        this.itemsTransactionGenerale=[];
                        this.itemsTransactionGenerale=ts.transactions;
                        this.total=ts.total;

                      }
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }

                  }
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch(error.status){

                    default: {
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                      break;
                    }
                  }

                }

              )
          }
          this.reloadall();
        }else{
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }


    }
    this.reloadall();
  }

  sendRequestGranularityYear(){
    this.statLabel=[];
    this.statDataenv=[];
    this.statDatarecu=[];
    this.stattranche=0;
    this.statday=false;
    this.statmonth=false;
    this.statyear=false;

    console.log(this.itemFormDay.value);
    if (this.itemFormDay.valid) {

      let req : Statrequest = new Statrequest();

      if(this.itemFormDay.get("fin").value>=this.itemFormDay.get("debut").value){
        req.from=this.itemFormDay.get("debut").value;
        req.to=this.itemFormDay.get("fin").value;

        /*
        let wall : Wallet = this.itemFormDay.get("wallet").value;
        let serv : Servicce = this.itemFormDay.get("services").value;
        req.granularity="year";
        req.service_token=serv.servicetoken;
        req.wallet_token=wall.wallettoken;
        */
       req.granularity="year";

        let wall : Walet = this.itemFormDay.get("wallet").value;
        let serv : Servicse = this.itemFormDay.get("services").value;
        req.service_token=serv.token;
        req.wallet_token=wall.authentication_token;
        this.loader.open();
        if(req!=null){
          this.dashboardService.sendStatistiqueOperation(req)
            .subscribe(data => {
                switch (data.status) {
                  case 200: {
                    this.statchoose="year";
                    console.log(data);
                    let ts : Statresult = new Statresult();
                    ts=data.body;
                    if(ts!=null){
                      this.itemsTransactionGenerale=[];
                      this.itemsTransactionGenerale=ts.transactions;
                      this.total=ts.total;
                      this.dataSource = new MatTableDataSource(this.itemsTransactionGenerale);
                    }
                    this.reloadall();
                    this.loader.close();
                    this.snack.open('Succes !', 'OK', { duration: 4000 });

                    break;
                  }
                  case 201: {
                    this.statchoose="year";
                    console.log(data);
                    let ts : Statresult = new Statresult();
                    ts=data.body;
                    if(ts!=null){
                      this.itemsTransactionGenerale=[];
                      this.itemsTransactionGenerale=ts.transactions;
                      this.total=ts.total;
                      this.dataSource = new MatTableDataSource(this.itemsTransactionGenerale);
                    }
                    this.reloadall();
                    this.loader.close();
                    this.snack.open('Succes !', 'OK', { duration: 4000 });

                    break;
                  }

                }
              },
              error => {
                console.log('data');
                console.log(error);
                switch(error.status){

                  default: {
                    this.reloadall();
                    this.loader.close();
                    this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                    break;
                  }
                }

              }

            )
        }
      }else{
        this.snack.open('saisie d année incorrecte !', 'OK', { duration: 4000 });

      }

    }
    this.reloadall();
  }

  sendRequestGranularityMonth(){
    this.statLabel=[];
    this.statDataenv=[];
    this.statDatarecu=[];
    this.stattranche=0;
    this.statday=false;
    this.statmonth=false;
    this.statyear=false;

    console.log(this.itemFormDay.value);
    if (this.itemFormDay.valid) {

      let date = new Date(this.itemFormDay.get("debut").value);
      let date1 = new Date(this.itemFormDay.get("fin").value);

      console.log("debut : "+date+" ; fin  : "+date1);

      let from : string ="";
      let to : string ="";


      if((date.toString()!='Invalid Date')){
        from  = this.itemFormDay.get("debut").value;
      }

      if(date1.toString()!=null){
        to  = this.itemFormDay.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);


      console.log(" date "+newDate);
      console.log(" date1 "+newDate1);

      if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      }else{
        if(newDate1>=newDate){
          console.log(" debut : "+this.datePipe.transform(newDate,"MM/yyyy"));
          console.log(" fin : "+this.datePipe.transform(newDate1,"MM/yyyy"));
          let req : Statrequest = new Statrequest();

          req.from=this.datePipe.transform(newDate,"MM/yyyy");
          req.to=this.datePipe.transform(newDate1,"MM/yyyy");

          /*
          let wall : Wallet = this.itemFormDay.get("wallet").value;
          let serv : Servicce = this.itemFormDay.get("services").value;

          req.granularity="month";
          req.service_token=serv.servicetoken;
          req.wallet_token=wall.wallettoken;
          */

         req.granularity="month";

          let wall : Walet = this.itemFormDay.get("wallet").value;
          let serv : Servicse = this.itemFormDay.get("services").value;
          req.service_token=serv.token;
          req.wallet_token=wall.authentication_token;

          this.loader.open();
          console.log(req);
          if(req!=null){

            this.dashboardService.sendStatistiqueOperation(req)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      this.statchoose="month";
                      console.log(data);
                      let ts : Statresult = new Statresult();
                      ts=data.body;
                      if(ts!=null){
                        this.itemsTransactionGenerale=[];
                        this.itemsTransactionGenerale=ts.transactions;
                        this.total=ts.total;
                        this.dataSource = new MatTableDataSource(this.itemsTransactionGenerale);
                      }
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });

                      break;
                    }
                    case 201: {
                      this.statchoose="month";
                      console.log(data);
                      let ts : Statresult = new Statresult();
                      ts=data.body;
                      if(ts!=null){
                        this.itemsTransactionGenerale=[];
                        this.itemsTransactionGenerale=ts.transactions;
                        this.total=ts.total;
                        this.dataSource = new MatTableDataSource(this.itemsTransactionGenerale);
                      }
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });

                      break;
                    }

                  }
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch(error.status){

                    default: {
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                      break;
                    }
                  }

                }

              )
          }

        }else{
          this.reloadall();
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }


    }
    this.reloadall();
  }


  generateStat(){
    this.snack.open('Statistique generé ! veuillez consulter le pied de page !', 'OK', { duration: 4000 });

    this.statLabel=[];
    this.statDataenv=[];
    this.statDatarecu=[];
    if(this.statchoose.trim()=="day"){
      console.log(" debut des ordonnées du graphes");
      console.log(" For day ");

      this.getGranularity();
      let mapGran: Map<string, Granularite>;
      mapGran = new Map();
      for (let g of this.itemsGranularite) mapGran.set(g.granularitelibele.trim(), g);
     let gr : Granularite = mapGran.get('day');
     let tranche : string = gr.granularitevalue.trim();
     this.stattranche=  parseInt(tranche, 10);
     for(let it of this.itemsTransactionGenerale){
       this.statLabel.push(it.times.trim());
       this.statDataenv.push(parseInt(it.psamount , 10));
       this.statDatarecu.push(parseInt(it.pgamount , 10));
     }
      this.barChartLabels= this.statLabel ;
      this.barChartData = [{
        data: this.statDatarecu,
        label: 'Recus',
        borderWidth: 0
      }, {
        data: this.statDataenv,
        label: 'Envoye',
        borderWidth: 0

      }] ;

      this.barChartOptions = Object.assign({
        scaleShowVerticalLines: false,
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0,0,0,0.02)',
              zeroLineColor: 'rgba(0,0,0,0.02)'
            }
          }],
          yAxes: [{
            gridLines: {
              color: 'rgba(0,0,0,0.02)',
              zeroLineColor: 'rgba(0,0,0,0.02)'
            },
            position: 'left',
            ticks: {
              beginAtZero: true,
              //suggestedMax: 20,
              stepSize: this.stattranche,
            }
          }]
        }
      }, this.sharedChartOptions);
        console.log("stat ");
      console.log(this.statLabel);
      console.log(this.statDataenv);
      console.log(this.statDatarecu);
      console.log(this.barChartLabels);
      this.statday=true;
     this.statmonth=false;
     this.statyear=false;
     console.log("statistique tranche : "+this.stattranche);
    }

    if(this.statchoose.trim()=="month"){
      this.getGranularity();
      let mapGran: Map<string, Granularite>;
      mapGran = new Map();
      for (let g of this.itemsGranularite) mapGran.set(g.granularitelibele.trim(), g);
      let gr : Granularite = mapGran.get('month');
      let tranche : string = gr.granularitevalue.trim();
      this.stattranche=  parseInt(tranche, 10);
      for(let it of this.itemsTransactionGenerale){
        this.statLabel.push(it.times.trim());
        this.statDataenv.push(parseInt(it.psamount , 10));
        this.statDatarecu.push(parseInt(it.pgamount , 10));
      }
      this.barChartLabels= this.statLabel ;
      this.barChartData = [{
        data: this.statDatarecu,
        label: 'Recus',
        borderWidth: 0
      }, {
        data: this.statDataenv,
        label: 'Envoye',
        borderWidth: 0

      }] ;

      this.barChartOptions = Object.assign({
        scaleShowVerticalLines: false,
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0,0,0,0.02)',
              zeroLineColor: 'rgba(0,0,0,0.02)'
            }
          }],
          yAxes: [{
            gridLines: {
              color: 'rgba(0,0,0,0.02)',
              zeroLineColor: 'rgba(0,0,0,0.02)'
            },
            position: 'left',
            ticks: {
              beginAtZero: true,
              //suggestedMax: 20,
              stepSize: this.stattranche,
            }
          }]
        }
      }, this.sharedChartOptions);
      console.log("stat ");
      console.log(this.statLabel);
      console.log(this.statDataenv);
      console.log(this.statDatarecu);
      console.log(this.barChartLabels);
      this.statday=false;
      this.statmonth=true;
      this.statyear=false;
    }

    if(this.statchoose.trim()=="year"){
      this.getGranularity();
      let mapGran: Map<string, Granularite>;
      mapGran = new Map();
      for (let g of this.itemsGranularite) mapGran.set(g.granularitelibele.trim(), g);
      let gr : Granularite = mapGran.get('year');
      let tranche : string = gr.granularitevalue.trim();
      this.stattranche=  parseInt(tranche, 10);
      for(let it of this.itemsTransactionGenerale){
        this.statLabel.push(it.times.trim());
        this.statDataenv.push(parseInt(it.psamount , 10));
        this.statDatarecu.push(parseInt(it.pgamount , 10));
      }
      this.barChartLabels= this.statLabel ;
      this.barChartData = [{
        data: this.statDatarecu,
        label: 'Recus',
        borderWidth: 0
      }, {
        data: this.statDataenv,
        label: 'Envoye',
        borderWidth: 0

      }] ;

      this.barChartOptions = Object.assign({
        scaleShowVerticalLines: false,
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0,0,0,0.02)',
              zeroLineColor: 'rgba(0,0,0,0.02)'
            }
          }],
          yAxes: [{
            gridLines: {
              color: 'rgba(0,0,0,0.02)',
              zeroLineColor: 'rgba(0,0,0,0.02)'
            },
            position: 'left',
            ticks: {
              beginAtZero: true,
              //suggestedMax: 20,
              stepSize: this.stattranche,
            }
          }]
        }
      }, this.sharedChartOptions);
      console.log("stat ");
      console.log(this.statLabel);
      console.log(this.statDataenv);
      console.log(this.statDatarecu);
      console.log(this.barChartLabels);
      this.statday=false;
      this.statmonth=false;
      this.statyear=true;
    }

  }



  sts : number = 50000000  ;


  stop() {
    console.log('stop timer');
    this.userIdle.stopTimer();
  }

  stopWatching() {
    console.log('stop watching');
    this.userIdle.stopWatching();
  }

  startWatching() {
    console.log('start watching');
    this.userIdle.startWatching();
  }

  restart() {
    console.log('restart timer');
    this.userIdle.resetTimer();
  }


  public applyFilter(filterValue: string) {
    console.log(" applyFilter ");
    console.log( filterValue );

  }


  sendRequestByGranularity(){
    this.selectedCompte=this.itemFormDay.get('granularite').value ;


    if (this.itemFormDay.valid) {

      let wall : Wallet = this.itemFormDay.get("wallet").value;

      if(wall!=null){
        this.operateur=wall.walletlibele;
      }

      if(this.selectedCompte!=null && (this.selectedCompte.codelibele=="month" || this.selectedCompte.codelibele=="day" ) ){

        if(this.selectedCompte.codelibele=="month"){
          this.sendRequestGranularityMonth();
        }else if(this.selectedCompte.codelibele=="day"){
          this.sendRequestGranularityDay();
        }

      }else if(this.selectedCompte!=null && (this.selectedCompte.codelibele=="year")){
        console.log(" year");
        console.log(this.selectedCompte.codelibele);
        this.sendRequestGranularityYear();

      }

    }

  }









  sendRequestPaginator(x:number){

    this.statLabel=[];
    this.statDataenv=[];
    this.statDatarecu=[];
    this.stattranche=0;
    this.statday=false;
    this.statmonth=false;
    this.statyear=false;

    console.log(this.itemFormDay.value);
    if (this.itemFormDay.valid) {

      let date = new Date(this.itemFormDay.get("debut").value);
      let date1 = new Date(this.itemFormDay.get("fin").value);

      console.log("debut : "+date+" ; fin  : "+date1);

      let from : string ="";
      let to : string ="";



      if((date.toString()!='Invalid Date')){
        from  = this.itemFormDay.get("debut").value;
      }

      if(date1.toString()!=null){
        to  = this.itemFormDay.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);


      console.log(" date "+newDate);
      console.log(" date1 "+newDate1);

      if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      }else{
        if(newDate1>=newDate){
          console.log(" debut : "+this.datePipe.transform(newDate,"dd/MM/yyyy"));
          console.log(" fin : "+this.datePipe.transform(newDate1,"dd/MM/yyyy"));
          let req : Statrequest = new Statrequest();

          req.from=this.datePipe.transform(newDate,"dd/MM/yyyy");
          req.to=this.datePipe.transform(newDate1,"dd/MM/yyyy");

          let wall : Walet = this.itemFormDay.get("wallet").value;
          let serv : Servicse = this.itemFormDay.get("services").value;



          req.granularity="day";
          req.service_token=serv.token;
          req.wallet_token=wall.authentication_token;

          req.page=x;

          this.loader.open();
          console.log(req);
          if(req!=null){
            this.dashboardService.sendStatistiqueOperation(req)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      this.statchoose="day";
                      console.log(data);
                      let ts : Statresult = new Statresult();
                      ts=data.body;
                      if(ts!=null){
                        this.itemsTransactionGenerale=[];
                        this.itemsTransactionGenerale=ts.transactions;
                        this.total=ts.total;
                        this.dataSource = new MatTableDataSource(this.itemsTransactionGenerale);

                      }
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }
                    case 201: {
                      this.statchoose="day";
                      console.log(data);
                      let ts : Statresult = new Statresult();
                      ts=data.body;
                      if(ts!=null){
                        this.itemsTransactionGenerale=[];
                        this.itemsTransactionGenerale=ts.transactions;
                        this.total=ts.total;

                      }
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }

                  }
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch(error.status){

                    default: {
                      this.reloadall();
                      this.loader.close();
                      this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                      break;
                    }
                  }

                }

              )
          }
          this.reloadall();

        }else{
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }


    }
    this.reloadall();
  }

  indexPage : number = 1 ;


  createRange(x:number){
    var items: number[] = [];
    for(var i = this.indexPage; i <= this.indexPage+x; i++){
       items.push(i);
    }
    return items;
  }

  nextVerif(){
    this.indexPage++;
    //this.createRange(5);
  }



  lastVerif(){
    console.log(" lastVerif :: "+this.indexPage);
    if(this.indexPage<=1){

    }else{
      this.indexPage--;
    }
    //this.createRange(5);
  }


  numberWithCommas(value : string) {
    if(value!=null){
     return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }







  /*
   indexPage : number = 1 ;
   itemsGeneral: number[] = [];
   itemsPartiel: number[] = [];
   indexbegin : number=0 ;
   indexend : number ;




  createRange(x:number){
    var items: number[] = [];
    this.itemsGeneral=[];
    this.itemsPartiel=[];


   let taille : number = this.itemsTransactionGenerale.length ;
   if(taille>0){
    if(taille>9){
      console.log(" taille : "+taille);

      let modulo : number = taille % 10 ;
      let div : number = taille / 10 ;

      if(modulo===0){
        this.indexPage=div ;
      }else{
        this.indexPage=div +1;
      }
      items=[];
      for(var i = 1 ; i <= this.indexPage; i++){
        items.push(i);
     }
     this.itemsGeneral=items;


     let v : number = 0 ;
     for(var j = 0 ; j<this.itemsGeneral.length ; j++){
      if(j<x){
        this.itemsPartiel[v]=this.itemsGeneral[v] ;
       }
       v++;
     }

     if(this.itemsPartiel.length>0){
      this.indexbegin=this.itemsPartiel[0];
     }else{
      this.indexbegin=0;
     }
     console.log("item general");
     console.log(this.itemsGeneral);


     console.log("item partiel");
     console.log(this.itemsPartiel);



     return this.itemsPartiel ;

    }else{
      this.indexbegin=1;
      items=[];
      items.push(1);
     return items ;
    }
   }else{
     this.indexbegin=0;
     items=[];
     return items ;
   }

  }

  nextVerif(){

    this.indexPage++;
    //this.createRange(5);
  }



  lastVerif(){
    console.log(" lastVerif :: "+this.indexPage);
    if(this.indexPage<=1){

    }else{
      this.indexPage--;
    }
    //this.createRange(5);
  }
  */





}
