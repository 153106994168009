import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {
  retry,
  catchError,
  take,
  map,
  exhaustMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { ConfigService } from "ngx-envconfig";
import { GlobalVariableService } from "./global-variable.service";

/**
 * @author Christian DJEA
 */
@Injectable({
  providedIn: "root",
})
export class DepositService {
  API_URL: string;
  CNAM_ID: string;
  CNAM_KEY: string;
  CNAM_API_BACKEND: string;

  public responseCache = new Map();
  constructor(private http: HttpClient, public configService: ConfigService) {
    this.API_URL = configService.get("HOST_API_MODULE_PREPAYE");
    this.CNAM_ID = configService.get("CNAM_ID");
    this.CNAM_KEY = configService.get("CNAM_KEY");
    this.CNAM_API_BACKEND = configService.get("HOST_API_CNAM");
  }
  /**
   * @author Christian DJEA
   */

  // Http Options
  getHttpOptions(accessToken?: string) {
    if (accessToken) {
      return {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + accessToken,
        }),
      };
    }

    return {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      }),
    };
  }

  //HTTP Headers Options for Prepaid account to debit
  prepaidModuleHttpOptions(sessionToken:string) {
      if (sessionToken) {
        return {
          headers: new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept":"text/plain",
            "Authorization":sessionToken
          }),
          responseType:'text'
        }
      }
  }

  //Debit the prepaid account balance
   debitPrepaidAccountBalance(quantity:number, operationAmount:number, serviceCode:string, responseKey:string, referenceTransaction?:string, ):Observable<any> {
    const body = new HttpParams()
    .set('qte', quantity.toString())
    .set('montant', operationAmount.toString())
    .set('reftransaction', referenceTransaction)
    .set('servicecode', serviceCode);
        return  this.http.post<any>(
          this.API_URL + "buisness/notifpay-cnam",
          body, 
          this.prepaidModuleHttpOptions(responseKey) as any).pipe(
            map(res => {
              return res;
            }),
            catchError((error => {
              return this.handleError(error)
            }))
          );
      
    
   
  }

  getServiceCredentials(
    serviceId: string,
    serviceKey: string
  ): Observable<any> {
    return this.http
      .post<any>(
        this.API_URL + "buisness/session",
        { identifiant: serviceId, key: serviceKey },
        this.getHttpOptions()
      )
      .pipe(catchError(this.handleError));
  }

  // HttpClient API get() method => Get all deposits
  getAllDeposits(): Observable<any> {
    return this.getServiceCredentials(this.CNAM_ID, this.CNAM_KEY).pipe(
      exhaustMap((response) => {
        return this.http
          .post<any>(
            this.API_URL + "buisness/deposit/all",
            { servicecode: response.servicecode },
            this.getHttpOptions(response.key)
          )
          .pipe(catchError(this.handleError));
      })
    );
  }
  

  getServiceBalance(): Observable<any> {
    return this.getServiceCredentials(this.CNAM_ID, this.CNAM_KEY).pipe(
      exhaustMap((response) => {
        return this.http
          .get<any>(
            this.API_URL + "buisness/solde/" + response.servicecode,
            this.getHttpOptions(response.key)
          )
          .pipe(catchError(this.handleError));
      })
    );
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  //Reload prepay account from Gateway
  reloadPrepayAccount(amount: number, moyen:string, emetteur:string, token:string) : Observable<any>{
      return this.http
        .post<any>(
          this.CNAM_API_BACKEND + "deposit/generate-order-id",
          {
            transaction_amount: amount,
            moyen:moyen,
            emetteur:emetteur
          },
          this.getHttpOptions(token)
        )
        .pipe(catchError(this.handleError));
    
   
  }


}
