import { HistoriqueComponent } from "./views/admin/historique/historique.component";
import { CaissierComponent } from "./views/admin/caissier/caissier.component";
import { AgenceComponent } from "./views/admin/agence/agence.component";
import { Admin2LayoutComponent } from "./shared/components/layouts/admin2-layout/admin2-layout.component";
import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/services/auth/auth.guard";
import { MapComponent } from "./views/map/map.component";
import { ForgetpasswordComponent } from "./views/forgetpassword/forgetpassword.component";
import { ResetpasswordComponent } from "./views/resetpassword/resetpassword.component";
import { AuthpasswdGuard } from "./shared/services/auth/authpasswd.guard";
import { SigninComponent } from "./views/signin/signin.component";
import { CpteuserComponent } from "./views/cpteuser/cpteuser.component";
import { StatopComponent } from "./views/statop/statop.component";
import { DetailopComponent } from "./views/detailop/detailop.component";
import { PourcentageComponent } from "./views/pourcentage/pourcentage.component";
import { WalletComponent } from "./views/wallet/wallet.component";
import { ServiccesComponent } from "./views/servicces/servicces.component";
import { ListprofilComponent } from "./views/listprofil/listprofil.component";
import { CreerprofileComponent } from "./views/creerprofile/creerprofile.component";
import { LierprofileuserComponent } from "./views/lierprofileuser/lierprofileuser.component";
import { ModifierprofileComponent } from "./views/modifierprofile/modifierprofile.component";
import { Virementrecuop } from "./shared/models/virementrecuop";
import { VirementrecuopComponent } from "./views/virementrecuop/virementrecuop.component";
import { MontantvireaupartComponent } from "./views/montantvireaupart/montantvireaupart.component";
import { AccueilcaisseComponent } from "./views/accueilcaisse/accueilcaisse.component";
import { GenerationvoucherModule } from "./views/generationvoucher/generationvoucher.module";
import { ReglementvoucherComponent } from "./views/reglementvoucher/reglementvoucher.component";
import { GenerationvoucherComponent } from "./views/generationvoucher/generationvoucher.component";
import { ConsultationvoucherComponent } from "./views/consultationvoucher/consultationvoucher.component";
import { HistoriquevoucherComponent } from "./views/historiquevoucher/historiquevoucher.component";
import { HistoriquereglementvoucherComponent } from "./views/historiquereglementvoucher/historiquereglementvoucher.component";
import { VoucheroperationComponent } from "./views/voucheroperation/voucheroperation.component";
import { ProfilComponent } from "./views/admin/profil/profil.component";
import { GestionnaireComponent } from "./views/admin/gestionnaire/gestionnaire.component";
import { GestLayoutComponent } from "./shared/components/layouts/gest-layout/gest-layout.component";
import { AgenceLayoutComponent } from "./shared/components/layouts/agence-layout/agence-layout.component";
import { AgencecaisseComponent } from "./views/agencecaisse/agencecaisse.component";
import { PaymentnotifComponent } from "./views/paymentnotif/paymentnotif.component";
import { PortailComponent } from "./views/portail/portail.component";
import { CnamLayoutComponent } from "./shared/components/layouts/cnam-layout/cnam-layout.component";
import { SettingsComponent } from "./views/cnam/settings/settings.component";
import { ManagerComponent } from "./views/cnam/manager/manager.component";
import { CashierComponent } from "./views/cnam/cashier/cashier.component";
import { AgencyComponent } from "./views/cnam/agency/agency.component";
import { ForgetpasswordCnamComponent } from "./views/forgetpassword-cnam/forgetpassword-cnam.component";
import { ProfileComponent } from "./views/cnam/profile/profile.component";
import { CnamLayoutProfileComponent } from "./shared/components/layouts/cnam-layout-profile/cnam-layout-profile.component";
import { RechercheNumeroSecuComponent } from "./views/cnam/recherche-numero-secu/recherche-numero-secu.component";
import { ViewDetailsSecuComponent } from "./views/cnam/view-details-secu/view-details-secu.component";
import { FormulairePaiementComponent } from "./views/cnam/formulaire-paiement/formulaire-paiement.component";
import { HistoriqueVenteComponent } from "./views/cnam/historique-vente/historique-vente.component";
import { HistoriqueVenteGeneraleComponent } from "./views/cnam/historique-vente-generale/historique-vente-generale.component";
import { MesVentesComponent } from "./views/cnam/mes-ventes/mes-ventes.component";
import { DepositComponent } from "./views/cnam/deposit/deposit.component";
import { GatewayrspComponent } from "./views/cnam/gatewayrsp/gatewayrsp.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "portail",
    pathMatch: "full",
  },
  {
    path: "seconnecter",
    component: SigninComponent,
    data: { title: "Se connecter" },
  },
  {
    path: "portail",
    component: PortailComponent,
    data: { title: "Portail" },
  },
  {
    path: "forgetpassword",
    component: ForgetpasswordComponent,
    canActivate: [AuthpasswdGuard],
    data: { title: "Mot de Passe Oublie" },
  },
  {
    path: "forgetpassword-cnam",
    component: ForgetpasswordCnamComponent,
    canActivate: [AuthpasswdGuard],
    data: { title: "Mot de Passe Oublie" },
  },
  {
    path: "resetpassword/user",
    component: ResetpasswordComponent,
    canActivate: [AuthpasswdGuard],
    data: { title: "Mot de Passe Oublie" },
  },
  {
    path: "",
    component: Admin2LayoutComponent,
    children: [
      {
        path: "agence",
        component: AgenceComponent,
        data: { title: "Agence" },
      },
      {
        path: "profil",
        component: ProfilComponent,
        data: { title: "Profil" },
      },

      {
        path: "caissier",
        component: CaissierComponent,
        data: { title: "Caissier" },
      },

      {
        path: "historique",
        component: HistoriqueComponent,
        data: { title: "Historique" },
      },
      {
        path: "gestionnaire",
        component: GestionnaireComponent,
        data: { title: "Gestionnaire" },
      },
    ],
  },
  {
    path: "",
    component: CnamLayoutComponent,
    children: [
      {
        path: "mes-ventes",
        component: MesVentesComponent,
        data: { title: "Mes ventes" },
      },
      {
        path: "agency",
        component: AgencyComponent,
        data: { title: "Agence" },
      },
      {
        path: "cashier",
        component: CashierComponent,
        data: { title: "Caissier" },
      },
      {
        path: "deposit",
        component: DepositComponent,
        data: { title: "Deposit" },
      },
      {
        path: "gatewayresponse",
        component: GatewayrspComponent,
        data: { title: "Gateway RSP" },
      },
      {
        path: "historique-vente",
        component: HistoriqueVenteComponent,
        data: { title: "Historique des ventes" },
      },
      {
        path: "historique-vente-generale",
        component: HistoriqueVenteGeneraleComponent,
        data: { title: "Historique des ventes génerales" },
      },
      {
        path: "manager",
        component: ManagerComponent,
        data: { title: "Gestionnaire" },
      },
      {
        path: "settings",
        component: SettingsComponent,
        data: { title: "Paramétrage" },
      },
    ],
  },
  {
    path: "",
    component: CnamLayoutProfileComponent,
    children: [
      {
        path: "my-profile",
        component: ProfileComponent,
        data: { title: "Mon profil" },
      },
      {
        path: "recherche-secu",
        component: RechercheNumeroSecuComponent,
        data: { title: "Recherche numéro de securité sociale" },
      },
      {
        path: "details-secu",
        component: ViewDetailsSecuComponent,
        data: { title: "Details numéro de securité sociale" },
      },
      {
        path: "form-paiement",
        component: FormulairePaiementComponent,
        data: { title: "Formulaire de paiement" },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: "accueil",
        component: AccueilcaisseComponent,
        data: { title: "Accueil caisse" },
      },

      {
        path: "journalvoucher",
        component: VoucheroperationComponent,
        data: { title: "Journal Voucher" },
      },

      {
        path: "generationvoucher",
        component: GenerationvoucherComponent,
        data: { title: "Achat de Timbre" },
      },

      {
        path: "reglementvoucher",
        component: ReglementvoucherComponent,
        data: { title: "Reglement de Voucher" },
      },

      {
        path: "consultationvoucher",
        component: ConsultationvoucherComponent,
        data: { title: "Consultation Voucher" },
      },

      {
        path: "historiqueachatvoucher",
        component: HistoriquevoucherComponent,
        data: { title: "Historique des ventes" },
      },
      {
        path: "historiquereglementvoucher",
        component: HistoriquereglementvoucherComponent,
        data: { title: "Historique Reglement Voucher" },
      },
      {
        path: "cpteusers",
        component: CpteuserComponent,
        data: { title: "Compte utilisateur" },
      },
      {
        path: "statoperations",
        component: StatopComponent,
        data: { title: "Statistique Operation" },
      },
      {
        path: "detailoperations",
        component: DetailopComponent,
        data: { title: "Detail Operation" },
      },
      {
        path: "pourcentage",
        component: PourcentageComponent,
        data: { title: "Pourcentage Stat" },
      },
      {
        path: "wallet",
        component: WalletComponent,
        data: { title: "Wallet" },
      },

      {
        path: "service",
        component: ServiccesComponent,
        data: { title: "service" },
      },

      {
        path: "monprofil",
        component: MapComponent,
        data: { title: "Profil" },
      },

      {
        path: "pourcentage",
        component: PourcentageComponent,
        data: { title: "Pourcentage" },
      },
      {
        path: "listprofil",
        component: ListprofilComponent,
        data: { title: "Lister Profil" },
      },

      {
        path: "creerprofil",
        component: CreerprofileComponent,
        data: { title: "Creer Profil" },
      },
      {
        path: "modifierprofil",
        component: ModifierprofileComponent,
        data: { title: "Modifier Profil" },
      },
      {
        path: "lierprofiluser",
        component: LierprofileuserComponent,
        data: { title: "Lier Profil Utilisateur" },
      },
      {
        path: "montantvire",
        component: MontantvireaupartComponent,
        data: { title: "Montant vire" },
      },

      {
        path: "virementrecu",
        component: VirementrecuopComponent,
        data: { title: "Virement recu" },
      },

      /*
      {
        path: 'dashboard',
        component : DashboardComponent ,
        data: {  title: 'Tableau de bord'}
      },
      {
        path: 'caissier',
        component : CaissierComponent ,
        data: {  title: 'Caissier'}
      },
      {
        path: 'typecompte',
        component : TypecpteComponent ,
        data: {  title: 'Type Compte'}
      },
      {
        path: 'comptejeux',
        component : CptejeuxComponent ,
        data: {  title: 'Compte Jeux'}
      },
      {
        path: 'profils',
        component : GestionprofilComponent ,
        data: { title: 'profils'}
      },
      {
        path: 'users',
        component : UtilisateurComponent ,
        data: { title: 'utilisateurs'}
      },
      {
        path: 'usersprofil',
        component : UserprofilComponent ,
        data: { title: 'Lier utilisateur profil'}
      },
      {
        path: 'usersprofilent',
        component : UserprofilentComponent ,
        data: { title: 'Lier utilisateur profil entreprise'}
      },
      {
        path: 'valideprofil',
        component : CreerprofilComponent ,
        data: { title: 'Gerer les profil'}
      },

      {
        path: 'modifierprofil',
        component : ModifierprofilComponent ,
        data: { title: 'Modifier profil'}
      },

      {
        path: 'comptebonusjeux',
        component : CptejeuxbonusComponent ,
        data: { title: 'Compte bonus jeux'}
      },
      {
        path: 'genreoperation',
        component : GenreoperationComponent ,
        data: { title: 'Type operation'}
      },

      {
        path: 'compterechargement',
        component : CpterechargementComponent ,
        data: { title: 'Rechargement'}
      },

      {
        path: 'comptesysteme',
        component : CptesystemeComponent ,
        data: { title: 'Systeme'}
      },

      {
        path: 'comptepartenaire',
        component : CptebancaireComponent ,
        data: { title: 'Partenaire'}
      },

      {
        path: 'comptegains',
        component : CptegainsComponent ,
        data: { title: 'Gains'}
      },

      {
        path: 'comptebonus',
        component : CptebonusComponent ,
        data: { title: 'Bonus'}
      },

      {
        path: 'monprofil',
        component : MapComponent ,
        data: { title: 'Profil'}
      },

      {
        path: 'paripmualr',
        component : ParipmualrComponent ,
        data: { title: 'Pari PMU ALR'}
      } ,
      {
        path: 'paripmuplr',
        component : ParipmuplrComponent ,
        data: { title: 'Pari PMU PLR'}
      } ,
      {
        path: 'parisportcash',
        component : ParisportcashComponent ,
        data: { title: 'Pari SPORTCASH'}
      } ,
      {
        path: 'parilotobonheur',
        component : ParilotobonheurComponent ,
        data: { title: 'Pari LOTO BONHEUR'}
      } ,
      {
        path: 'paiementdegains',
        component : PaiementdegainsComponent ,
        data: { title: 'Paiement de gains'}
      }
      ,
      {
        path: 'accesrefuse',
        component : AccesdeniedComponent ,
        data: { title: 'Acces refuse'}
      }
      ,
      {
        path: 'paiementrecuorange',
        component : PaiementrecuorangeComponent ,
        data: { title: 'Paiement reçu orange'}
      },
      {
        path: 'paiementenvoyeorange',
        component : PaiementenvoyeorangeComponent ,
        data: { title: 'Paiement envoye orange'}
      },

      {
        path: 'paiementrecumtn',
        component : PaiementrecumtnComponent ,
        data: { title: 'Paiement reçu mtn'}
      },
      {
        path: 'paiementenvoyemtn',
        component : PaiementenvoyemtnComponent ,
        data: { title: 'Paiement envoye mtn'}
      },

      {
        path: 'paiementrecumoov',
        component : PaiementrecumoovComponent ,
        data: { title: 'Paiement reçu moov'}
      },
      {
        path: 'paiementenvoyemoov',
        component : PaiementenvoyemoovComponent ,
        data: { title: 'Paiement envoye moov'}
      },

      {
        path: 'paiementrecuuba',
        component : PaiementrecuubaComponent ,
        data: { title: 'Paiement reçu uba'}
      },
      {
        path: 'paiementenvoyeuba',
        component : PaiementenvoyeubaComponent ,
        data: { title: 'Paiement envoye uba'}
      },

      {
        path: 'paiementrecuyup',
        component : PaiementrecuyupComponent ,
        data: { title: 'Paiement reçu yup'}
      },
      {
        path: 'paiementenvoyeyup',
        component : PaiementenvoyeyupComponent ,
        data: { title: 'Paiement envoye yup'}
      }
      */
      /*
      ,

      {
        path: 'paiementrecunsia',
        component : PaiementrecunsiaComponent ,
        data: { title: 'Paiement reçu nsia'}
      },

      {
        path: 'paiementenvoyensia',
        component : PaiementenvoyensiaComponent ,
        data: { title: 'Paiement envoye nsia'}
      }
      */
    ],
  },

  {
    path: "",
    component: GestLayoutComponent,
    children: [
      {
        path: "profilgestionnaire",
        component: ProfilComponent,
        data: { title: "Profil" },
      },
      {
        path: "historiquegestionaire",
        component: HistoriqueComponent,
        data: { title: "Historique" },
      },
      {
        path: "journalgestionaire",
        component: HistoriqueComponent,
        data: { title: "Historique" },
      },
    ],
  },

  {
    path: "",
    component: AgenceLayoutComponent,
    children: [
      {
        path: "achatagence",
        component: GenerationvoucherComponent,
        data: { title: "Achat de Timbre" },
      },

      {
        path: "profilagence",
        component: ProfilComponent,
        data: { title: "Profil" },
      },
      {
        path: "historiquechefagence",
        component: HistoriquevoucherComponent,
        data: { title: "Historique" },
      },
      {
        path: "historiqueagence",
        component: AgencecaisseComponent,
        data: { title: "Historique" },
      },
      {
        path: "paymentnotif",
        component: PaymentnotifComponent,
        data: { title: "Payment Notification" },
      },
    ],
  },
];
