import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'ngx-envconfig';
import { GlobalVariableService } from './global-variable.service';
@Injectable({
    providedIn: 'root'
})

export class AgencyService {
    // Define API
    API_URL: string;
    public responseCache = new Map();
    constructor(private http: HttpClient, public configService: ConfigService, private variable: GlobalVariableService) {
        this.API_URL = configService.get('HOST_API_CNAM');
    }
    /**
     * @author André ATCHORI
     */

        // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.variable.getAccessToken()
        })
    };

    // HttpClient API get() method => Get all caissier
    getAllAgency(): Observable<any> {
        return this.http.get<any>(this.API_URL + 'agence/all', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // HttpClient API post() method => Create caissier
    createAgency(data): Observable<any> {
        return this.http.post<any>(this.API_URL + 'agence/new', JSON.stringify(data), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // HttpClient API post() method => Save all caissier
    saveAllAgency(data): Observable<any> {
        return this.http.post<any>(this.API_URL + 'agence/storeMasse', JSON.stringify(data), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // HttpClient API post() method => Update caissier
    updateAgency(data): Observable<any> {
        return this.http.put<any>(this.API_URL + 'agence/update', JSON.stringify(data), this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // HttpClient API post() method => delete caissier
    deleteAgency(identifiant): Observable<any> {
        return this.http.delete<any>(this.API_URL + 'agence/delete/' + identifiant, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // HttpClient API post() method => disable caissier
    disableAgency(identifiant): Observable<any> {
        return this.http.put<any>(this.API_URL + 'agence/disable/' + identifiant, {}, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }


    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

}
