export class Caisserequest {
    
	public   typeoperation : string ;
	public   montant : string ;
	public   trainsactionid : string ;
	public   numerovoucher : string ;
	public   reference : string ;
	public   password : string ;
	public   details : string ;

}
