import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accesdenied',
  templateUrl: './accesdenied.component.html',
  styleUrls: ['./accesdenied.component.scss']
})
export class AccesdeniedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
