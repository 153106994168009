import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { GestionnaireService } from '../../../../shared/services/gestionnaire.service';
import { SettingsService } from '../../../../shared/services/settings.service';
import {AgencyService} from "../../../../shared/services/agency.service";

@Component({
  selector: 'app-edit-cashier',
  templateUrl: './edit-agency.component.html',
  styleUrls: ['./edit-agency.component.scss']
})
export class EditAgencyComponent implements OnInit {
  @Input() agence;
  response: any;
  listAgence: any = [];
  listDirection: any = [];
  constructor(private activeModal: NgbActiveModal, private _agence: AgencyService,
    private eventEmitter: EventEmitterService, private _settings: SettingsService) { }

  ngOnInit() {
    this.getAllAgence();
    this.getAllDirection();
  }

    // Get all agence
    getAllAgence() {
      this._agence.getAllAgency().subscribe((data: {}) => {
        this.response = data;
        this.listAgence = this.response.data;
      });
    }
  
    // Get all direction
    getAllDirection() {
      this._settings.getAllDirection().subscribe((data: {}) => {
        this.response = data;
        this.listDirection = this.response.data;
      });
    }
  
    onSelectDirection(value) {
      this.agence.direction_id = value;
    }

  closeModal() {
    this.activeModal.close();
  }

  updateAgence() {
    this._agence.updateAgency(this.agence)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          this.activeModal.close();
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.eventEmitter.onGetAllAgence();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }


}
