import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Utilisateur} from '../../shared/models/utilisateur';
import {DashboardService} from '../../shared/services/dashboard.service';
import {AppConfirmService} from '../../shared/services/app-confirm/app-confirm.service';
import {AppLoaderService} from '../../shared/services/app-loader/app-loader.service';
import {Granularite} from '../../shared/models/granularite';
import {Unknow} from '../../shared/models/unknow';
import {Wallet} from '../../shared/models/wallet';
import {Servicce} from '../../shared/models/servicce';
import {UpdategranComponent} from './updategran/updategran.component';
import {UpdateunknowComponent} from './updateunknow/updateunknow.component';
import {UpdatewalletComponent} from './updatewallet/updatewallet.component';

@Component({
  selector: 'app-pourcentage',
  templateUrl: './pourcentage.component.html',
  styleUrls: ['./pourcentage.component.scss']
})
export class PourcentageComponent implements OnInit {

  public itemsGran: any[];
  tempGran = [];
  rowsGran = [];
  columnsGran = [];


  public itemsEnt: any[];
  tempEnt = [];
  rowsEnt = [];
  columnsEnt = [];

  public itemsWall: any[];
  tempWall = [];
  rowsWall = [];
  columnsWall = [];


  public getItemSub: Subscription;
  dataSourceGran: MatTableDataSource<Granularite> = new MatTableDataSource([]);
  dataSourceEnt: MatTableDataSource<Unknow> = new MatTableDataSource([]);
  dataSourceWall: MatTableDataSource<Wallet> = new MatTableDataSource([]);


  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.getItemsGranularite();
    this.getItemsServicces() ;
    this.getItemsUnknow();
    this.getItemsWallet();
  }



  getItemsWallet() {
    this.getItemSub = this.dashboardService.getallWallet()
      .subscribe(data => {
        console.log(data);
        this.itemsWall = this.tempWall = data;
        this.dataSourceWall = new MatTableDataSource(this.itemsWall);
        localStorage.setItem('allwallet',JSON.stringify(this.itemsWall));

      });
  }

  getItemsUnknow() {
    this.getItemSub = this.dashboardService.getallUnknw()
      .subscribe(data => {
        console.log(data);
        this.itemsEnt = this.tempEnt = data;
        this.dataSourceEnt = new MatTableDataSource(this.itemsEnt);
      });
  }


  getItemsGranularite() {
    this.getItemSub = this.dashboardService.getallgranularite()
      .subscribe(data => {
        console.log(data);
        this.itemsGran = this.tempGran = data;
        this.dataSourceGran = new MatTableDataSource(this.itemsGran);
        localStorage.setItem('allgranularity',JSON.stringify(this.itemsGran));
      });
  }


  getItemsServicces() {
    this.getItemSub = this.dashboardService.getallServices()
      .subscribe(data => {
        console.log(data);
        let resultat : any [] = data;
        let services : Servicce[] ;
        services = resultat ;
        localStorage.setItem('allservices',JSON.stringify(services));
      });
  }

  updateGran(data: any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdategranComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        this.loader.open();
        let val : Granularite = res ;


        if(val!=null){
          this.dashboardService.updategran(val).subscribe(data => {
              switch (data.status) {
                case 200: {
                  this.getItemsGranularite();
                  this.loader.close();
                  this.snack.open('succes !', 'OK', { duration: 4000 })
                  break;
                }
                case 201: {

                  this.getItemsGranularite();
                  this.loader.close();
                  this.snack.open('succes', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }
            },
            error => {
              console.log('data');
              console.log(error);
              switch(error.status){

                default: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
        }


      });

  }


  updateUnknow(data: any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateunknowComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        this.loader.open();
        let val : Unknow = res ;

        if(val!=null){
          this.dashboardService.updateUnkw(val).subscribe(data => {
              switch (data.status) {
                case 200: {
                  this.getItemsUnknow();
                  this.loader.close();
                  this.snack.open('succes !', 'OK', { duration: 4000 })
                  break;
                }
                case 201: {

                  this.getItemsUnknow();
                  this.loader.close();
                  this.snack.open('succes', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }
            },
            error => {
              console.log('data');
              console.log(error);
              switch(error.status){

                default: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
        }


      });

  }

  updateWallet(data: any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdatewalletComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        this.loader.open();
        let val : Wallet = res ;

        console.log(" id : "+val.walletid);

        if(val!=null){
          this.dashboardService.updateWallet(val).subscribe(data => {
              switch (data.status) {
                case 200: {
                  this.getItemsWallet();
                  this.loader.close();
                  this.snack.open('succes !', 'OK', { duration: 4000 })
                  break;
                }
                case 201: {

                  this.getItemsWallet();
                  this.loader.close();
                  this.snack.open('succes', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }
            },
            error => {
              console.log('data');
              console.log(error);
              switch(error.status){

                default: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
        }


      });

  }





}
