import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { SettingsService } from 'app/shared/services/settings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-direction',
  templateUrl: './edit-direction.component.html',
  styleUrls: ['./edit-direction.component.scss']
})
export class EditDirectionComponent implements OnInit {
  @Input() direction;
  listInspection: any = [];
  settingsInspection = {};
  selectedInspection: any;
  idInspection: any;
  response: any;
  constructor(private activeModal: NgbActiveModal,
    private _settings: SettingsService, private eventEmitter: EventEmitterService) { }

  ngOnInit() {
    this.getAllInspection();
  }

  onSelectInspection(item) {
    this.idInspection = item.id;
  }
  
    // Get all inspection
    getAllInspection() {
      this._settings.getAllInspection().subscribe((data: {}) => {
        this.response = data;
        this.listInspection = this.response.data;
        this.listInspection.forEach(element => {
          element.nom_code = `${element.nom} [${element.code}]`;
        });
      });
    }
  
  closeModal() {
    this.activeModal.close();
  }

  updateDirection() {
    this._settings.updateDirection(this.direction)
    .subscribe(
      res => {
        if (res.error === false) {
          this.activeModal.close();
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.eventEmitter.onGetAllUsers();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

}
