import { CaissiereService } from './caissier/caissiere.service';
import { AgenceService } from './agence/agence.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {
  MatButtonModule,
  MatCardModule, MatCheckboxModule,
  MatChipsModule, MatDialogModule,
  MatIconModule,
  MatInputModule, MatListModule,
  MatMenuModule,
  MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatTooltipModule
} from '@angular/material';
import {SharedModule} from '../../shared/shared.module';
import { AgenceComponent } from './agence/agence.component';
import { CaissierComponent } from './caissier/caissier.component';
import { PopupcaissierComponent } from './caissier/popupcaissier/popupcaissier.component';
import { PopupagenceComponent } from './agence/popupagence/popupagence.component';
import { HistoriqueComponent } from './historique/historique.component';
import { ProfilComponent } from './profil/profil.component';
import { GestionnaireComponent } from './gestionnaire/gestionnaire.component';
import { PopupgestComponent } from './gestionnaire/popupgest/popupgest.component';


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    SharedModule
  ],
  declarations: [AgenceComponent, CaissierComponent, PopupcaissierComponent, PopupagenceComponent, HistoriqueComponent, ProfilComponent,
    GestionnaireComponent, PopupgestComponent],
  entryComponents: [PopupagenceComponent, PopupcaissierComponent,PopupgestComponent],
  providers: [AgenceService, CaissiereService],

})
export class AdminModule { }
