import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { GestionnaireService } from '../../../../shared/services/gestionnaire.service';

@Component({
  selector: 'app-edit-manager',
  templateUrl: './edit-manager.component.html',
  styleUrls: ['./edit-manager.component.scss']
})
export class EditManagerComponent implements OnInit {
  @Input() manager;
  response: any;
  constructor(private activeModal: NgbActiveModal, private _manager: GestionnaireService,
    private eventEmitter: EventEmitterService) { }

  ngOnInit() {
  }


  onSelectService(value){
    this.manager.service = value;
  }

  closeModal() {
    this.activeModal.close();
  }

  updateGestionnaire() {
    this._manager.updateGestionnaire(this.manager)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          this.activeModal.close();
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.eventEmitter.onGetAllManager();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }


}
