import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'ngx-envconfig';
import { GlobalVariableService } from './global-variable.service';
@Injectable({
  providedIn: 'root'
})

export class SettingsService {
  // Define API
  API_URL: string;
  public responseCache = new Map();
  constructor(private http: HttpClient, public configService: ConfigService, private variable: GlobalVariableService) { 
    this.API_URL = configService.get('HOST_API_CNAM');  
  }
  /**
   * @author André ATCHORI
   */

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.variable.getAccessToken()
    })
  };

  // HttpClient API get() method => Get all logs
  getAllLogs(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'user/getlogs', data, this.httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        );
  }

  /**
   * 
   *  Piste audite
   */

  // HttpClient API get() method => Get all piste audite 
  getPisteAudite(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'user/piste_audite', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API get() method => Filter by date_debut and date_fin
  getAuditeByDate(date_debut, date_fin): Observable<any> {
    return this.http.get<any>(this.API_URL + `user/piste_audite/date_debut/${date_debut}/date_fin/${date_fin}`, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * 
   * Direction
   */

  // HttpClient API get() method => Get all direction
  getAllDirection(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'direction/all', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Create direction
  createDirection(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'direction/new', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

    // HttpClient API post() method => Save all direction
   saveAllDirection(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'direction/storeMasse', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Update direction
  updateDirection(data): Observable<any> {
    return this.http.put<any>(this.API_URL + 'direction/update', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => delete direction
  deleteDirection(identifiant): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'direction/delete/' + identifiant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API put() method => disable direction
  disableDirection(identifiant): Observable<any> {
    return this.http.put<any>(this.API_URL + 'direction/disable/' + identifiant, {}, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

    /**
   * 
   * Inspection
   */

  // HttpClient API get() method => Get all inspection
  getAllInspection(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'inspection/all', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Create inspection
  createInspection(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'inspection/new', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

    // HttpClient API post() method => Save all inspection
   saveAllInspection(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'inspection/storeMasse', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Update direction
  updateInspection(data): Observable<any> {
    return this.http.put<any>(this.API_URL + 'inspection/update', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API delete() method => delete inspection
  deleteInspection(identifiant): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'inspection/delete/' + identifiant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

    // HttpClient API post() method => Get list direction by Inspection
    getListDirectionByInspection(data): Observable<any> {
      return this.http.post<any>(this.API_URL + 'inspection/liste_directions', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
    }

  // HttpClient API put() method => disable inspection
  disableInspection(identifiant): Observable<any> {
    return this.http.put<any>(this.API_URL + 'inspection/disable/' + identifiant, {}, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * 
   *  Users
   */

   // HttpClient API get() method => Get all users
  getAllUsers(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'user/all', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

 // HttpClient API post() method => Create users
  createUsers(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'user/register', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

   // HttpClient API post() method => Save all users
   saveAllUsers(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'user/storeMasse', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Update users
  updateUsers(data): Observable<any> {
    return this.http.put<any>(this.API_URL + 'user/update', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => delete users
  deleteUsers(identifiant): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'user/delete/' + identifiant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => disable users
  disableUsers(identifiant): Observable<any> {
    return this.http.put<any>(this.API_URL + 'user/disable/' + identifiant, {}, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
    * Permissions
  */
  
    // HttpClient API post() method => Get All permissions
  getAllPermissions(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'permissions/all', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  
   // HttpClient API post() method => Create permissions
  createPermissions(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'permissions/new', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Update permissions
  updatePermissions(data, identifiant): Observable<any> {
    return this.http.put<any>(this.API_URL + 'permissions/update/' + identifiant, JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Delete permissions
  deletePermissions(identifiant): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'permissions/delete/' + identifiant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
    * Profiles
  */
  
  // HttpClient API get() method => Get all profil
  getAllProfil(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'roles/all', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

    // HttpClient API get() method => Get all profil by Id
    getAllProfilById(identifiant): Observable<any> {
        return this.http.get<any>(this.API_URL + 'roles/' + identifiant, this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    // HttpClient API get() method => Get all menu
    getAllMenu(): Observable<any> {
        return this.http.get<any>(this.API_URL + 'user/allmenu', this.httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }
  
  // HttpClient API post() method => Create profile
  createProfile(data): Observable<any> {
    return this.http.post<any>(this.API_URL + 'roles/new', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

   // HttpClient API post() method => Lier Profile
  lierProfile(data): Observable<any> {
    return this.http.put<any>(this.API_URL + 'user/change_role', JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Update profile
  updateProfile(identifiant, data): Observable<any> {
    return this.http.put<any>(this.API_URL + 'roles/update/' + identifiant, JSON.stringify(data), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Delete profile
  deleteProfile(identifiant): Observable<any> {
    return this.http.delete<any>(this.API_URL + 'roles/delete/' + identifiant, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Agence
   */

    // HttpClient API get() method => Get all agence
  getAllAgence(): Observable<any> {
      return this.http.get<any>(this.API_URL + 'agence/all', this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

      // HttpClient API post() method => Get list agence by Direction
      getListAgenceByDirection(data): Observable<any> {
        return this.http.post<any>(this.API_URL + 'direction/liste_agences', data, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.handleError)
        );
      }


  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     return throwError(errorMessage);
  }

}
