import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text 
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[]
}
interface IChildItem {
  type?: string,
  name: string,       // Display text
  state?: string,     // Router state
  icon?: string,
  sub?: IChildItem[]
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {
  constructor() { }


  defaultMenu: IMenuItem[] = [
    {
      name: 'Tableau de bord',
      type: 'link',
      tooltip: 'Tableau de bord',
      icon: 'dashboard',
      state: 'dashboard'
    },
    {
      name: 'Parametrages',
      type: 'dropDown',
      tooltip: 'Dialogs',
      icon: 'settings',
      state: 'dialogs',
      sub: [
        { name: 'caissier voucher', state: 'confirm' },
        { name: 'type de compte', state: 'confirm' },
        { name: 'gestion de compte de jeux', state: 'confirm' },
        { name: 'gestion de compte bonus', state: 'confirm' },
        { name: 'type d operation', state: 'confirm' },
        { name: 'profil utilisateur', state: 'confirm' },
        { name: 'gestion de droit', state: 'confirm' },
        { name: 'gestion des entreprises', state: 'confirm' },
        { name: 'piste d audit', state: 'confirm' },
      ]
    },
    {
      name: 'Operation Globale',
      type: 'link',
      tooltip: 'Operation Globale',
      icon: 'beenhere',
      state: 'inbox'
    },
    {
      name: 'Detail Operation',
      type: 'dropDown',
      tooltip: 'Dialogs',
      icon: 'more',
      state: 'dialogs',
      sub: [
        { name: 'Pari PMU ALR', state: 'confirm' },
        { name: 'Pari PMU PLR', state: 'confirm' },
        { name: 'Pari SPORT CASH', state: 'confirm' },
        { name: 'Pari LOTO BONHEUR', state: 'confirm' },
        { name: 'Paiement de gains', state: 'confirm' },
      ]
    },
    {
      name: 'Compte',
      type: 'dropDown',
      tooltip: 'Dialogs',
      icon: 'group',
      state: 'dialogs',
      sub: [
        { name: 'Compte de rechargement', state: 'confirm' },
        { name: 'Compte systeme', state: 'confirm' },
        { name: 'Compte partenaire', state: 'confirm' },
        { name: 'Compte gains', state: 'confirm' },
        { name: 'Compte bonus', state: 'confirm' },
      ]
    },
    {
      name: 'Partenaires Financiers',
      type: 'dropDown',
      tooltip: 'Dialogs',
      icon: 'description',
      state: 'dialogs',
      sub: [
        { name: 'Paiement Reçu',
          type: 'dropDown',
          state: 'confirm',
          sub: [
            { name: 'ORANGE', state: 'confirm' },
            { name: 'MTN', state: 'confirm' },
            { name: 'MOOV', state: 'confirm' },
            { name: 'UBA', state: 'confirm' },
            { name: 'YUB', state: 'confirm' },
            { name: 'NSIA', state: 'confirm' },


          ]
        },
        { name: 'Paiement effectué',
          type: 'dropDown',
          state: 'confirm',
          sub: [
            { name: 'ORANGE', state: 'confirm' },
            { name: 'MTN', state: 'confirm' },
            { name: 'MOOV', state: 'confirm' },
            { name: 'UBA', state: 'confirm' },
            { name: 'YUB', state: 'confirm' },
            { name: 'NSIA', state: 'confirm' },


          ]
        }
      ]
    },
    {
      name: 'Voucher',
      type: 'dropDown',
      tooltip: 'Dialogs',
      icon: 'filter_none',
      state: 'dialogs',
      sub: [
        { name: 'Achat de voucher à la caisse',  state: 'confirm' },
        { name: 'Reglement de voucher à la caisse', state: 'confirm' },
        { name: 'Paiement reçu de voucher', state: 'confirm' },
        { name: 'Paiement effectué vers voucher', state: 'confirm' },
      ]
    }
  ]

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'separator-menu':
        this.menuItems.next(this.defaultMenu);
        break;
      default:
        this.menuItems.next(this.defaultMenu);
    }
  }
}
