import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.scss']
})
export class CreateuserComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateuserComponent>,
    private fb: FormBuilder,
  ) { }


  ngOnInit() {
    this.buildItemForm(this.data.payload)
  }

  buildItemForm(item) {
    console.log(item);
    this.itemForm = this.fb.group({
      utilisateurentreprise :[item.utilisateurentreprise || '', Validators.compose([Validators.required])],
      utilisateurname :[item.utilisateurname || '', Validators.compose([Validators.required])],
      utilisateurlogin :[item.utilisateurlogin || '', Validators.compose([Validators.required, Validators.email
      ])],
      utilisateurphone :[item.utilisateurphone || '', Validators.compose([Validators.required])],
      utilisateurpassword :[item.utilisateurpassword || '', Validators.compose([Validators.required])]
    });
  }

  submit() {
    this.dialogRef.close(this.itemForm.value);
  }


}
