import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatDialog, MatProgressBar, MatSnackBar, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../shared/services/dashboard.service';
import { AppConfirmService } from '../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../shared/services/app-loader/app-loader.service';
import { Utilisateur } from '../../shared/models/utilisateur';
import { Fonctionnalite } from '../../shared/models/fonctionnalite';
import { Newprofil } from '../../shared/models/newprofil';
import { Genreoperation } from '../../shared/models/genreoperation';
import { Servicce } from '../../shared/models/servicce';
import { Granularite } from '../../shared/models/granularite';
import { Wallet } from '../../shared/models/wallet';
import { Profil } from '../../shared/models/profil';
import { Servicse } from '../../shared/models/servicse';
import { Signupform } from '../../shared/models/signupform';
import { Caissier } from '../../shared/models/caissier';
import { Caissiere } from 'app/shared/models/caissiere';
import { AuthenticationService } from '../../shared/services/auth.service';
import { GlobalVariableService } from '../../shared/services/global-variable.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  signinForm: FormGroup;
  hide = true;
  service: string;
  constructor(public formBuilder: FormBuilder,
    public snackBar: MatSnackBar, public router: Router,
    public dasboardservice: DashboardService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private variable: GlobalVariableService,
    private auth: AuthenticationService,
    private loader: AppLoaderService) { }


  ngOnInit() {
    localStorage.clear();
    let routing = this.router.url.split(';');
    this.service = routing[1];
    this.signinForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      stay_connected: new FormControl('')
    })
  }


  signin() {
    const signinData = this.signinForm.value
    let path: string;
    //path= "getlogin/"+this.signinForm.controls['username'].value+"/"+this.signinForm.controls['password'].value ;
    let valuetosend: Signupform = new Signupform();
    valuetosend.username = this.signinForm.controls['username'].value;
    valuetosend.password = this.signinForm.controls['password'].value;
    if (this.signinForm.controls['stay_connected'].value == "") valuetosend.stay_connected = false
    else valuetosend.stay_connected = true
    valuetosend.service = 'cnam';
    this.variable.setStayConnected(valuetosend.stay_connected);
    this.loader.open();
    if (this.service === 'service=cnam') {
      this.loginCnam(valuetosend);
    } else if (this.service === 'service=historique') {
      this.loginHistorique(valuetosend);
    } else {
      this.loginCaissier(signinData);
    }
    //this.router.navigate(['/accueil']);
  }

  reset_password() {
    if (this.service === 'service=cnam') {
      this.router.navigate(['/forgetpassword-cnam']);
    } else {
      this.router.navigate(['/forgetpassword', { service: this.service }]);
    }
    // this.router.navigate(['/forgetpassword', { service: this.service }]);
  }

  loginCnam(data) {
    this.auth.sign_in(data).subscribe(data => {
      console.log(" user get ");
      console.log(data);
      switch (data.error) {
        case true:
          this.loader.close();
          this.snack.open(data.message, 'OK', { duration: 4000 });
          break;
        default: {
          this.loader.close();
          this.variable.setAccessToken(data.data.access_token);
          this.variable.setUser(data.data.user);
          this.variable.setPermissions(data.data.permission);
          if (data.data.user.role.slug === 'chef-dagence' || data.data.user.role.slug === 'caissier') {
            this.router.navigate(['/mes-ventes']);
          } else {
            this.router.navigate(['/historique-vente']);
          }
          break;
        }
      }
    },
      error => {
        this.loader.close();
        this.snack.open(error, 'OK', { duration: 4000 })
      }
    );
  }

  loginHistorique(data) {
    this.auth.sign_in(data).subscribe(data => {
      switch (data.error) {
        case true:
          this.loader.close();
          this.snack.open(data.message, 'OK', { duration: 4000 });
          break;
        default: {
          this.loader.close();
          this.variable.setAccessToken(data.data.access_token);
          this.variable.setUser(data.data.user);
          this.variable.setPermissions(data.data.permission);
          if (data.data.user.role.slug === 'gestionnaire' || data.data.user.role.slug === 'administrateur' || data.data.user.role.slug === 'chef-dagence') {
            
            this.router.navigate(['/historique-vente-generale']);
          } else {
            this.loader.close();
            this.snack.open('Vous n\'avez pas droit à visualiser l\'historique génerale', 'OK', { duration: 7000 });
          }
          break;
        }
      }
    },
      error => {
        this.loader.close();
        this.snack.open(error, 'OK', { duration: 4000 })
      }
    );
  }

  loginCaissier(data) {
    this.dasboardservice.loginCaissier(data).subscribe(data => {
      switch (data.status) {
        case 200: {
          this.loader.close();
          console.log(data);
          let currentuser: Caissiere = data.body;
          console.log(" BODY : ", data.body);
          localStorage.setItem('curentuser', JSON.stringify(currentuser));
          console.log(" curentuser : ", JSON.parse(localStorage.getItem("curentuser")));
          if (data.body.status == 'gestionnaire') { // verifier si root
            this.router.navigate(['/historiquegestionaire']);
          } else if(data.body.status == 'admin')  {
            this.router.navigate(['/agence']);
          } else if(data.body.status == 'caisse')  {
            this.router.navigate(['/generationvoucher']);
          }else if(data.body.status == 'chefagence')  {
            this.router.navigate(['/achatagence']);
          } else {
            this.router.navigate(['/historique-vente']);
          }

          break;
        }
      }
    },
      error => {

        switch (error.status) {

          case 401:

            this.loader.close();
            this.snack.open('Echec veuillez verifier vos parametres ', 'OK', { duration: 4000 })


            break;
          default: {

            this.loader.close();
            this.snack.open('Echec veuillez verifier vos parametres ', 'OK', { duration: 4000 })

            break;
          }
        }
      }
    );
  }


  getItemsWallet() {
    this.dasboardservice.getallWallet()
      .subscribe(data => {
        console.log(data);
        let resultat: any[] = data;
        let wallet: Wallet[];
        wallet = resultat;
        localStorage.setItem('allwallet', JSON.stringify(wallet));
      });
  }


  getItemsGranularite() {
    this.dasboardservice.getallgranularite()
      .subscribe(data => {
        console.log(data);
        let resultat: any[] = data;
        let granularite: Granularite[];
        granularite = resultat;
        localStorage.setItem('allgranularity', JSON.stringify(granularite));
      });
  }


  getItemsServicces() {
    this.dasboardservice.getallServices()
      .subscribe(data => {
        console.log(data);
        let resultat: any[] = data;
        let services: Servicce[];
        services = resultat;
        localStorage.setItem('allservice', JSON.stringify(services));
      });
  }





  getItemGetServces() {
    this.dasboardservice.getalleservics()
      .subscribe(data => {
        console.log("getalleservics");
        console.log(data);
        let resultat: any[] = data;
        let services: Servicse[];
        services = resultat;
        localStorage.setItem('allservics', JSON.stringify(services));
      });
  }


  redirect(value: string) {
    this.router.navigateByUrl(value);
  }




  /*
  getallfonctionalite(){
    this.dasboardservice.getallfonctionalite().subscribe(data => {
      console.log(data);
      let foncts : Fonctionnalite[] = [] ;
      foncts = data ;
      //localStorage.setItem('allfonctionnatite',JSON.stringify(foncts));
      console.log(localStorage.getItem('allfonctionnatite'));

    });
  }



  getGenreOperation(){
    this.dasboardservice.getalltypeoperation().subscribe(data => {
      //console.log(data);
      let ops : Genreoperation[] = [] ;
      ops = data ;
      localStorage.setItem('allgenreoperation',JSON.stringify(ops));
    //  console.log("allgenreoperation");
     // console.log(localStorage.getItem('allgenreoperation'));

    });
  }

*/




  getallfonctionalite() {
    this.dasboardservice.getallfonctionalite().subscribe(data => {
      console.log(data);
      let foncts: Fonctionnalite[] = [];
      foncts = data;
      localStorage.setItem('allfonctionnatite', JSON.stringify(foncts));
      console.log(" nos fonctionalites ");
      console.log(localStorage.getItem('allfonctionnatite'));

    });
  }


}
