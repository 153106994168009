import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentnotifComponent } from './paymentnotif.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatIconModule, MatButtonModule, MatCardModule, MatMenuModule, MatSlideToggleModule, MatGridListModule, MatChipsModule, MatCheckboxModule, MatRadioModule, MatTabsModule, MatInputModule, MatProgressBarModule, MatSelectModule, MatDatepickerModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatInputModule,
    MatProgressBarModule,
    FlexLayoutModule,
    NgxDatatableModule,
    ChartsModule,
    FileUploadModule,
    SharedModule,
    MatSelectModule,
    MatDatepickerModule,
    BrowserAnimationsModule
  ],
  declarations: [PaymentnotifComponent]
})
export class PaymentnotifModule { }
