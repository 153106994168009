import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../../../shared/services/excel.service';
import swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EditCashierComponent } from './edit-cashier/edit-cashier.component';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { DetailsCashierComponent } from './details-cashier/details-cashier.component';
import {CaissierService} from "../../../shared/services/caissier.service";
import {Cashier} from "../../../shared/models/cashier";
import {SettingsService} from "../../../shared/services/settings.service";
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { GestionCaissier } from 'app/shared/models/permissions';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss']
})
export class CashierComponent implements OnInit {
  status: string = 'Desactivé';
  selectedAgence = [];
  listAgence: any = [];
  cashierData: Cashier = new Cashier(null, null, null, null, null, null);
  public response: any;
  listCaissier = [];
  loaderText: string;
  API_URL: any;
  filter: any;
  p: number = 1;
  arrayCaissier: any = [];
  arrayPermissions: any = [];
  permissionsCaissier: GestionCaissier = new GestionCaissier(null, null, null, null, null, null, null);
  submitted = false;
  settingsAgence = {};
  formGroup = this.fb.group({
    file: [null, Validators.required]
  });
  constructor(private fb: FormBuilder, private eventEmitter: EventEmitterService, private _settings: SettingsService, private variable: GlobalVariableService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, private http: HttpClient, private _router: Router, private _caissier: CaissierService,
  private toastrService: ToastrService, private spinner: NgxSpinnerService, private excelService: ExcelService,
  ) { }

  addCaissier(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  detailsCaissier = function(data) {
    const modalRef = this.modalService.open(DetailsCashierComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.cashier = data;
  };

  editCaissier = function(data) {
    const modalRef = this.modalService.open(EditCashierComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.cashier = data;
  };

  lockCaissier(data){
    this._caissier.disableCaissier(data.id)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.refresh();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
}

    // Upload file
    onFileChange(ev) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
        this.formGroup.patchValue({
          file: reader.result
        });
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        const dataStringParse = JSON.parse(dataString);
        this.arrayCaissier = dataStringParse;
        // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
        // this.setDownload(dataString);
      }
      reader.readAsBinaryString(file);
    }
  
    addCaissierBulk(content) {
      this.activeModal = this.modalService.
      open(content, { backdrop: 'static', keyboard: false, centered: true  });
    }
  
    // Submit Form
    onSubmit() {
      this.submitted = true;
      // stop here if form is invalid 
      if (this.formGroup.invalid) {
        return;
      }
      this.saveAllCaissier(this.arrayCaissier);
    }
  
    // Save all caissier
  saveAllCaissier(data) {
      this._caissier.saveAllCaissier(data).subscribe((data: {}) => {
        this.response = data;
        if (!this.response.error) {
          swal({
            title: 'CNAM SYSTEM',
            text: this.response.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.excelService.exportAsExcelFile(this.response.data, 'Rapport sauvegarde des caissiers');
              this.getAllCaissier();
            }
          });
        } else {
          swal('CNAM SYSTEM', this.response.message, 'error')
        }
        // !this.response.error ? this.toastr.success(this.response.message) : swal.fire('CNAM SYSTEM', this.response.message, 'error');
      });
    }

  saveCaissier() {
      this._caissier.createCaissier(this.cashierData)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            this.activeModal.close();
            this.cashierData.nom_prenoms = '';
            this.cashierData.code = '';
            this.cashierData.email = '';
            this.cashierData.telephone = '';
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.activeModal.close();
                this.getAllCaissier();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    }

  deleteCaissier(data) {
      swal({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas revenir en arrière!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonText: 'Oui, supprimez-le!'
      }).then((result) => {
        if (result.value) {
          this._caissier.deleteCaissier(data.id)
          .subscribe(
            res => {
              console.log(res);
              if (res.error === false) {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'success',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                }).then((result)  => {
                  if (result) {
                    this.activeModal.close();
                    this.refresh();
                  }
                });
              } else {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }

    onSelectAgence(value) {
        this.cashierData.agence_id = value.id;
    }

  onSelectService(value){
    this.cashierData.service = value;
  }

    // Get all agence
    getAllAgence() {
        this._settings.getAllAgence().subscribe((data: {}) => {
            this.response = data;
            this.listAgence = this.response.data;
        });
    }

  closeModal() {
    this.activeModal.close();
  }

  getListPermissions() {
    this.arrayPermissions = this.variable.getPermissions();
    const create_caissier = this.arrayPermissions.find(x => x.slug === 'creation-de-caissier');
    const update_caissier = this.arrayPermissions.find(x => x.slug === 'modification-de-caissier');
    const suppression_caissier = this.arrayPermissions.find(x => x.slug === 'suppression-de-caissier');
    const exportation_caissier = this.arrayPermissions.find(x => x.slug === 'exportation-de-la-liste-des-caissiers');
    const creation_masse_caissier = this.arrayPermissions.find(x => x.slug === 'creation-en-masse-des-caissiers');
    this.permissionsCaissier.creation_caissier = create_caissier.is_visible;
    this.permissionsCaissier.modification_caissier = update_caissier.is_visible;
    this.permissionsCaissier.suppression_caissier = suppression_caissier.is_visible;
    this.permissionsCaissier.exportation_caissier = exportation_caissier.is_visible;
    this.permissionsCaissier.creation_masse_caissier = creation_masse_caissier.is_visible;
  }



  // export to excel
  exportXls() {
    let data = [];
    this.listCaissier.forEach(element => {
      data.push(
        {
          'Code': element.code,
          'Nom & Prénoms': element.nom_prenoms,
          'Email': element.email,
          'Service': element.service,
          'Agence': element.agence,
          'Télephone': element.telephone,
          'Statut': element.status ? 'Activé' : 'Desactivé'
        }
      )
    });
    this.listCaissier.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste des caissiers') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

  // Get all caissier
  getAllCaissier() {
    this._caissier.getAllCaissier().subscribe((data: {}) => {
      this.response = data;
      this.listCaissier = this.response.data;
    });
  }

  refresh() {
    this.getAllCaissier();
  }

  ngOnInit(): void {
    this.getAllCaissier();
    this.getAllAgence();
    this.getListPermissions();
    this.settingsAgence = {
      singleSelection: true,
      text: 'Sélectionner l\'agence',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_agence',
      enableSearchFilter: true,
      searchBy: ['nom_agence'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune agence disponible'
    };
    if (this.eventEmitter.subsVar === undefined) {
      this.eventEmitter.subsVar = this.eventEmitter.
      invokeOnGetAllCaissier.subscribe((name: string) => {
        this.getAllCaissier();
      });
    }
  }

}
