import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { CaissiereService } from '../caissier/caissiere.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { PopupcaissierComponent } from '../caissier/popupcaissier/popupcaissier.component';
import { PopupgestComponent } from './popupgest/popupgest.component';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-gestionnaire',
  templateUrl: './gestionnaire.component.html',
  styleUrls: ['./gestionnaire.component.scss']
})
export class GestionnaireComponent implements OnInit {

  getItemSub: Subscription;
  public items: any[];
  temp = [];


  constructor(private snack: MatSnackBar, private caissiereService: CaissiereService,
    private dialog: MatDialog, private confirmService: AppConfirmService,
    private loader: AppLoaderService) { }

  ngOnInit() {
    this.getData();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  getData() {
    this.snack.open('Recherche en cours...', 'OK', { duration: 4000 })

    this.getItemSub = this.caissiereService.findAllGestionnaire()
      .subscribe(data => {

        let status = data.status;
        switch (status) {
          case 200:
            console.log('DATA: ', data);
            this.items = data.body;
            this.temp = data.body;
            this.snack.open('Chargement terminé', 'OK', { duration: 4000 })
            break;

          case 204:
            this.snack.open('Aucune ligne trouvé', 'OK', { duration: 4000 })
            break;

          default:
            this.items = data.body;
            this.snack.open('Chargement terminé', 'OK', { duration: 4000 })
            break;
        }



      }, error => {
        this.snack.open('Une erreur survenue, reessayer SVP', 'OK', { duration: 4000 });

      })
  }

  creationCaissier(data: any = {}, isNew?) {
    let title = isNew ? "Creation d'un gestionaire" : "Mettre à jour un gestionnaire";
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupgestComponent, {
      disableClose: true,
      width: '720px',
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        } else {
          this.getData(); // Rafraichir données
        }
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Supprimé ${row.caissierelogin}?` })
      .subscribe(res => {
        if (res) {
          this.loader.open();
          this.caissiereService.deleteCaissiere(row.caissiereid)
            .subscribe(data => {
              let statuscode = data.status;

              switch (statuscode) {
                case 200: {
                  this.getData();
                  this.loader.close();
                  //this.snack.open('Caissiere Supprimé!', 'OK', { duration: 4000 })
                
                  this.openDialog("le gestionnaire a été supprimé avec succes");
                  break;
                }
                default: {
                  this.loader.close();
                  this.openDialog("le gestionnaire a été supprimé avec succes");
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;

                switch (statuscode) {

                  case 500: {
                    this.loader.close();
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.loader.close();
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }


              });
        }


      })
  }


  openDialog(value : string): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: value
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }


}
