import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { SettingsService } from 'app/shared/services/settings.service';

@Component({
  selector: 'app-details-agency',
  templateUrl: './details-agency.component.html',
  styleUrls: ['./details-agency.component.scss']
})
export class DetailsAgencyComponent implements OnInit {
  @Input() agence;
  response: any;
  constructor(private activeModal: NgbActiveModal,
    private eventEmitter: EventEmitterService) { }

  ngOnInit() {
  }


  closeModal() {
    this.activeModal.close();
  }


}
