import { AdminModule } from './views/admin/admin.module';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MAT_DATE_LOCALE } from '@angular/material';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';


import { DialognotifComponent } from './shared/services/dialognotif/dialognotif.component';


import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {AppMapModule} from './views/map/map.module';
import {UserIdleModule} from 'angular-user-idle';
import {DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import { ResetpasswordComponent } from './views/resetpassword/resetpassword.component';
import {ForgetpasswordModule} from './views/forgetpassword/forgetpassword.module';
import {ResetpasswordModule} from './views/resetpassword/resetpassword.module';
import {SigninModule} from './views/signin/signin.module';
import {AngularDateTimePickerModule} from 'angular2-datetimepicker';
import {CpteuserModule} from './views/cpteuser/cpteuser.module';
import {StatopModule} from './views/statop/statop.module';
import {DetailopModule} from './views/detailop/detailop.module';
import {WalletModule} from './views/wallet/wallet.module';
import {ServiccesModule} from './views/servicces/servicces.module';
import {PourcentageModule} from './views/pourcentage/pourcentage.module';
import {AccesdeniedModule} from './views/accesdenied/accesdenied.module';
import { ListprofilModule } from './views/listprofil/listprofil.module';
import { CreerprofileModule } from './views/creerprofile/creerprofile.module';
import { LierprofileuserModule } from './views/lierprofileuser/lierprofileuser.module';
import { ModifierprofileModule } from './views/modifierprofile/modifierprofile.module';
import { MontantvireaupartModule } from './views/montantvireaupart/montantvireaupart.module';
import { VirementrecuopModule } from './views/virementrecuop/virementrecuop.module';
import { AccueilcaisseComponent } from './views/accueilcaisse/accueilcaisse.component';
import { AccueilcaisseModule } from './views/accueilcaisse/accueilcaisse.module';
import { GenerationvoucherModule } from './views/generationvoucher/generationvoucher.module';
import { ReglementvoucherModule } from './views/reglementvoucher/reglementvoucher.module';
import { ConsultationvoucherModule } from './views/consultationvoucher/consultationvoucher.module';
import { HistoriquevoucherModule } from './views/historiquevoucher/historiquevoucher.module';
import { HistoriquereglementvoucherModule } from './views/historiquereglementvoucher/historiquereglementvoucher.module';
import { VoucheroperationModule } from './views/voucheroperation/voucheroperation.module';
import localeFr from '@angular/common/locales/fr';
import { AgencecaisseModule } from './views/agencecaisse/agencecaisse.module';

import { PaymentnotifModule } from './views/paymentnotif/paymentnotif.module';
import { PortailModule } from './views/portail/portail.module';
import { ConfigModule } from 'ngx-envconfig';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { GlobalHttpInterceptorService } from './shared/services/global-http-interceptor.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';
import { CnamModule } from './views/cnam/cnam.module';
import { ForgetpasswordCnamModule } from './views/forgetpassword-cnam/forgetpassword-cnam.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
//import { GatewayrspComponent } from './views/cnam/deposit/gatewayrsp/gatewayrsp.component';


registerLocaleData(localeFr)

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [

    UserIdleModule.forRoot({idle: 300, timeout: 1, ping: 60}),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ForgetpasswordModule,
    ForgetpasswordCnamModule,
    ResetpasswordModule,
    SigninModule,
    VoucheroperationModule,
    AngularDateTimePickerModule,
    CpteuserModule,
    StatopModule,
    DetailopModule,
    WalletModule,
    ListprofilModule,
    LoadingBarHttpClientModule,
    CreerprofileModule,
    ModifierprofileModule,
    ServiccesModule,
    PourcentageModule,
    LierprofileuserModule,
    MontantvireaupartModule,
    VirementrecuopModule,
    AccesdeniedModule,
    AccueilcaisseModule,
    GenerationvoucherModule,
    ReglementvoucherModule,
    ConsultationvoucherModule,
    HistoriquevoucherModule,
    HistoriquereglementvoucherModule,
    PaymentnotifModule,
    AgencecaisseModule,
    PortailModule,
    AdminModule,
    CnamModule,
    AppMapModule,
    //GatewayrspComponent,
    AngularMultiSelectModule,
    ConfigModule.forRoot({state: 'development'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false })
  ],
  declarations: [AppComponent,DialognotifComponent],
  entryComponents: [DialognotifComponent],
  providers: [
    DatePipe ,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID
    },
    // { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
