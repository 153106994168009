import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { Montantvireaupart } from 'app/shared/models/montantvireaupart';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Virementrecuop } from 'app/shared/models/virementrecuop';
import { CreatevirementrecuopComponent } from './createvirementrecuop/createvirementrecuop.component';
import { DatePipe } from '@angular/common';
import { Servicce } from '../../shared/models/servicce';
import { Wallet } from '../../shared/models/wallet';
import { Servicse } from 'app/shared/models/servicse';
import { Walet } from 'app/shared/models/walet';
import { UpdatevirementrecuopComponent } from './updatevirementrecuop/updatevirementrecuop.component';

@Component({
  selector: 'app-virementrecuop',
  templateUrl: './virementrecuop.component.html',
  styleUrls: ['./virementrecuop.component.scss']
})
export class VirementrecuopComponent implements OnInit {

  public itemsrv: Servicse[];
 
  public items: any[];
  temp = [];
  rows = [];

  columns = [];

  public getItemSub: Subscription;
  dataSource: MatTableDataSource<Virementrecuop> = new MatTableDataSource([]);

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    
    this.getItems();
  }


  getItems() {

    this.getItemSub = this.dashboardService.findallvirementrecuop()
      .subscribe(data => {
        console.log("resultat");
        console.log(data);
        this.items = this.temp = data;
        this.dataSource = new MatTableDataSource(this.items);

      });

  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }





  convertToHeure(d: any) {
    if (d != null) {
      let value: string = d.toString();
      return value.substring(11, 16);
    } else {
      return null;
    }
  }


  convertToDate(d: any) {
    if (d != null) {
      let value: string = d.toString();
      return value.substring(0, 10);
    } else {
      return null;
    }
  }


  convertDateHeure(d: any) {
    let date : string = this.convertToDate(d);
    let heur : string = this.convertToHeure(d);
    return date+" "+heur;
  }

  openAddPup(data: any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(CreatevirementrecuopComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        let s : Servicse = res.services ;
        let w : Walet = res.wallet ;


        let v : Virementrecuop = new Virementrecuop();
        /*
        let s : Servicce = res.services ;
        let w : Wallet = res.wallet ;
        */

        v = res ;

        v.ecom=s.name;
        v.ecomtoken=s.token;

        v.wallet=w.name;
        v.wallettoken=w.authentication_token;

        let dv : string ="";
        dv=res.datevalidemontant;
        let newDate = new Date(dv);
        v.dateclient=this.datePipe.transform(newDate,"dd/MM/yyyy");
        console.log(" requette ") ;
        console.log(v) ;

        console.log(" final ") ;
        let vir : Virementrecuop = new Virementrecuop();
        vir.dateclient=v.dateclient;
        vir.ecom=v.ecom;
        vir.ecomtoken=v.ecomtoken;
        vir.wallet=v.wallet;
        vir.wallettoken=v.wallettoken;
        vir.montant=v.montant;
        console.log(vir) ;

        this.dashboardService.createVirement(vir).subscribe(data => {
          switch (data.status) {
            case 200: {
              this.getItems();
              this.loader.close();
              this.snack.open('virement crée avec succes !', 'OK', { duration: 4000 })
              break;
            }
            case 201: {

              this.getItems();
              this.loader.close();
              this.snack.open('virement crée avec succes', 'OK', { duration: 4000 })
              break;
            }
            
            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        },
        error => {
          console.log('data');
          console.log(error);
          switch(error.status){
         
            case 500: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        });


       
      });
  }



  deleteItem(row) {
    this.confirmService.confirm({message: `Voulez vous supprimer ce virement ?`})
      .subscribe(res => {
        if (res) {
          this.loader.open();
          this.dashboardService.deleteVirement(row.virementrecuopid)
            .subscribe(data => {
                switch (data.status) {
                  case 200: {
                    console.log('data');
                    console.log(data);

                    this.loader.close();
                    this.snack.open('Virement Supprimé!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.loader.close();
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.loader.close();
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }
                this.getItems();
              },
              error => {
                console.log('data');
                console.log(error);
                switch (error.status) {
                  case 400: {
                    this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }
                this.loader.close();
                this.snack.open('Erreur!', 'OK', { duration: 4000 })
              });
        }

      })
  }



  
  openPopUp(data: any = {}, isNew?) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdatevirementrecuopComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        let s : Servicse = res.services ;
        let w : Walet = res.wallet ;


        let v : Virementrecuop = new Virementrecuop();
        /*
        let s : Servicce = res.services ;
        let w : Wallet = res.wallet ;
        */

        v = res ;

        v.ecom=s.name;
        v.ecomtoken=s.token;

        v.wallet=w.name;
        v.wallettoken=w.authentication_token;

        v.dateclient=res.datevalidemontant ;

        let dv : string ="";
        dv=res.datevalidemontant;
        let newDate = new Date(dv);
        v.dateclient=this.datePipe.transform(newDate,"dd/MM/yyyy");
        console.log(" requette ") ;
        console.log(v) ;

        console.log(" final ") ;
        let vir : Virementrecuop = new Virementrecuop();
        vir.dateclient=v.dateclient;
        vir.ecom=v.ecom;
        vir.ecomtoken=v.ecomtoken;
        vir.wallet=v.wallet;
        vir.wallettoken=v.wallettoken;
        vir.montant=v.montant;
        vir.virementrecuopid=res.virementrecuopid;

        console.log(vir) ;

        this.loader.open();

        this.dashboardService.updateVirement(vir).subscribe(data => {
          switch (data.status) {
            case 200: {
              this.getItems();
              this.loader.close();
              this.snack.open('Virement mis à jour avec succes !', 'OK', { duration: 4000 })
              break;
            }
            case 201: {

              this.getItems();
              this.loader.close();
              this.snack.open('Virement mis à jour avec succes', 'OK', { duration: 4000 })
              break;
            }
            
            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        },
        error => {
          console.log('data');
          console.log(error);
          switch(error.status){
         
            case 500: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        });


      })
  }



}
