import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Utilisateur } from '../../models/utilisateur';
import { Caissier } from '../../models/caissier';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically
  
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let u = new Caissier() ;
    u=JSON.parse(localStorage.getItem("curentuser"));
    if (u!=null) {
      return true
    }
    this.router.navigate(['/seconnecter']);
    return false;
  }
}
