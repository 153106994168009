import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../../../../shared/services/excel.service';
import { GlobalVariableService } from '../../../../shared/services/global-variable.service';
import * as XLSX from 'xlsx';
import { SettingsService } from '../../../../shared/services/settings.service';
import swal from 'sweetalert2';
import { EditInspectionComponent } from './edit-inspection/edit-inspection.component';
import { GestionInspections } from '../../../../shared/models/permissions';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {
  public response: any;
  listInspection: any = [];
  loaderText: string;
  API_URL: any;
  filter: any;
  p: number = 1;
  arrayInspection: any = [];
  arrayPermissions: any = [];
  submitted = false;
  code: any;
  nom_inspection: any;
  permissionsSettingsInspections: GestionInspections = new GestionInspections(null, null, null, null, null, null, null);
  formGroup = this.fb.group({
    file: [null, Validators.required]
  });
  constructor(private fb: FormBuilder, private modalService: NgbModal, private _settings: SettingsService,
    private toastrService: ToastrService, private spinner: NgxSpinnerService, private excelService: ExcelService,
    private activeModal: NgbActiveModal, private variable: GlobalVariableService, private eventEmitter: EventEmitterService) { }

  ngOnInit() {
    this.getAllInspection();
    this.getListPermissions();
    if (this.eventEmitter.subsVar === undefined) {
      this.eventEmitter.subsVar = this.eventEmitter.
      invokeOnGetAllInspection.subscribe((name: string) => {
        this.getAllInspection();
      });
    }
  }

  getListPermissions() {
    this.arrayPermissions = this.variable.getPermissions();
    const ajouter_inspection = this.arrayPermissions.find(x => x.slug === 'creation-de-inspections');
    const ajouter_inspection_masse = this.arrayPermissions.find(x => x.slug === 'creation-en-masse-de-inspections');
    const exportation_liste_inspection = this.arrayPermissions.find(x => x.slug === 'exportation-des-inspections');
    const modifier_inspection = this.arrayPermissions.find(x => x.slug === 'modification-de-inspections');
    const supprimer_inspection = this.arrayPermissions.find(x => x.slug === 'suppression-de-inspections');
    this.permissionsSettingsInspections.creation_inspection = ajouter_inspection.is_visible;
    this.permissionsSettingsInspections.modification_inspection = modifier_inspection.is_visible;
    this.permissionsSettingsInspections.suppression_inspection = supprimer_inspection.is_visible;
    this.permissionsSettingsInspections.exportation_inspection = exportation_liste_inspection.is_visible;
    this.permissionsSettingsInspections.creation_masse_inspection = ajouter_inspection_masse.is_visible;
  }

  addInspection(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  editInspection = function(data) {
    const modalRef = this.modalService.open(EditInspectionComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.inspection = data;
  };

    // Upload file
    onFileChange(ev) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
        this.formGroup.patchValue({
          file: reader.result
        });
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        const dataStringParse = JSON.parse(dataString);
        this.arrayInspection = dataStringParse;
      }
      reader.readAsBinaryString(file);
    }
  
  addInspectionBulk(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }
  
  // Submit Form
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid 
    if (this.formGroup.invalid) {
      return;
    }
    this.saveAllInspection(this.arrayInspection);
  }

  // Save all inspection
  saveAllInspection(data) {
      this._settings.saveAllInspection(data).subscribe((data: {}) => {
        this.response = data;
        if (!this.response.error) {
          swal({
            title: 'CNAM SYSTEM',
            text: this.response.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.excelService.exportAsExcelFile(this.response.data, 'Rapport sauvegarde des inspections');
              this.getAllInspection();
            }
          });
        } else {
          swal('CNAM SYSTEM', this.response.message, 'error')
        }
        // !this.response.error ? this.toastr.success(this.response.message) : swal.fire('CNAM SYSTEM', this.response.message, 'error');
      });
    }

  saveInspection() {
    const data = {
      'code': this.code,
      'nom': this.nom_inspection
    }
      this._settings.createInspection(data)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            this.activeModal.close();
            this.code = '';
            this.nom_inspection = '';
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.activeModal.close();
                this.getAllInspection();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  deleteInspection(data) {
    swal({
      title: 'Êtes-vous sûr?',
      text: 'Vous ne pourrez pas revenir en arrière!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Non, Annuler!',
      confirmButtonText: 'Oui, supprimez-le!'
    }).then((result) => {
      if (result.value) {
        this._settings.deleteInspection(data.id)
        .subscribe(
          res => {
            console.log(res);
            if (res.error === false) {
              swal({
                title: 'CNAM SYSTEM',
                text: res.message,
                type: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result)  => {
                if (result) {
                  this.activeModal.close();
                  this.getAllInspection();
                }
              });
            } else {
              swal({
                title: 'CNAM SYSTEM',
                text: res.message,
                type: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              });
            }
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  }

  lockInspection(data){
    this._settings.disableInspection(data.id)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.getAllInspection();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  closeModal() {
    this.activeModal.close();
  }
  
  // export to excel
  exportXls() {
    let data = [];
    this.listInspection.forEach(element => {
      data.push(
        {
          'Code': element.code,
          'Nom inspection': element.nom,
        }
      )
    });
    this.listInspection.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste des inspections') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

  // Get all inspection
  getAllInspection() {
    this._settings.getAllInspection().subscribe((data: {}) => {
      this.response = data;
      this.listInspection = this.response.data;
    });
  }

}
