import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'ngx-envconfig';
import { Router } from '@angular/router';
import { GlobalVariableService } from './global-variable.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  // Define API
  API_URL: string;
  API_URL_LOGIN_CNAM: string;
  private loggedInStatus = false
  constructor(private http: HttpClient, public configService: ConfigService, private router: Router, private variable: GlobalVariableService) {
    this.API_URL = configService.get('HOST_API_CNAM');
    this.API_URL_LOGIN_CNAM = configService.get('HOST_API_ASSURE_CNAM');
  }
  /**
   * @author André ATCHORI
   */

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.variable.getAccessToken()
    })
  };

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value
  }

  get isLoggedIn() {
    return this.loggedInStatus
  }

  // public isAuthenticated(): boolean {
  //   const token = this.variable.getAccessToken();
  //   return !this.jwtHelper.isTokenExpired(token);
  // }

  // HttpClient API post() method => Sign in user
  sign_in(user): Observable<any> {
    return this.http.post<any>(this.API_URL + 'login', user)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => auth API CNAM
  loginCNAM(data): Observable<any> {
    return this.http.post<any>(this.API_URL_LOGIN_CNAM + 'platform/auth',  data)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API get() method => Get user details
  getUserDetails(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'user/details', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API update() method => Reset password
  forget_password(user): Observable<any> {
    return this.http.put<any>(this.API_URL + 'forgetpassword', user, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API update() method => UPDATE password
  update_password(data, token): Observable<any> {
    return this.http.put<any>(this.API_URL + 'user/changepassword', data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      }  
    )
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API update() method => UPDATE user data
  update_info(user): Observable<any> {
    return this.http.put<any>(this.API_URL + 'user/update', user, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     return throwError(errorMessage);
  }

}
