import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Historiquecaisse } from '../../shared/models/historiquecaisse';
import { Subscription } from 'rxjs';
import { Caissier } from 'app/shared/models/caissier';
import { Pmurequest } from 'app/shared/models/pmurequest';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Valeur } from '../../shared/models/valeur';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-voucheroperation',
  templateUrl: './voucheroperation.component.html',
  styleUrls: ['./voucheroperation.component.scss']
})
export class VoucheroperationComponent implements OnInit {


  public itemFormCumul: FormGroup;

  public valueVente: Valeur = new Valeur();

  public valuePaiement: Valeur = new Valeur();


  public ventemontant: string = "0";
  public paiementmontant: string = "0";
  public soldemontant: string = "0";


  public nbrevente: string = "0";
  public nbrepaiement: string = "0";




  public items: any[];
  temp = [];
  rows = [];
  columns = [];


  datedebut: string = "";

  datefin: string = "";


  periodedebut: string = "";

  periodefin: string = "";


  public getItemSub: Subscription;
  dataSource: MatTableDataSource<Historiquecaisse> = new MatTableDataSource([]);



  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private fb: FormBuilder,
    private fbc: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  updateFilter(event){

  }

  ngOnInit() {

    this.getItems();


    this.researchStatAchatForAll();
    this.researchStatReglementForAll();

    this.itemFormCumul = this.fb.group({
      toc: [''],
      fromc: [''],
    });


  }


  getTimeByFormat(value: string) {
    let day: string = value.substring(8, 10);
    let mount: string = value.substring(5, 7);
    let year: string = value.substring(0, 4);
    let hours: string = value.substr(10);
    return day + "-" + mount + "-" + year + " " + hours;
  }




  allresearch() {
    console.log(" allresearch ");
    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      let to: string = "";
      let from: string = "";
      this.datedebut = "";
      this.datefin = "";

      console.log("itemFormCumul " + this.itemFormCumul.get("fromc").value.toString());

      let date = new Date(this.itemFormCumul.get("fromc").value);
      let date1 = new Date(this.itemFormCumul.get("toc").value);
      console.log(" var date : " + date);
      console.log("message");
      console.log("" + date.toString() === 'Invalid Date');
      console.log(" fromc " + this.itemFormCumul.get("fromc"));
      console.log(" toc " + this.itemFormCumul.get("toc"));
      console.log(this.itemFormCumul);
      if ((date.toString() != 'Invalid Date')) {
        from = this.itemFormCumul.get("fromc").value;
      }

      if (date1.toString() != null) {
        to = this.itemFormCumul.get("toc").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);

      console.log(" date " + newDate);
      console.log(" date1 " + newDate1);

      if ((newDate.toDateString() === "Invalid Date") || (newDate1.toDateString() === "Invalid Date")) {
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      } else {
        if (newDate1 >= newDate) {
          this.loader.open();
          console.log("true");
          let cumulstat: Pmurequest = new Pmurequest();
          cumulstat.from = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
          this.datedebut = cumulstat.from;
          cumulstat.to = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
          this.datefin = "au " + cumulstat.to;

          this.periodedebut = this.getTimeByFormat(cumulstat.from);
          this.periodefin = this.getTimeByFormat(cumulstat.to);
          if (this.periodedebut.length > 17) {
            this.periodedebut = this.periodedebut.substring(0, 17);
          }
          if (this.periodefin.length > 17) {
            this.periodefin = this.periodefin.substring(0, 17);
          }

          cumulstat.compte = u.matricule;
          cumulstat.oper = "";
          cumulstat.limit = "1000";
          cumulstat.page = "1";

          this.getItemSub = this.dashboardService.getOperationForAllVoucher(cumulstat)
            .subscribe(data => {
              switch (data.status) {
                case 200: {
                  console.log("retour");
                  console.log(data);
                  let resp: Historiquecaisse[] = [];
                  resp = data.body;

                  let vente: number = 0;
                  let paiement: number = 0;
                  let solde: number = 0;

                  let ventenbre: number = 0;
                  let paiementnbre: number = 0;

                  resp.forEach(function (element) {
                    if (element.operationStatus === 1) {
                      vente = vente + parseInt(element.operationMontant, 10);
                      ventenbre++;
                    }

                    if (element.operationStatus === 2) {
                      paiement = paiement + parseInt(element.operationMontant, 10);
                      paiementnbre++;
                    }

                  });

                  this.ventemontant = vente + "";
                  this.paiementmontant = paiement + "";
                  this.soldemontant = (vente - paiement) + "";

                  this.nbrevente = ventenbre + "";
                  this.nbrepaiement = paiementnbre + "";


                  console.log(" soldemontant ");

                  console.log(this.soldemontant);


                  console.log("operations");
                  console.log(resp);
                  this.items = this.temp = resp;
                  this.dataSource = new MatTableDataSource(this.items);
                  this.loader.close();
                  break;
                }
                case 201: {
                  console.log("retour");
                  console.log(data);
                  let resp: Historiquecaisse[] = [];
                  resp = data.body;
                  console.log("operations");
                  console.log(resp);
                  this.items = this.temp = resp;
                  this.dataSource = new MatTableDataSource(this.items);
                  this.loader.close();
                  break;
                }
              }
            },
              error => {
                switch (error.status) {

                  default: {
                    this.loader.close();
                    this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
                    break;
                  }
                }
              });


        } else {
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }
    }

  }










  getItems() {
    console.log(" chargement du journal");
    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {

      this.datedebut = "";
      this.datefin = "";
      let date = new Date();
      let dates: string = this.datePipe.transform(date, "yyyy-MM-dd");
      this.datedebut = dates.trim() + " 00:00:00";
      this.datefin = dates.trim() + " 23:59:59";

      this.periodedebut = this.datedebut.substring(0, 16);
      this.periodefin = this.datefin.substring(0, 16);

      let req: Pmurequest = new Pmurequest();
      req.compte = "";
      req.from = this.datedebut;
      req.to = this.datefin;
      req.compte = u.matricule;
      req.oper = "";
      req.limit = "1000";
      req.page = "1";
      console.log(req);

      this.getItemSub = this.dashboardService.getOperationForAllVoucher(req)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log("retour");
              console.log(data);
              let resp: Historiquecaisse[] = [];

              let vente: number = 0;
              let paiement: number = 0;

              let ventenbre: number = 0;
              let paiementnbre: number = 0;
              let solde: number = 0;




              resp = data.body;


              resp.forEach(function (element) {


                console.log(element);

                console.log("verif ");
                console.log((element.operationStatus === 1));

                if (element.operationStatus === 1) {
                  vente = vente + parseInt(element.operationMontant, 10);
                  ventenbre++;
                }


                if (element.operationStatus === 2) {
                  paiement = paiement + parseInt(element.operationMontant, 10);
                  paiementnbre++;
                }


              });


              this.ventemontant = vente + "";
              this.paiementmontant = paiement + "";
              this.soldemontant = (vente - paiement) + "";

              this.nbrevente = ventenbre + "";
              this.nbrepaiement = paiementnbre + "";


              console.log(" soldemontant ");

              console.log(vente);


              console.log("operations");
              console.log(resp);
              this.items = this.temp = resp;
              this.dataSource = new MatTableDataSource(this.items);
              this.loader.close();
              break;
            }
            case 201: {
              console.log("retour");
              console.log(data);
              let resp: Historiquecaisse[] = [];
              resp = data.body;
              console.log("operations");
              console.log(resp);
              this.items = this.temp = resp;
              this.dataSource = new MatTableDataSource(this.items);
              this.loader.close();
              break;
            }
          }
        },
          error => {
            switch (error.status) {

              default: {
                this.loader.close();
                this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
                break;
              }
            }
          });



    }

  }


  convertDateInString(data: string) {
    if (data != null) {
      return data.replace("T", " ").substring(0, 16);
    } else {
      return "";
    }
  }






  indexPage: number = 1;


  createRange(x: number) {
    var items: number[] = [];
    for (var i = this.indexPage; i <= this.indexPage + x; i++) {
      items.push(i);
    }
    return items;
  }

  nextVerif() {
    this.indexPage++;
    //this.createRange(5);
  }



  lastVerif() {
    console.log(" lastVerif :: " + this.indexPage);
    if (this.indexPage <= 1) {

    } else {
      this.indexPage--;
    }
    //this.createRange(5);
  }


  sendRequestPaginator(item) {

    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      let to: string = "";
      let from: string = "";
      this.datedebut = "";
      this.datefin = "";

      console.log("itemFormCumul " + this.itemFormCumul.get("fromc").value.toString());

      let date = new Date(this.itemFormCumul.get("fromc").value);
      let date1 = new Date(this.itemFormCumul.get("toc").value);
      console.log(" var date : " + date);
      console.log("message");
      console.log("" + date.toString() === 'Invalid Date');
      console.log(" fromc " + this.itemFormCumul.get("fromc"));
      console.log(" toc " + this.itemFormCumul.get("toc"));
      console.log(this.itemFormCumul);
      if ((date.toString() != 'Invalid Date')) {
        from = this.itemFormCumul.get("fromc").value;
      }

      if (date1.toString() != null) {
        to = this.itemFormCumul.get("toc").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);

      console.log(" date " + newDate);
      console.log(" date1 " + newDate1);

      if ((newDate.toDateString() === "Invalid Date") || (newDate1.toDateString() === "Invalid Date")) {
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      } else {
        if (newDate1 >= newDate) {
          this.loader.open();
          console.log("true");
          let cumulstat: Pmurequest = new Pmurequest();
          cumulstat.from = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
          this.datedebut = cumulstat.from;
          cumulstat.to = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
          this.datefin = "au " + cumulstat.to;

          this.periodedebut = this.getTimeByFormat(cumulstat.from);
          this.periodefin = this.getTimeByFormat(cumulstat.to);
          if (this.periodedebut.length > 17) {
            this.periodedebut = this.periodedebut.substring(0, 17);
          }
          if (this.periodefin.length > 17) {
            this.periodefin = this.periodefin.substring(0, 17);
          }

          cumulstat.compte = u.matricule;
          cumulstat.oper = "";
          cumulstat.limit = "1000";
          cumulstat.page = item;

          this.getItemSub = this.dashboardService.getOperationForAllVoucher(cumulstat)
            .subscribe(data => {
              switch (data.status) {
                case 200: {
                  console.log("retour");
                  console.log(data);
                  let resp: Historiquecaisse[] = [];
                  resp = data.body;

                  let vente: number = 0;
                  let paiement: number = 0;
                  let solde: number = 0;

                  resp.forEach(function (element) {
                    if (element.operationStatus === 1) {
                      vente = vente + parseInt(element.operationMontant, 10);
                    }

                    if (element.operationStatus === 2) {
                      paiement = paiement + parseInt(element.operationMontant, 10);
                    }

                  });

                  this.ventemontant = vente + "";
                  this.paiementmontant = paiement + "";
                  this.soldemontant = (vente - paiement) + "";


                  console.log(" soldemontant ");

                  console.log(this.soldemontant);


                  console.log("operations");
                  console.log(resp);
                  this.items = this.temp = resp;
                  this.dataSource = new MatTableDataSource(this.items);
                  this.loader.close();
                  break;
                }
                case 201: {
                  console.log("retour");
                  console.log(data);
                  let resp: Historiquecaisse[] = [];
                  resp = data.body;
                  console.log("operations");
                  console.log(resp);
                  this.items = this.temp = resp;
                  this.dataSource = new MatTableDataSource(this.items);
                  this.loader.close();
                  break;
                }
              }
            },
              error => {
                switch (error.status) {

                  default: {
                    this.loader.close();
                    this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
                    break;
                  }
                }
              });


          this.researchStatAchatForAll();
          this.researchStatReglementForAll();


        } else {
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }
    }
  }







  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




















  researchStatAchatForAll() {

    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));

    if (u != null) {

      let req: Pmurequest = new Pmurequest();
      req.compte = u.matricule;
      req.from = "";
      req.to = "";
      req.page = "";
      req.oper = "";
      req.limit = "";

      console.log(" request to send for stat");
      console.log(req);
      this.dashboardService.getcumulmtallstatachatbycaissier(req)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              let vals: Valeur = new Valeur();

              vals = data.body;
              console.log("Valeur");
              console.log("Montant : " + vals.montant + " ; Nombre : " + vals.nombre);
              this.valueVente = vals;
              break;
            }
            case 201: {
              console.log(data);
              let vals: Valeur = new Valeur();
              vals = data.body;

              console.log("Valeur");
              console.log("Montant : " + vals.montant + " ; Nombre : " + vals.nombre);
              this.valueVente = vals;
              break;
            }

          }
        },
          error => {
            console.log('data');
            console.log(error);
            switch (error.status) {

              default: {
                this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                /*
                this.loader.close();
                this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                */
                break;
              }
            }

          }
        );


    }


  }


  researchStatReglementForAll() {
    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));

    if (u != null) {

      let req: Pmurequest = new Pmurequest();
      req.compte = u.matricule;
      req.from = "";
      req.to = "";
      req.page = "";
      req.oper = "";
      req.limit = "";

      console.log(" request ");
      console.log(req);
      this.dashboardService.getcumulmtallstatreglementbycaissier(req)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              let vals: Valeur = new Valeur();

              vals = data.body;
              console.log("Valeur");
              console.log("Montant : " + vals.montant + " ; Nombre : " + vals.nombre);
              this.valuePaiement = vals;
              //this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              let vals: Valeur = new Valeur();
              vals = data.body;

              console.log("Valeur");
              console.log("Montant : " + vals.montant + " ; Nombre : " + vals.nombre);
              this.valuePaiement = vals;
              //this.loader.close();
              break;
            }

          }
        },
          error => {
            console.log('data');
            console.log(error);
            switch (error.status) {

              default: {
                //this.loader.close();
                this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                break;
              }
            }

          }
        );


    }
  }












}
