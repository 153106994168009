import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CpteuserComponent } from './cpteuser.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {
  MatButtonModule,
  MatCardModule,
  MatChipsModule, MatDialogModule,
  MatIconModule,
  MatInputModule, MatListModule,
  MatMenuModule,
  MatSelectModule, MatSlideToggleModule, MatSnackBarModule, MatTooltipModule
} from '@angular/material';
import {SharedModule} from '../../shared/shared.module';
import { CreateuserComponent } from './createuser/createuser.component';
import { UpdateuserComponent } from './updateuser/updateuser.component';
import {DashboardService} from '../../shared/services/dashboard.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    SharedModule,
  ],
  declarations: [CpteuserComponent, CreateuserComponent, UpdateuserComponent],
  providers: [DashboardService],
  entryComponents: [CreateuserComponent,UpdateuserComponent]
})
export class CpteuserModule { }
