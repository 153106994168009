export class Agency {
    public code: string;
    public nom_agence: string;
    public email: string;
    public telephone: string;
    public direction_id: string;
    constructor(code: string, nom_agence: string, email: string, telephone: string, direction_id: string) {
        this.code = code;
        this.nom_agence = nom_agence;
        this.email = email;
        this.telephone = telephone;
        this.telephone = telephone;
        this.direction_id = direction_id;
    }
}