import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import {DashboardService} from '../../services/dashboard.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-headeragence-side',
  templateUrl: './headeragence-side.component.html',
  styleUrls: ['./headeragence-side.component.scss']
})
export class HeaderagenceSideComponent implements OnInit {

  @Input() notificPanel;
  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]



listercompteutilisateur : boolean = false ;
creercompteutilisateur : boolean = false ;
modifiercompteutilisateur : boolean = false ;
supprimercompteutilisateur : boolean = false ;
listerprofil : boolean = false ;
creerprofil : boolean = false ;
modifierprofil : boolean = false ;
lierutilisateuraprofil : boolean = false ;
reportingoperations : boolean = false ;
detailscompensations : boolean = false ;
supprimerprofil : boolean = false ;

virementrecuoperateur : boolean = false ;
montantvireaupartenaire : boolean = false ;

pourcentage : boolean = false ;








  public egretThemes;
  public layoutConf:any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private dashboardservice : DashboardService,
    public router:Router
  ) {}
  ngOnInit() {



    this.listercompteutilisateur = this.verifyMyMenu("listercompteutilisateur") ;
    this.creercompteutilisateur = this.verifyMyMenu("creercompteutilisateur") ;
    this.modifiercompteutilisateur = this.verifyMyMenu("modifiercompteutilisateur") ;
    this.supprimercompteutilisateur = this.verifyMyMenu("supprimercompteutilisateur") ;
    this.listerprofil = this.verifyMyMenu("listerprofil") ;
    this.creerprofil = this.verifyMyMenu("creerprofil") ;
    this.modifierprofil = this.verifyMyMenu("modifierprofil") ;
    this.lierutilisateuraprofil = this.verifyMyMenu("lierutilisateuraprofil") ;
    this.reportingoperations = this.verifyMyMenu("reportingoperations") ;
    this.detailscompensations = this.verifyMyMenu("detailscompensations") ;
    this.supprimerprofil = this.verifyMyMenu("supprimerprofil") ;

    this.virementrecuoperateur = this.verifyMyMenu("virementrecuoperateur") ;
    this.montantvireaupartenaire = this.verifyMyMenu("montantvireaupartenaire") ;

    this.pourcentage = this.verifyMyMenu("montantvireaupartenaire") ;

    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }
  setLang(e) {
    console.log(e)
    this.translate.use(this.currentLang);
  }
  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }


  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true});
    }
    console.log('compact');

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true})
  }


  verifyMyMenu(value : string){
    console.log(localStorage.getItem(value.trim()));
    if(localStorage.getItem(value.trim())!=null){
      return true ;
    }else{
      return false ;
    }
  }


}
