import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencecaisseComponent } from './agencecaisse.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatSelectModule, MatInputModule, MatIconModule, MatCardModule, MatMenuModule, MatButtonModule, MatChipsModule, MatListModule, MatTooltipModule, MatDialogModule, MatSnackBarModule, MatSlideToggleModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatTableModule, MatTabsModule } from '@angular/material';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { SharedModule } from 'app/shared/shared.module';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatTabsModule,
    AngularDateTimePickerModule,
    SharedModule,
    ChartsModule,
  ],
  declarations: [AgencecaisseComponent]
})
export class AgencecaisseModule { }
