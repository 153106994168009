import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { SettingsService } from 'app/shared/services/settings.service';

@Component({
  selector: 'app-details-utilisateur',
  templateUrl: './details-utilisateur.component.html',
  styleUrls: ['./details-utilisateur.component.scss']
})
export class DetailsUtilisateurComponent implements OnInit {
  @Input() user;
  response: any;
  listAgence = [];
  constructor(private activeModal: NgbActiveModal, private _settings: SettingsService,
    private eventEmitter: EventEmitterService) { }

  ngOnInit() {
    this.getAllAgence();
  }

  onSelectAgence(value){
    this.user.agence_id = value;
  }

  onSelectService(value){
    this.user.service = value;
  }

  closeModal() {
    this.activeModal.close();
  }

  // Get all agence
  getAllAgence() {
    this._settings.getAllAgence().subscribe((data: {}) => {
      this.response = data;
      this.listAgence = this.response.data;
    });
  }

}
