import { Component, OnInit } from '@angular/core';
import { Caissiere } from 'app/shared/models/caissiere';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DashboardService } from 'app/shared/services/dashboard.service';

@Component({
  selector: 'app-paymentnotif',
  templateUrl: './paymentnotif.component.html',
  styleUrls: ['./paymentnotif.component.scss']
})
export class PaymentnotifComponent implements OnInit {

  private sub: any;

  order: string;

  order_id: string;
  status_id: string;
  transaction_id: string;
  transaction_amount: string;
  currency: string;
  paid_transaction_amount: string;
  paid_currency: string;
  change_rate: string;
  conflictual_transaction_amount: string;
  conflictual_currency: string;
  wallet: string;

  response: string;

  notsucces: boolean = false;

  verifypaiement: boolean = false;
  modepaiement: boolean = false;
  confirmationpaiement: boolean = false;

  ventenow: Caissiere = null;

  constructor(private activatedRoute: ActivatedRoute, private route: ActivatedRoute, private loader: AppLoaderService,
    public dialog: MatDialog, public router: Router, public dashboardService: DashboardService) { }

  ngOnInit() {

    this.loader.open();

    this.response = 'Votre commande est en cours de traitement ';
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.order_id = params['order_id'];
        this.status_id = params['status_id'];
        this.transaction_id = params['transaction_id'];
        this.transaction_amount = params['transaction_amount'];
        this.currency = params['currency'];
        this.paid_transaction_amount = params['paid_transaction_amount'];
        this.paid_currency = params['paid_currency'];
        this.change_rate = params['change_rate'];
        this.conflictual_transaction_amount = params['conflictual_transaction_amount'];
        this.conflictual_currency = params['conflictual_currency'];
        this.wallet = params['wallet'];
      });

    if (decodeURIComponent(this.status_id).trim() === "1") {
      this.notsucces = true;
      let gwr: commandGwResp = new commandGwResp();
      gwr.change_rate = this.change_rate;
      gwr.conflictual_currency = this.conflictual_currency;
      gwr.conflictual_transaction_amount = this.conflictual_transaction_amount;
      gwr.currency = this.currency;
      gwr.order_id = this.order_id;
      gwr.paid_currency = this.paid_currency;
      gwr.paid_transaction_amount = this.paid_transaction_amount;
      gwr.status_id = decodeURIComponent(this.status_id).trim();
      gwr.transaction_amount = this.transaction_amount;
      gwr.transaction_id = this.transaction_id;
      gwr.wallet = this.wallet;
      this.dashboardService.getVenteByTransactionId(this.order_id)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              this.verifypaiement = true;
              this.modepaiement = true;
              this.ventenow = data.body;
              this.confirmationpaiement=true ;
              this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              this.loader.close();
              break;
            }

          }
        },
          error => {
            console.log('data');
            console.log(error);
            switch (error.status) {
              case 423: {
                this.loader.close();
                break;
              }
              default: {
                this.loader.close();
                break;
              }
            }
          }
        )


    } else {
      this.response = "Echec !!! Erreur survenue lors de la transaction  ";
      this.notsucces = false;
    }


  }


  download(req: Caissiere) {
    /*
    let a = document.createElement("a");
    document.body.appendChild(a);
    let url: string;
    url = this.dashboardService.uploadrecutimbre;
    a.href = this.dashboardService.uploadrecutimbre + req.venteid;
    a.target = "_blank";
    a.download = "test.pdf";
    a.name = "test"
    a.click();
    document.body.removeChild(a);
    */
   var form = document.createElement("form");
   form.method = "GET";
   form.action = this.dashboardService.uploadrecutimbre + req.venteid;
   form.target = "_blank";
   document.body.appendChild(form);
   form.submit();
  }


  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }


}


export class commandGwResp {
  order_id: string;
  status_id: string;
  transaction_id: string;
  transaction_amount: string;
  currency: string;
  paid_transaction_amount: string;
  paid_currency: string;
  change_rate: string;
  conflictual_transaction_amount: string;
  conflictual_currency: string;
  wallet: string;

}