import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../shared/services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: any;
  old_password: any;
  new_password: any;
  c_new_password: any;
  imagePath: any;
  profilPhoto: File;
  imgURL: any;
  API_URL: any;
  FILE_URL: any;
  response: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };
  constructor(private variable: GlobalVariableService, private router: Router, private http: HttpClient, private configService: ConfigService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, private authService: AuthenticationService, private toastrService: ToastrService) {
    this.API_URL = configService.get('HOST_API_CNAM');
    this.FILE_URL = configService.get('HOST_FILE_URL');
    }

  ngOnInit() {
    this.user = JSON.parse(this.variable.getUser());
    this.user.photo ? this.imgURL = this.FILE_URL + this.user.photo : this.imgURL = null;
  }



  closeModal() {
    this.activeModal.close();
  }

  callModal(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  updateInfoUser(item) {
    let profile = new FormData();
    profile.append('id', item.id);
    profile.append('nom_prenoms', item.nom_prenoms);
    profile.append('telephone', item.telephone);
    profile.append('service', item.service);
    profile.append('photo', this.profilPhoto);
    this.http.post(this.API_URL + 'user/update_profil', profile, this.httpOptions)
    // this.authService.update_info(data)
    .subscribe(
      res => {
        this.response = res;
        if (this.response.error === false) {
          swal({
            title: 'CNAM SYSTEME',
            type: 'success',
            text: 'Modification effectuée avec succès',
            showCancelButton: false,
            confirmButtonText: 'ok'
          }).then(() => {
            this.activeModal.close();
            this.getDetailsAccountUser();
            // this.router.navigate(['/portail']);
          })
        } else {
          swal({
            title: 'CNAM SYSTEME',
            type: 'error',
            text: this.response.message,
            showCancelButton: false,
            confirmButtonText: 'ok'
          })
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getDetailsAccountUser() {
    this.http.get(this.API_URL + 'user/details', this.httpOptions)
      .subscribe(
        res => {
          this.response = res;
          if (this.response.error == false) {
            this.toastrService.success(this.response.message);
            this.variable.setUser(this.response.data);
          } else {
            this.toastrService.error(this.response.message);
          }
        },
        err => {
          console.log(err);
        }
    )
  }

  previewProfil(files) {
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    this.profilPhoto = files[0];
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  changePassword() {
    const data = {
      'old_password': this.old_password,
      'new_password': this.new_password,
      'c_new_password': this.c_new_password
    }
    this.authService.update_password(data, localStorage.getItem('access_token'))
    .subscribe(
      res => {
        if (res.error === false) {
          swal({
            title: 'CNAM SYSTEME',
            type: 'success',
            text: 'Modification effectuée. Vous allez être déconnecter',
            showCancelButton: false,
            confirmButtonText: 'ok'
          }).then(() => {
            this.activeModal.close();
            this.router.navigate(['/portail']);
          })
        } else {
          swal({
            title: 'CNAM SYSTEME',
            type: 'error',
            text: res.message,
            showCancelButton: false,
            confirmButtonText: 'ok'
          })
        }
      },
      err => {
        swal({
          title: 'CNAM SYSTEME',
          type: 'error',
          text: JSON.stringify(err),
          showCancelButton: false,
          confirmButtonText: 'ok'
        })
      }
    );
}

}
