import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Valeur } from 'app/shared/models/valeur';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Operation } from 'app/shared/models/operation';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Caissier } from 'app/shared/models/caissier';
import { Pmurequest } from 'app/shared/models/pmurequest';
import { Historiquecaisse } from 'app/shared/models/historiquecaisse';
import { Generaterecu } from 'app/shared/models/generaterecu';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-historiquereglementvoucher',
  templateUrl: './historiquereglementvoucher.component.html',
  styleUrls: ['./historiquereglementvoucher.component.scss']
})
export class HistoriquereglementvoucherComponent implements OnInit {

  dataSourceForExport: MatTableDataSource<Historiquecaisse>= new MatTableDataSource([]);


  cumulGlobal : Valeur = new Valeur();

  cumulTransac : Valeur = new Valeur();

  datedebut : string ="";

  datefin : string ="";


  periodedebut : string ="";

  periodefin : string ="";

  public items: any[];
  temp = [];
  rows = [];
  columns = [];
  useUtcForDisplay = true;
  renderedData: any;
  valeurResponse : Valeur = new Valeur();
  valeurResponseNow : Valeur = new Valeur();
  okdisabled : boolean = false ;


  public itemForm: FormGroup;

  public itemFormCumul: FormGroup;

  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy hh:mm a',
    defaultOpen: false,
    closeOnSelect: false
  }

  private _dateValue: Date;

  public getItemSub: Subscription;
  dataSource: MatTableDataSource<Operation>= new MatTableDataSource([]);





  constructor( private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService:DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router:Router,
    private fb: FormBuilder,
    private fbc: FormBuilder,
    private datePipe: DatePipe,) { }

  ngOnInit() {
    this.getItems();
    this.itemFormCumul = this.fbc.group({
      toc :[ ''],
      fromc :[ ''],
    });
    this.researchStatReglementForAll();
    this.researchStatReglementNow();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  getItems() {
    let u = new Caissier() ;
    u=JSON.parse(localStorage.getItem("curentuser"));
    if (u!=null) {

      this.datedebut="";
      this.datefin="";
      let date = new Date();
      let dates : string = this.datePipe.transform(date,"yyyy-MM-dd") ;
      this.datedebut = dates.trim()+" 00:00:00" ;
      this.datefin = dates.trim()+" 23:59:59" ;

      let req : Pmurequest = new Pmurequest() ;
      req.compte="";
      req.from=this.datedebut;
      req.to=this.datefin;
      req.compte=u.matricule;
      req.oper="";
      req.limit="10";
      req.page="1";
      console.log(req);

      this.getItemSub = this.dashboardService.getOperationForAllReglementCaisse(req)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            console.log("retour");
            console.log(data);
            let resp : Historiquecaisse[] = [];
            resp=data.body;
            console.log("operations");
            console.log(resp);
            this.items=this.temp=resp ;
            this.dataSource = new MatTableDataSource(this.items);
            this.loader.close();
            break;
          }
          case 201: {
            console.log("retour");
            console.log(data);
            let resp : Historiquecaisse[] = [];
            resp=data.body;
            console.log("operations");
            console.log(resp);
            this.items=this.temp=resp ;
            this.dataSource = new MatTableDataSource(this.items);
            this.loader.close();
            break;
          }
        }
      },
      error => {
        switch(error.status){

          default: {
            this.loader.close();
            this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
            break;
          }
        }
      });
    }

  }


  exportCsv() {
    if (this.items.length > 0) {
      let itemscsv: any[] = [];
      console.log(" nombre de items : " + this.items.length);
      this.items.forEach(function (element) {
        console.log(element);
        if (element.operationDate.length > 17) {
          element.operationDate = element.operationDate.substr(0, 16);
        }
        if (element.voucherexpiration.length > 17) {
          element.voucherexpiration = element.voucherexpiration.substr(0, 16);
          element.voucherexpiration = element.voucherexpiration.replace("T", " ");
        }
        var my_json = {
          "Date Transaction": element.operationDate,
          "Date Expiration ": element.voucherexpiration,
          "Code Voucher": element.numeroTicket,
          "Numero Transaction": element.transactionId,
          "Operation Montant": element.operationMontant
        };
        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Voucher Paiement Caisse');

    }
  }

  /*

  getItems() {
    this.datedebut="";
    this.datefin="";
    let date = new Date();
    let dates : string = this.datePipe.transform(date,"yyyy-MM-dd") ;
    this.datedebut = dates.trim()+" 00:00:00" ;
    this.datefin = dates.trim()+" 23:59:59" ;

    let req : Pmurequest = new Pmurequest() ;
    req.compte="";
    req.from=this.datedebut;
    req.to=this.datefin;

    req.oper="YgHYbCCM";
    req.limit="10";
    req.page="1";
    console.log(req);
    this.getItemSub = this.dashboardService.getallenvoye(req)
      .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              let resp : Pmuobject = new Pmuobject();
              resp=data.body;
              console.log("operations");
              console.log(resp);
              if(resp!=null){
                this.items=this.temp=resp.Operations ;
                this.dataSource = new MatTableDataSource(this.items);
              }else{
                this.items=this.temp=null ;
                this.dataSource = new MatTableDataSource(this.items);            }
              this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              let resp : Pmuobject = new Pmuobject();
              resp=data.body;
              console.log("operations");
              console.log(resp);
              if(resp!=null){
                this.items=this.temp=resp.Operations ;
                this.dataSource = new MatTableDataSource(this.items);
              }else{
                this.items=this.temp=null ;
                this.dataSource = new MatTableDataSource(this.items);            }
              this.loader.close();
              break;
            }
          }
        },
        error => {
          switch(error.status){

            default: {
              this.loader.close();
              this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
              break;
            }
          }
        });
  }

  */


 convertDateInString(data : string){
  if(data!=null){
    return data.replace("T"," ").substring(0, 16);
  }else{
    return "";
  }  }



  indexPage : number = 1 ;


  createRange(x:number){
    var items: number[] = [];
    for(var i = this.indexPage; i <= this.indexPage+x; i++){
       items.push(i);
    }
    return items;
  }

  nextVerif(){
    this.indexPage++;
    //this.createRange(5);
  }



  lastVerif(){
    console.log(" lastVerif :: "+this.indexPage);
    if(this.indexPage<=1){

    }else{
      this.indexPage--;
    }
    //this.createRange(5);
  }


  sendRequestPaginator(item){
    let u = new Caissier() ;
    u=JSON.parse(localStorage.getItem("curentuser"));
    if (u!=null) {

      let req : Pmurequest = new Pmurequest() ;
    req.compte="";
    //req.from=this.datePipe.transform(this.datedebut,"yyyy-MM-dd HH:mm:ss");
    //req.to=this.datePipe.transform(this.datefin,"yyyy-MM-dd HH:mm:ss");

    req.from="";
    req.to="";

    let from : string ="";
    let to : string ="";

    let date = new Date(this.itemFormCumul.get("fromc").value);
    let date1 = new Date(this.itemFormCumul.get("toc").value);
    if((date.toString()!='Invalid Date')){
      from  = this.itemFormCumul.get("fromc").value;
    }

    if(date1.toString()!=null){
      to  = this.itemFormCumul.get("toc").value;
    }


	 let newDate = new Date(from);

   let newDate1 = new Date(to);

 if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
  this.datedebut="";
  this.datefin="";
  let date = new Date();
  let dates : string = this.datePipe.transform(date,"yyyy-MM-dd") ;
  this.datedebut = dates.trim() ;
  this.datefin = " au "+dates.trim() ;
  to=dates.trim()+" 23:59:59";
      from=dates.trim()+" 00:01:01" ;
   }else{
     if(newDate1<newDate){
      this.datedebut="";
      this.datefin="";
      let date = new Date();
      let dates : string = this.datePipe.transform(date,"yyyy-MM-dd") ;
      this.datedebut = dates.trim() ;
      this.datefin = " au "+dates.trim() ;
      to=dates.trim()+" 23:59:59";
      from=dates.trim()+" 00:01:01" ;
   }
 }

      req.compte="";
      req.from=from;
      req.to=to;
      req.compte=u.matricule;
      req.oper="";
      req.limit="10";
      req.page=item;
      console.log(req);
      this.loader.open();
      this.getItemSub = this.dashboardService.getOperationForAllReglementCaisse(req)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            console.log("retour");
            console.log(data);
            let resp : Historiquecaisse[] = [];
            resp=data.body;
            console.log("operations");
            console.log(resp);
            this.items=this.temp=resp ;
            this.dataSource = new MatTableDataSource(this.items);
            this.loader.close();
            break;
          }
          case 201: {
            console.log("retour");
            console.log(data);
            let resp : Historiquecaisse[] = [];
            resp=data.body;
            console.log("operations");
            console.log(resp);
            this.items=this.temp=resp ;
            this.dataSource = new MatTableDataSource(this.items);
            this.loader.close();
            break;
          }
        }
      },
      error => {
        switch(error.status){

          default: {
            this.loader.close();
            this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
            break;
          }
        }
      });
    }
  }

  allresearch(){
    let u = new Caissier() ;
    u=JSON.parse(localStorage.getItem("curentuser"));
    if (u!=null) {
    let to : string ="";
    let from : string ="";
    this.datedebut="";
    this.datefin="";

    console.log("itemFormCumul "+this.itemFormCumul.get("fromc").value.toString());

    let date = new Date(this.itemFormCumul.get("fromc").value);
    let date1 = new Date(this.itemFormCumul.get("toc").value);
    console.log(" var date : "+date);
    console.log("message");
    console.log(""+date.toString()==='Invalid Date');
    console.log(" fromc "+this.itemFormCumul.get("fromc"));
    console.log(" toc "+this.itemFormCumul.get("toc"));
    console.log(this.itemFormCumul);
    if((date.toString()!='Invalid Date')){
      from  = this.itemFormCumul.get("fromc").value;
    }

    if(date1.toString()!=null){
      to  = this.itemFormCumul.get("toc").value;
    }

    let newDate = new Date(from);

    let newDate1 = new Date(to);

    console.log(" date "+newDate);
    console.log(" date1 "+newDate1);

    if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
      this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
    }else{
      if(newDate1>=newDate){
        this.loader.open();
        console.log("true");
        let cumulstat : Pmurequest = new Pmurequest();
        cumulstat.from=this.datePipe.transform(newDate,"yyyy-MM-dd HH:mm:ss") ;
        this.datedebut=cumulstat.from;
        cumulstat.to=this.datePipe.transform(newDate1,"yyyy-MM-dd HH:mm:ss") ;
        this.datefin= "au "+cumulstat.to;

        this.periodedebut=this.getTimeByFormat(cumulstat.from);
        this.periodefin=this.getTimeByFormat(cumulstat.to);
        if(this.periodedebut.length>17){
          this.periodedebut=this.periodedebut.substring(0,17);
        }
        if(this.periodefin.length>17){
          this.periodefin=this.periodefin.substring(0,17);
        }

        cumulstat.compte=u.matricule;
        cumulstat.oper="";
        cumulstat.limit="10";
        cumulstat.page="1";


      this.getItemSub = this.dashboardService.getOperationForAllReglementCaisse(cumulstat)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            console.log("retour");
            console.log(data);
            let resp : Historiquecaisse[] = [];
            resp=data.body;
            console.log("operations");
            console.log(resp);
            this.items=this.temp=resp ;
            this.dataSource = new MatTableDataSource(this.items);
            this.loader.close();
            break;
          }
          case 201: {
            console.log("retour");
            console.log(data);
            let resp : Historiquecaisse[] = [];
            resp=data.body;
            console.log("operations");
            console.log(resp);
            this.items=this.temp=resp ;
            this.dataSource = new MatTableDataSource(this.items);
            this.loader.close();
            break;
          }
        }
      },
      error => {
        switch(error.status){

          default: {
            this.loader.close();
            this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
            break;
          }
        }
      });

      this.researchStatReglementByDate(cumulstat);


      }else{
        console.log("false");
        this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
      }
    }
  }

  }





  researchStatReglementForAll() {

    let date = new Date();
    console.log(" date now ");
    console.log(date);
    console.log(this.datePipe.transform(date,"yyyy-MM-dd"));
    let dates : string = this.datePipe.transform(date,"yyyy-MM-dd") ;
    let date1 : string = dates.trim()+" 00:01:00" ;
    let date2 : string = dates.trim()+" 23:59:59" ;

    this.periodedebut=this.getTimeByFormat(date1);
    this.periodefin=this.getTimeByFormat(date2);
    this.periodedebut=this.periodedebut.replace("T", "");
    this.periodefin=this.periodefin.replace("T", "");
    if(this.periodedebut.length>17){
      this.periodedebut=this.periodedebut.substring(0,17);
    }
    if(this.periodefin.length>17){
      this.periodefin=this.periodefin.substring(0,17);
    }

    let u = new Caissier() ;
    u=JSON.parse(localStorage.getItem("curentuser"));

    if(u!=null) {

      let req : Pmurequest = new Pmurequest() ;
      req.compte=u.matricule;
      req.from="";
      req.to="";
      req.page="";
      req.oper="";
      req.limit="";

      console.log(" request ");
      console.log(req);
      this.dashboardService.getcumulmtallstatreglementbycaissier(req)
        .subscribe(data => {
            switch (data.status) {
              case 200: {
                console.log(data);
                let vals : Valeur = new Valeur();

                vals=data.body;
                console.log("Valeur");
                //  console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
                this.cumulGlobal=vals;
                this.loader.close();
                break;
              }
              case 201: {
                console.log(data);
                let vals : Valeur = new Valeur();
                vals=data.body;

                console.log("Valeur");
                console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
                this.cumulGlobal=vals;
                this.loader.close();
                break;
              }

            }
          },
          error => {
            console.log('data');
            console.log(error);
            switch(error.status){

              default: {
                this.loader.close();
                this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                break;
              }
            }

          }
        );


    }
  }

  researchStatReglementNow() {

    let date = new Date();
    console.log(" date now ");
    console.log(date);
    console.log(this.datePipe.transform(date,"yyyy-MM-dd"));
    let dates : string = this.datePipe.transform(date,"yyyy-MM-dd") ;
    let date1 : string = dates.trim()+" 00:01:00" ;
    let date2 : string = dates.trim()+" 23:59:59" ;


    let u = new Caissier() ;
    u=JSON.parse(localStorage.getItem("curentuser"));

    if(u!=null) {

      let req : Pmurequest = new Pmurequest() ;
      req.compte=u.matricule;
      req.from=date1;
      req.to=date2;
      req.page="";
      req.oper="";
      req.limit="";

      console.log(" request ");
      console.log(req);
      this.dashboardService.getcumulmtstatreglementbycaissier(req)
        .subscribe(data => {
            switch (data.status) {
              case 200: {
                console.log(data);
                let vals : Valeur = new Valeur();

                vals=data.body;
                console.log("Valeur");
                //  console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
                this.cumulTransac=vals;
                this.loader.close();
                break;
              }
              case 201: {
                console.log(data);
                let vals : Valeur = new Valeur();
                vals=data.body;

                console.log("Valeur");
                console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
                this.cumulTransac=vals;
                this.loader.close();
                break;
              }

            }
          },
          error => {
            console.log('data');
            console.log(error);
            switch(error.status){

              default: {
                this.loader.close();
                this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                break;
              }
            }

          }
        );


    }
  }


  getTimeByFormat(value : string){
    let day : string = value.substring(8,10);
    let mount : string = value.substring(5,7);
    let year : string = value.substring(0,4);
    let hours : string = value.substr(10);
    return day+"-"+mount+"-"+year+" "+hours ;
}



researchStatReglementByDate(req : Pmurequest) {

  let u = new Caissier() ;
  u=JSON.parse(localStorage.getItem("curentuser"));

  if(u!=null) {

    console.log(" request ");
    console.log(req);
    this.dashboardService.getcumulmtstatreglementbycaissier(req)
      .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              let vals : Valeur = new Valeur();

              vals=data.body;
              console.log("Valeur");
              //  console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
              this.cumulTransac=vals;
              this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              let vals : Valeur = new Valeur();
              vals=data.body;

              console.log("Valeur");
              console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
              this.cumulTransac=vals;
              this.loader.close();
              break;
            }

          }
        },
        error => {
          console.log('data');
          console.log(error);
          switch(error.status){

            default: {
              this.loader.close();
              this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
              break;
            }
          }

        }
      );
  }
}





getfacture(facture:Historiquecaisse) {

  let u = new Caissier();
  u = JSON.parse(localStorage.getItem("curentuser"));
  if (u != null) {

    if(facture!=null){

      let req: Generaterecu = new Generaterecu();
      req.matriculecaissier = u.matricule;
      req.coderecu=facture.numeroTicket;
      req.agence=u.agence;
      req.typerecu="2";

      this.loader.open();
      this.dashboardService.getfacturerecu(req)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              this.loader.close();
              break;
            }

          }
        },
          error => {
            console.log('data');
            console.log(error);
            switch (error.status) {

              default: {
                this.loader.close();
                break;
              }
            }
          }
        )

    }
  }
}


download(facture: Historiquecaisse) {


  let u = new Caissier();
  u = JSON.parse(localStorage.getItem("curentuser"));
  if (u != null) {

    if (facture != null) {

      let req: Generaterecu = new Generaterecu();
      req.matriculecaissier = u.matricule;
      req.coderecu = facture.numeroTicket;
      req.agence = u.agence;
      req.email = u.email;
      req.typerecu = "2";

      console.log('download');

      let a = document.createElement("a");
      document.body.appendChild(a);
      let url: string;
      url = this.dashboardService.uploadrecu;
      a.href =  this.dashboardService. uploadrecu + "/" + req.matriculecaissier + "/" + req.coderecu + "/" + req.typerecu + "/" + req.email + "/" + req.agence;
      a.target= "_blank";
      a.download = "test.pdf";
      a.name="test"
      a.click();
      document.body.removeChild(a);
    }
  }


}





public exportAsExcelFile(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  this.saveAsExcelFile(excelBuffer, excelFileName);
}
private saveAsExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
  FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}

}
