import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'ngx-envconfig';
import { GlobalVariableService } from './global-variable.service';
@Injectable({
  providedIn: 'root'
})

export class HistoriqueVenteService {
  // Define API
  API_URL: string;
  public responseCache = new Map();
  constructor(private http: HttpClient, public configService: ConfigService, private variable: GlobalVariableService) { 
    this.API_URL = configService.get('HOST_API_CNAM');  
  }
  /**
   * @author André ATCHORI
   */

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.variable.getAccessToken()
    })
  };

   // HttpClient API get() method => Get all historique
  getAllHistoriqueVente(date_debut, date_fin): Observable<any> {
    return this.http.get<any>(this.API_URL + `vente/historique/du/${date_debut}/au/${date_fin}`, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API get() method => Get all historique vente generale
  getAllHistoriqueVenteGenerale(data): Observable<any> {
      return this.http.post<any>(this.API_URL + `vente/historique_general`, data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  // HttpClient API get() method => Get all cumul vente
  getAllCumulVente(): Observable<any> {
      return this.http.get<any>(this.API_URL + `vente/all_cumul`, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }
  
  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     return throwError(errorMessage);
  }

}
