import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-updategran',
  templateUrl: './updategran.component.html',
  styleUrls: ['./updategran.component.scss']
})
export class UpdategranComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdategranComponent>,
    private fb: FormBuilder,
  ) { }


  ngOnInit() {
    this.buildItemForm(this.data.payload)
  }

  buildItemForm(item) {
    console.log(item);
    this.itemForm = this.fb.group({
      granulariteid :[item.granulariteid , Validators.compose([Validators.required])],
      granularitevalue :[item.granularitevalue , Validators.compose([Validators.required])],
    });
  }

  submit() {
    this.dialogRef.close(this.itemForm.value);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
