import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-app-progressbar',
  templateUrl: './app-progressbar.component.html',
  styleUrls: ['./app-progressbar.component.css']
})
export class AppProgressbarComponent implements OnInit {
  title;
  message;
  valueToDisplay;
  additionnel;
  mode
  constructor(public dialogRef: MatDialogRef<AppProgressbarComponent>) {}

  ngOnInit() {
  }

}
