import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgetpasswordCnamComponent } from './forgetpassword-cnam.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatIconModule, MatInputModule, MatProgressBarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    FlexLayoutModule
  ],
  declarations: [ForgetpasswordCnamComponent]
})
export class ForgetpasswordCnamModule { }
