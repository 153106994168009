import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { AppProgressbarComponent } from './app-progressbar.component';

@Injectable()
export class AppProgressbarService {
  dialogRef: MatDialogRef<AppProgressbarComponent>;
  constructor(private dialog: MatDialog) { }

  public open(title: string, value: number, mode: string = 'determinate', additionnel?: string): Observable<boolean> {
    this.dialogRef = this.dialog.open(AppProgressbarComponent, { disableClose: true, backdropClass: 'light-backdrop'} );
    this.dialogRef.updateSize('200px');
    this.dialogRef.componentInstance.title = title;
    this.dialogRef.componentInstance.valueToDisplay = value;
    this.dialogRef.componentInstance.mode = mode;
    this.dialogRef.componentInstance.additionnel = additionnel;
    return this.dialogRef.afterClosed();
  }

  public close() {
    if(this.dialogRef)
      this.dialogRef.close();
  }

  public update(value: number, mode: string = 'determinate', additionnel?: string) {
    if(this.dialogRef)
    this.dialogRef.componentInstance.mode = mode;
    this.dialogRef.componentInstance.valueToDisplay = value;
    this.dialogRef.componentInstance.additionnel = additionnel;
  }

}
