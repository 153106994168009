import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Wallet } from 'app/shared/models/wallet';
import { Servicce } from 'app/shared/models/servicce';
import { Walet } from 'app/shared/models/walet';
import { Servicse } from 'app/shared/models/servicse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CreatevirementrecuopComponent } from '../createvirementrecuop/createvirementrecuop.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-updatevirementrecuop',
  templateUrl: './updatevirementrecuop.component.html',
  styleUrls: ['./updatevirementrecuop.component.scss']
})
export class UpdatevirementrecuopComponent implements OnInit {


  public itemForm: FormGroup;

  public itemsWallet: Wallet[];
  public itemsServicce: Servicce[];

  public walletselect : Walet = new Walet();
  public serviceselect : Servicse = new Servicse();

  mapServ: Map<string, Servicse>;
  mapWall: Map<string, Walet>;

  
  itemServicse : Servicse[] = [] ;
  itemWal : Walet[] = [] ;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreatevirementrecuopComponent>,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.getService();
    this.getWallet();
    this.getItemGetServces();
   }

  ngOnInit() {
    this.getService();
    this.getWallet();
    this.getItemGetServces();
    this.buildItemForm(this.data.payload); 

    this.itemForm.controls['services'].valueChanges.subscribe((value) => {       
      console.log(" services " ) ;
      console.log(this.itemForm.get('services').value);
      let srv : Servicse = this.itemForm.get('services').value ;
      console.log(" active_wallets " ) ;
     let ws : Walet[] = srv.active_wallets ;
     this.itemWal=[]     ;
      this.itemWal=ws ;
      console.log(this.itemWal);
    })
  }

  buildItemForm(item) {
    console.log("buildItemForm");

    console.log(item);

    let servtoken : string = item.ecomtoken ;

    if(this.itemServicse.length>0){
      this.serviceselect=this.mapServ.get(servtoken);
    } 

    let serv : Servicse = this.serviceselect ;

    if(serv!=null){
      this.itemWal=serv.active_wallets ;
    }

    this.mapWall  = new Map();
    for (let w of this.itemWal) this.mapWall.set(w.authentication_token , w);

    if(this.itemWal.length>0){
      this.walletselect = this.mapWall.get(item.wallettoken);
    }


    /*
    let wall : string = item.wallettoken ;
    if(this.itemsWallet.length>0){
      this.walletselect=this.mapWall.get(wall);
    }
    */

    console.log("wallet");

    console.log(this.walletselect);

    
    console.log("service");

    console.log(this.serviceselect);

    console.log("datevalidemontant");

    let finaldate : string ="";
    finaldate = item.datevalidemontant ;
    console.log(finaldate);

    let newDate = new Date(finaldate);
    let value : any =this.datePipe.transform(newDate,"yyyy-MM-dd");

    console.log("date finale");
    console.log(value);


    console.log("value finale");
    console.log(newDate.toDateString());

    this.itemForm = this.fb.group({
      'virementrecuopid': [item.virementrecuopid , Validators.compose([Validators.required])],
      'services': ['', Validators.compose([Validators.required])],
      'wallet': [this.walletselect, Validators.compose([Validators.required])],
      'montant': [item.montant , Validators.compose([Validators.required])],
      'datevalidemontant': [value , Validators.compose([Validators.required])]
    });
  }

  submit() {
    console.log("-------submit---------");
    console.log(this.itemForm.value);
    this.dialogRef.close(this.itemForm.value);
  }

  getService(){
    let services : Servicce[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservice"));
    this.itemsServicce=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservice"));
  }

  getWallet(){
    let wallets : Wallet[] ;
    wallets = [] ;
    wallets=JSON.parse(localStorage.getItem("allwallet"));
    this.itemsWallet=wallets;
    console.log("wallet taille : "+this.itemsWallet.length);
  }



  getItemGetServces() {
    let services : Servicse[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservics"));
    this.itemServicse=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservics"));

    this.mapServ  = new Map();
    for (let s of this.itemServicse) this.mapServ.set(s.token, s);

    this.mapWall  = new Map();
    for (let w of this.itemWal) this.mapWall.set(w.authentication_token , w);
  }


}
