import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Dashboard } from '../models/dashboard';
import { Observable } from 'rxjs';
import { Utilisateur } from '../models/utilisateur';
import { Newprofil } from '../models/newprofil';
import { Entreprise } from '../models/entreprise';
import { Compte } from '../models/compte';
import { Fonctionnalite } from '../models/fonctionnalite';
import { Caissier } from '../models/caissier';
import { Objectosend1 } from '../models/objectosend1';
import { Objectosend2 } from '../models/objectosend2';
import { Genreoperation } from '../models/genreoperation';
import { Pmurequest } from '../models/pmurequest';
import { Granularite } from '../models/granularite';
import { Unknow } from '../models/unknow';
import { Servicce } from '../models/servicce';
import { Wallet } from '../models/wallet';
import { Statrequest } from '../models/statrequest';
import { Profil } from '../models/profil';
import { Virementrecuop } from '../models/virementrecuop';
import { Montantvireaupart } from '../models/montantvireaupart';
import { Servicse } from '../models/servicse';
import { Signupform } from '../models/signupform';
import { Caisserequest } from '../models/caisserequest';
import { Caissereglement } from '../models/caissereglement';
import { Passwdform } from '../models/passwdform';
import { Generaterecu } from '../models/generaterecu';
import { RequestOptionsArgs, ResponseContentType } from '@angular/http';
const httpOptions = {
  headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
    .set("content-Type", "application/json")
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  private addvente = 'http://crossroadtest.net:9393/oneci/caissiere/add/vente';
  private pushachat = 'http://crossroadtest.net:9393/oneci/caissiere/achat';

  private findallvente = 'http://crossroadtest.net:9393/oneci/caissiere/getallvente/';
  private findvente = 'http://crossroadtest.net:9393/oneci/caissiere/getallvente';
  public uploadrecutimbre = 'http://crossroadtest.net:9393/oneci/caissiere/uploadrecu/';
  private updatecaissierpasswd = 'http://crossroadtest.net:9393/oneci/caissiere/userupdate';
  private getverifyemail = 'http://crossroadtest.net:9393/oneci/caissiere/verifymail/';
  private getresetpassword = 'http://crossroadtest.net:9393/oneci/caissiere/resetpassword/';
  private logincaiss = 'http://crossroadtest.net:9393/oneci/caissiere/login';
  private getventepaymobile = 'http://crossroadtest.net:9393/oneci/caissiere/ventebypaymentmobile/';
  private getallstat = 'http://crossroadtest.net:9393/oneci/caissiere/stat';



  mapNewprofil: Map<string, Fonctionnalite>;

  mapGenreOp: Map<string, Genreoperation>;

  private getallunkwown = 'http://crossroadtest.net:8889/monetique/getallunknow';
  private updateunknown = 'http://crossroadtest.net:8889/monetique/updateunknow';

  private getallgran = 'http://crossroadtest.net:8889/monetique/getallgranularite';
  private updategranu = 'http://crossroadtest.net:8889/monetique/updategranularite';

  private getallserv = 'http://crossroadtest.net:8889/monetique/getallservice';

  private getallwall = 'http://crossroadtest.net:8889/monetique/getallwallet';
  private updatewallet = 'http://crossroadtest.net:8889/monetique/updatewallet';

  private getoperationdetail = 'http://crossroadtest.net:8889/monetique/getdetailoperation';
  private getstatoperation = 'http://crossroadtest.net:8889/monetique/sendstatbygranularity';
  private getlogin = 'http://crossroadtest.net:8889/monetique/'
  private getuserbyid = 'http://crossroadtest.net:8889/monetique/getuser/';
  private updateuserpassword = 'http://crossroadtest.net:8889/monetique/updatepasswordprofil/';
  private createuser = 'http://crossroadtest.net:8889/monetique/createprofil';

  private deleteuser = 'http://crossroadtest.net:8889/monetique/deleteuser/';
  private upduser = 'http://crossroadtest.net:8889/monetique/updateprofil';
  private findalluser = 'http://crossroadtest.net:8889/monetique/getalluser';


  private getallprofil = 'http://crossroadtest.net:8889/monetique/getallnewprofil';
  private getallfonct = 'http://crossroadtest.net:8889/monetique/getallfonctionalite';

  private createnewprofil = 'http://crossroadtest.net:8889/monetique/createnewprofil';

  private modifnewprofil = 'http://crossroadtest.net:8889/monetique/updatenewprofil';


  private deleteprofil = 'http://crossroadtest.net:8889/monetique/deletenewprofil/';

  private getlieruserprofil = 'http://crossroadtest.net:8889/monetique/addprofiltouser/';

  private getonenewprofil = 'http://crossroadtest.net:8889/monetique/getonenewprofil/';


  private getallvirementrecuop = 'http://crossroadtest.net:8889/monetique/getallvirementrecuop';

  private getallmontantvireaupart = 'http://crossroadtest.net:8889/monetique/getallmontantvireaupart';

  private addvirementrecuop = 'http://crossroadtest.net:8889/monetique/createvirementrecuop';

  private updatevirementrecuop = 'http://crossroadtest.net:8889/monetique/updatevirementrecuop';


  private deletevirementrecuop = 'http://crossroadtest.net:8889/monetique/deletevirementrecuop/';



  private addmontantvireaupart = 'http://crossroadtest.net:8889/monetique/createmontantvireaupart';

  private updatemontantvireaupart = 'http://crossroadtest.net:8889/monetique/updatemontantvireaupart';

  private deletemontantvireaupart = 'http://crossroadtest.net:8889/monetique/deletemontantvireaupart/';

  private getservices = 'http://crossroadtest.net:8889/monetique/getallservics';

  //private logincaiss = 'http://crossroadtest.net:8888/wallet/loginvouchercaisse';



  private achatvouncher = 'http://crossroadtest.net:8888/wallet/achatvouncher';

  private encaissementvouncher = 'http://crossroadtest.net:8888/wallet/encaissementvouncher';

  //private  encaissementvouncher = 'http://crossroadtest.net:9096/parions_direct_wallet/rest/paymentcaisse/encaissementvouncher';

  private getallachatvouchercaisse = 'http://crossroadtest.net:8888/wallet/getallachatvouchercaisse';

  private getallreglementvouchercaisse = 'http://crossroadtest.net:8888/wallet/reglementcaissetransaction';




  private getstatachatbycaissier = 'http://crossroadtest.net:8888/wallet/getstatachatbycaissier';

  private getstatreglementbycaissier = 'http://crossroadtest.net:8888/wallet/getstatreglementbycaissier';

  private getallstatachatbycaissier = 'http://crossroadtest.net:8888/wallet/getallstatachatbycaissier';

  private getallstatreglementbycaissier = 'http://crossroadtest.net:8888/wallet/getallstatreglementbycaissier';

  public uploadrecu = 'http://crossroadtest.net:8888/wallet/uploadrecu';

  private getallvoucher = 'http://crossroadtest.net:8888/wallet/getalloperationvoucher';


  /*
  private getallventevoucher = 'http://crossroadtest.net:8888/wallet/getallstatachatbycaissier';
  private getallpaiementvoucher = 'http://crossroadtest.net:8888/wallet/getallstatreglementbycaissier';
  */



 public getStat(req: any): Observable<HttpResponse<any>> {
  return this.http.post(this.getallstat, JSON.stringify(req), {
    observe: 'response',
    headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
      .set("content-Type", "application/json")
  });
}


  public getAllVente(id: number): Observable<HttpResponse<any>> {
    console.log(this.findallvente + id);
    return this.http.get(this.findallvente + id, {
      observe: 'response'
    });
  }

  public getVente(): Observable<HttpResponse<any>> {
    return this.http.get(this.findvente, {
      observe: 'response'
    });
  }


  


  getLogin(path: string): Observable<any> {
    return this.http.get<any>(this.getlogin + "" + path, httpOptions);
  }

  getUserById(id: number): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(this.getuserbyid + "" + id, httpOptions);
  }

  

  public updatePassword(id: number, password: string, oldpassword: string): Observable<HttpResponse<any>> {
    console.log(this.updateuserpassword + '' + id + '/' + password + '/' + oldpassword);
    return this.http.get(this.updateuserpassword + '' + id + '/' + password + '/' + oldpassword, {
      observe: 'response'
    });
  }

  public getVenteByTransactionId( transactionid: string): Observable<HttpResponse<any>> {
    console.log(this.getventepaymobile + transactionid);
    return this.http.get(this.getventepaymobile + transactionid, {
      observe: 'response'
    });
  }

  public updatePasswordByForm(value: Passwdform): Observable<HttpResponse<any>> {
    console.log("url : " + this.updatecaissierpasswd);
    return this.http.post(this.updatecaissierpasswd, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public createUtilisateur(value: Utilisateur): Observable<HttpResponse<any>> {
    console.log("url : " + this.createuser);
    return this.http.post(this.createuser, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public createUser(value: Utilisateur): Observable<HttpResponse<any>> {
    console.log("url : " + this.createuser);
    return this.http.post(this.createuser, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public updateUtilisateur(value: Utilisateur): Observable<HttpResponse<any>> {
    console.log("url : " + this.upduser);
    return this.http.post(this.upduser, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public deleteUser(id: number): Observable<HttpResponse<any>> {
    return this.http.get(this.deleteuser + id, {
      observe: 'response'
    });
  }


  public getalusers(): Observable<Profil[]> {
    return this.http.get<Profil[]>(this.getallprofil, httpOptions);
  }


  public deleteProfil(id: number): Observable<HttpResponse<any>> {
    console.log(" delete profil : " + this.deleteprofil + id);
    return this.http.delete(this.deleteprofil + id, {
      observe: 'response'
    });
  }



  public verifyemail(email: string): Observable<HttpResponse<any>> {
    return this.http.get(this.getverifyemail + email, {
      observe: 'response'
    });
  }


  public resetpassword(value: string): Observable<HttpResponse<any>> {
    return this.http.get(this.getresetpassword + value, {
      observe: 'response'
    });
  }

  public VerifyProfil(value: string) {
    /*
    let b : boolean = false ;
    let u = new  Utilisateur();
    u = JSON.parse(localStorage.getItem("curentuser"));
    console.log(u);
    if(u!=null){
      this.mapNewprofil  = new Map();
      for (let f of u.newprofil.fonctionnalites) this.mapNewprofil.set(f.nomFonc, f);
      let fnt : Fonctionnalite = new Fonctionnalite() ;
      fnt = this.foncGetNom(value.trim());
      if(fnt!=null){
        console.log('value : '+value+' ; response : '+true);
        b= true;
      }else{
        b = false ;
      }
    }else{
      b = false ;
    }
    return b ;
    */
  }

  foncGetNom(val: string) {
    return this.mapNewprofil.get(val);
  }


  initMapFonction() {
    /*
    let u = new  Utilisateur();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if(u!=null){
      this.mapNewprofil  = new Map();
      for (let f of u.newprofil.fonctionnalites) this.mapNewprofil.set(f.nomFonc, f);
      localStorage.setItem('curentfonctionalite',JSON.stringify(this.mapNewprofil));
    }
    */
  }


  getOperationByName(libele: string) {
    let ops: Genreoperation[] = [];
    ops = JSON.parse(localStorage.getItem("allgenreoperation"));
    console.log(ops);
    if (ops != null) {
      this.mapGenreOp = new Map();
      for (let o of ops) this.mapGenreOp.set(o.genreOperationNom.trim(), o);
      let op: Genreoperation = new Genreoperation();
      op = this.mapGenreOp.get(libele);
      return op;
    } else {
      return null;
    }
  }


  getallgranularite(): Observable<Granularite[]> {
    return this.http.get<Granularite[]>(this.getallgran, httpOptions);
  }

  public updategran(req: Granularite): Observable<HttpResponse<any>> {
    return this.http.post(this.updategranu, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }



  public sendTimbre(req: any): Observable<HttpResponse<any>> {
    return this.http.post(this.addvente, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }




  public sendAchat(req: any): Observable<HttpResponse<any>> {
    return this.http.post(this.pushachat, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  getallUnknw(): Observable<Unknow[]> {
    return this.http.get<Unknow[]>(this.getallunkwown, httpOptions);
  }

  public updateUnkw(req: Unknow): Observable<HttpResponse<any>> {
    return this.http.post(this.updateunknown, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }



  getallServices(): Observable<Servicce[]> {
    return this.http.get<Servicce[]>(this.getallserv, httpOptions);
  }



  getallWallet(): Observable<Wallet[]> {
    return this.http.get<Wallet[]>(this.getallwall, httpOptions);
  }

  public updateWallet(req: Wallet): Observable<HttpResponse<any>> {
    console.log(" JSON ");
    console.log(JSON.stringify(req));
    return this.http.post(this.updatewallet, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public sendOperationDetail(req: Statrequest): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(req));
    return this.http.post(this.getoperationdetail, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public sendStatistiqueOperation(req: Statrequest): Observable<HttpResponse<any>> {
    console.log(JSON.stringify(req));
    return this.http.post(this.getstatoperation, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public findallusers(): Observable<Utilisateur[]> {
    return this.http.get<Utilisateur[]>(this.findalluser, httpOptions);
  }


  public getallfonctionalite(): Observable<Fonctionnalite[]> {
    return this.http.get<Fonctionnalite[]>(this.getallfonct, httpOptions);
  }

  public createProfil(value: Profil): Observable<HttpResponse<any>> {
    console.log("url : " + this.createnewprofil);
    return this.http.post(this.createnewprofil, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public updateProfil(value: Profil): Observable<HttpResponse<any>> {
    console.log("url : " + this.modifnewprofil)
    return this.http.put(this.modifnewprofil, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public lieruserprofil(userid: number, profilid: number): Observable<HttpResponse<any>> {
    console.log(this.lieruserprofil + '' + userid + '/' + profilid);
    return this.http.get(this.getlieruserprofil + '' + userid + '/' + profilid, {
      observe: 'response'
    });
  }


  public findoneprofil(id: number): Observable<HttpResponse<any>> {
    return this.http.get(this.getonenewprofil + id, {
      observe: 'response'
    });
  }







  public findallvirementrecuop(): Observable<Virementrecuop[]> {
    return this.http.get<Virementrecuop[]>(this.getallvirementrecuop, httpOptions);
  }


  public findallmontantvireaupart(): Observable<Montantvireaupart[]> {
    return this.http.get<Montantvireaupart[]>(this.getallmontantvireaupart, httpOptions);
  }







  public createVirement(value: Virementrecuop): Observable<HttpResponse<any>> {
    console.log("url : " + this.addvirementrecuop);
    return this.http.post(this.addvirementrecuop, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public updateVirement(value: Virementrecuop): Observable<HttpResponse<any>> {
    console.log("url : " + this.updatevirementrecuop);
    return this.http.post(this.updatevirementrecuop, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public deleteVirement(id: number): Observable<HttpResponse<any>> {
    console.log(" delete virementrecuop : " + this.deletevirementrecuop + id);
    return this.http.delete(this.deletevirementrecuop + id, {
      observe: 'response'
    });
  }








  public createMontant(value: Montantvireaupart): Observable<HttpResponse<any>> {
    console.log("url : " + this.addmontantvireaupart);
    return this.http.post(this.addmontantvireaupart, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public updateMontant(value: Montantvireaupart): Observable<HttpResponse<any>> {
    console.log("url : " + this.updatemontantvireaupart);
    return this.http.post(this.updatemontantvireaupart, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public deleteMontant(id: number): Observable<HttpResponse<any>> {
    console.log("  delete montantvireaupart : " + this.deletemontantvireaupart + id);
    return this.http.delete(this.deletemontantvireaupart + id, {
      observe: 'response'
    });
  }

  public getalleservics(): Observable<Servicse[]> {
    return this.http.get<Servicse[]>(this.getservices, httpOptions);
  }

  public loginCaissier(value: Signupform): Observable<HttpResponse<any>> {
    console.log("url : " + this.logincaiss);
    return this.http.post(this.logincaiss, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public loginCnam(value: Signupform): Observable<HttpResponse<any>> {
    console.log("url : " + this.logincaiss);
    return this.http.post(this.logincaiss, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public sendachatvouncher(value: Caisserequest): Observable<HttpResponse<any>> {
    console.log("url : " + this.achatvouncher);
    return this.http.post(this.achatvouncher, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public getencaissementvouncher(value: Caisserequest): Observable<HttpResponse<any>> {
    console.log("url : " + this.encaissementvouncher);
    return this.http.post(this.encaissementvouncher, JSON.stringify(value), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }









  public getOperationForAllAchatCaisse(req: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" JSON ");
    console.log(JSON.stringify(req));
    return this.http.post(this.getallachatvouchercaisse, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }





  public getOperationForAllReglementCaisse(req: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" JSON ");
    console.log(JSON.stringify(req));
    return this.http.post(this.getallreglementvouchercaisse, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public getcumulmtstatachatbycaissier(pmureq: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" objet a envoyer ");
    console.log(JSON.stringify(pmureq));
    return this.http.post(this.getstatachatbycaissier, JSON.stringify(pmureq), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public getcumulmtstatreglementbycaissier(pmureq: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" objet a envoyer ");
    console.log(JSON.stringify(pmureq));
    return this.http.post(this.getstatreglementbycaissier, JSON.stringify(pmureq), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }



  public getcumulmtallstatachatbycaissier(pmureq: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" objet a envoyer ");
    console.log(JSON.stringify(pmureq));
    return this.http.post(this.getallstatachatbycaissier, JSON.stringify(pmureq), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public getcumulmtallstatreglementbycaissier(pmureq: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" objet a envoyer ");
    console.log(JSON.stringify(pmureq));
    return this.http.post(this.getallstatreglementbycaissier, JSON.stringify(pmureq), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

  public downlaodrecu(pmureq: Generaterecu): Observable<HttpResponse<any>> {
    console.log(" objet a envoyer ");
    console.log(JSON.stringify(pmureq));
    return this.http.post(this.uploadrecu, JSON.stringify(pmureq), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }


  public getfacturerecu(value: Generaterecu) {

    return this.http.get<any>(this.uploadrecu + "/" + value.matriculecaissier + "/" + value.coderecu + "/" + value.typerecu + "/" + value.email + "/" + value.agence, httpOptions);
  }

  export(value: Generaterecu): Observable<HttpResponse<Blob>> {
    return this.http.get(this.uploadrecu + "/" + value.matriculecaissier + "/" + value.coderecu + "/" + value.typerecu + "/" + value.email + "/" + value.agence, { responseType: 'blob', observe: 'response' });
  }





  public getOperationForAllVoucher(req: Pmurequest): Observable<HttpResponse<any>> {
    console.log(" JSON ");
    console.log(JSON.stringify(req));
    return this.http.post(this.getallvoucher, JSON.stringify(req), {
      observe: 'response',
      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
        .set("content-Type", "application/json")
    });
  }

}
