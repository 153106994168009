export class Generaterecu {


    public  matriculecaissier : string;
	public  coderecu : string;
	public  typerecu : string;

	public  agence : string;
    public  email : string;


}
