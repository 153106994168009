import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Wallet } from 'app/shared/models/wallet';
import { Servicce } from 'app/shared/models/servicce';
import { Servicse } from 'app/shared/models/servicse';
import { Walet } from 'app/shared/models/walet';

@Component({
  selector: 'app-createvirementrecuop',
  templateUrl: './createvirementrecuop.component.html',
  styleUrls: ['./createvirementrecuop.component.scss']
})
export class CreatevirementrecuopComponent implements OnInit {



 
  public itemForm: FormGroup;

  public itemsWallet: Wallet[];
  public itemsServicce: Servicce[];

  itemServicse : Servicse[] = [] ;
  itemWal : Walet[] = [] ;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreatevirementrecuopComponent>,
    private fb: FormBuilder,
  ) {
    this.getService();
    this.getWallet();
   }

  ngOnInit() {
    this.buildItemForm(this.data.payload);
    this.getService();
    this.getWallet();
    this.getItemGetServces();



    this.itemForm.controls['services'].valueChanges.subscribe((value) => {       
      console.log(" services " ) ;
      console.log(this.itemForm.get('services').value);
      let srv : Servicse = this.itemForm.get('services').value ;
      console.log(" active_wallets " ) ;
     let ws : Walet[] = srv.active_wallets ;
     this.itemWal=[]     ;
      this.itemWal=ws ;
      console.log(this.itemWal);
    })
  }



  buildItemForm(item) {
    console.log(item);
    this.itemForm = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'montant': [null, Validators.compose([Validators.required])],
      'datevalidemontant': [null, Validators.compose([Validators.required])]
    });
  }

  submit() {
    this.dialogRef.close(this.itemForm.value);
  }




  getService(){
    let services : Servicce[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservice"));
    this.itemsServicce=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservice"));
  }

  getWallet(){
    let wallets : Wallet[] ;
    wallets = [] ;
    wallets=JSON.parse(localStorage.getItem("allwallet"));
    this.itemsWallet=wallets;
    console.log("wallet taille : "+this.itemsWallet.length);
  }


  getItemGetServces() {
    let services : Servicse[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservics"));
    this.itemServicse=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservics"));
  }

}
