import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, exhaustMap, map } from 'rxjs/operators';
import { ConfigService } from 'ngx-envconfig';
import { Router } from '@angular/router';
import { GlobalVariableService } from './global-variable.service';
import { DepositService } from './deposit.service';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class SecuService {
  // Define API
  API_URL: string;
  API_HOST: string;
  CNAM_ID: string;
  CNAM_KEY: string;
  API_PREPAID_URL: string;
  balance_account:number;
  constructor(private http: HttpClient,
    public configService: ConfigService, 
    private router: Router, 
    private variable: GlobalVariableService,
    private depositService: DepositService,
    private auth: AuthenticationService
    ) {
    this.API_URL = configService.get('HOST_API_ASSURE_CNAM');
    this.API_HOST = configService.get('HOST_API_CNAM');
    this.CNAM_ID = configService.get("CNAM_ID");
    this.CNAM_KEY = configService.get("CNAM_KEY");
    this.API_PREPAID_URL = configService.get("HOST_API_MODULE_PREPAYE");
  }

  /**
   * @author André ATCHORI
   */

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.variable.getAccessToken()
      })
  };
  

  // public isAuthenticated(): boolean {
  //   const token = this.variable.getAccessToken();
  //   return !this.jwtHelper.isTokenExpired(token);
  // }

  // HttpClient API post() method => get Details Assure
  getDetailsAssure(data, options): Observable<any> {
    return this.http.post<any>(this.API_URL + 'CNAM/subscriber/auth', data, options)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => get list Assure Couvert
  getListAssureCouvert(data, options): Observable<any> {
    return this.http.post<any>(this.API_URL + 'CNAM/subscriber/fellows', data, options)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => get list Cotisations
  getListCotisations(data, options): Observable<any> {
    return this.http.post<any>(this.API_URL + 'CNAM/unpaid/history', data, options)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => get Montant cotisation individuelle
  getMontantCotisationIndividuel(data, options): Observable<any> {
    return this.http.post<any>(this.API_URL + 'CNAM/subscriber/personal_fee', data, options)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => get Montant cotisation familiale
  getMontantCotisationFamilial(data, options): Observable<any> {
    return this.http.post<any>(this.API_URL + 'CNAM/subscriber/family_fee', data, options)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  // HttpClient API post() method => Payer cotisation
  payCotisations(data, options): Observable<{
    balance:number, code:string, transactionId: string, msg:string, refId:any
}> {   
    return this.depositService.getServiceCredentials(this.CNAM_ID, this.CNAM_KEY).pipe(
      exhaustMap((response) => {
        return this.http
          .get<any>(
            this.API_PREPAID_URL + "buisness/solde/" + response.servicecode,
            this.depositService.getHttpOptions(response.key)
          )
          .pipe(
            exhaustMap(res => {
              this.balance_account = res.solde
              try {
                 if(Number(res.solde) == 0) return throwError(`${this.balance_account}`);
                let refTransaction = new Date().getTime().toString();
                return this.depositService.debitPrepaidAccountBalance(1,data.amount, response.servicecode, response.key,refTransaction ).pipe(
                  exhaustMap(res => {
                    return this.http.post<any>(this.API_URL + 'CNAM/payment/process', data, options)
                      .pipe(
                        map((res2) => {
                          const data: any = res2
                          return {
                            balance : this.balance_account,
                            code : response.servicecode,
                            transactionId: data.data.transaction_id,
                            msg:data,
                            refId:refTransaction
                          }
                        }),
                        catchError((error) => {
                          if(error.status == 401){
                            return throwError({error: "Veuillez vous réconnecter !"}); 
                          }
                          
                          return this.handleError(`${this.balance_account}`)
                        })
                        
                      );
                  }),
                  catchError((error) => {
                    if(error.status == 401){
                      return throwError({error: "Veuillez vous réconnecter !"}); 
                    }
                    return this.handleError(`${this.balance_account}`)
                  })
                 
                )
              } catch (error) {
                // console.log(333333, error)
                throw new Error(error.message)
              }
             
            }),
            catchError((error) => {
              // console.log(444444, error)
              if(error.status == 401){
                return throwError({error: "Veuillez vous réconnecter !"}); 
              }
              return throwError(this.balance_account); 
            })
          );
      }),
      catchError((error) => {
        // console.log(555555, error)
        if(error.status == 401){
          return throwError({error: "Veuillez vous réconnecter !"}); 
        }
        return throwError(this.balance_account); 
      })
      )
  }

  // HttpClient API post() method => save Vente
  saveVente(data): Observable<any> {
    const headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.variable.getAccessToken()
    });
    return this.http.post<any>(this.API_HOST + 'vente/save', data, {headers})
    .pipe(
      catchError(this.handleError)
    );
  }


  // Error handling
  handleError(error) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     return throwError(errorMessage);
  }

}
