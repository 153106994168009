import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalVariableService {
  public menus_id: any;
  public menu: any;
  public permissions: any;
  constructor() {
    this.menus_id = '';
    this.menu = [];
    this.permissions = [];
  }

    setUser(data) {
        window.localStorage.setItem('user', JSON.stringify(data));
    }
  
    setStayConnected(value) {
      window.localStorage.setItem('stay_connected', value);
    }

    setAccessToken(value) {
      window.localStorage.setItem('access_token', value);
    }

    setErrorMessage(data) {
      window.localStorage.setItem('message', data);
    }

    setMenu(value) {
      localStorage.setItem('menu', JSON.stringify(value));
    }
  
    setMenuId(value) {
      this.menus_id = value;
    }

    setPermissions(value) {
      localStorage.setItem('permissions', JSON.stringify(value));
    }
  
    setDetailsSecu(value) {
      localStorage.setItem('detailsSecu', JSON.stringify(value));
    }

    getUser() {
      return window.localStorage.getItem('user');
    }
  
    getStayConnected() {
      return window.localStorage.getItem('stay_connected');
    }

    getErrorMessage() {
      return window.localStorage.getItem('message');
    }

    getAccessToken() {
      return window.localStorage.getItem('access_token');
    }
  
    getMenuId() {
      return this.menus_id;
    }

    getMenu() {
      // return this.menu;
      return JSON.parse(localStorage.getItem('menu'));
    }

    getPermissions() {
      return JSON.parse(localStorage.getItem('permissions'));
    }
  
    getDetailsSecu() {
      return JSON.parse(localStorage.getItem('detailsSecu'));
    }

    removeUser() {
      localStorage.removeItem('user');
    }

}
