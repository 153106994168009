import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class CaissiereService {

  private apiMS = 'http://localhost:9393/oneci/';

  constructor(
    private http: HttpClient,
  ) { }

  public findAllCaissiere(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiMS + 'caissiere/getallcaissiere', {
      observe: 'response'
    });
  }

  public findAllGestionnaire(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiMS + 'caissiere/getalladmin', {
      observe: 'response'
    });
  }

  public createCaissiere(value): Observable<HttpResponse<any>> {
    return this.http.post(this.apiMS + 'caissiere/createcaissier', value, {
      observe: 'response'
    });
  }


  
  public createAdmin(value): Observable<HttpResponse<any>> {
    return this.http.post(this.apiMS + 'caissiere/createadmin', value, {
      observe: 'response'
    });
  }


  public deleteCaissiere(value): Observable<HttpResponse<any>> {
    return this.http.delete(this.apiMS + 'caissiere/deletecaissier/' + value, {
      observe: 'response'
    });
  }

  public updateCaissiere(value): Observable<HttpResponse<any>> {
    return this.http.put(this.apiMS + 'caissiere/updatecaissier', value, {
      observe: 'response'
    });
  }

}
