import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton, MatProgressBar, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../../shared/services/auth.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { ConfigService } from 'ngx-envconfig';


@Component({
  selector: 'app-portail',
  templateUrl: './portail.component.html',
  styleUrls: ['./portail.component.scss']
})
export class PortailComponent implements OnInit {

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  signinForm: FormGroup;
  portailData: any = [
    {
      "img": "assets/images/portail/logo_ONECI.png",
      "libelle": "Services ONECI",
      "type": "oneci",
      "width": 180
    },
    {
      "img": "assets/images/portail/logo_CNAM.png",
      "libelle": "Services CNAM",
      "type": "cnam",
      "width": 100
    },
    {
      "img": "assets/images/portail/logo_historique_generale.png",
      "libelle": "Historique génerale",
      "type": "historique",
      "width": 180
    }
  ];
  constructor(
    private title: Title,
    public formBuilder: FormBuilder,
    private auth: AuthenticationService,
    public snackBar: MatSnackBar,
    private loader: AppLoaderService,
    private configService: ConfigService,
    public router: Router) {
      this.title.setTitle('Portail d\'application | Ma poste');
    }


  ngOnInit() {
    //this.getallfonctionalite();
    localStorage.clear();
    // this.checkIfTokenIsCorrect();
  }

  checkIfTokenIsCorrect() {
    this.auth.getUserDetails().subscribe(data => {
      console.log(data.error.status);
      switch (data.error) {
        case true:
          this.loader.close();
          this.snackBar.open(data.message, 'OK', { duration: 4000 });
          break;
        default: {
          this.router.navigate(['/settings']);
          break;
        }
      }
    },
      error => {
        this.loader.close();
        this.snackBar.open(error, 'OK', { duration: 4000 })
      }
    );
  }

  sign_in(type) {
    if (type === 'oneci') {
      window.location.href = `${this.configService.get('URL_ONECI_APP')}`;
      // window.open(`${this.configService.get('URL_ONECI_APP')}`, '_blank');
    } else {
      this.router.navigate(['/seconnecter', { service: type }])
    }
  }


}
