import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/shared/models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../../../../shared/services/excel.service';
import swal from 'sweetalert2';
import { SettingsService } from 'app/shared/services/settings.service';
import { FormBuilder, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EditUtilisateurComponent } from './edit-utilisateur/edit-utilisateur.component';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { DetailsUtilisateurComponent } from './details-utilisateur/details-utilisateur.component';
import { GlobalVariableService } from '../../../../shared/services/global-variable.service';
import { GestionUtilisateurs } from 'app/shared/models/permissions';

@Component({
  selector: 'app-creation-utilisateur',
  templateUrl: './creation-utilisateur.component.html',
  styleUrls: ['./creation-utilisateur.component.scss']
})
export class CreationUtilisateurComponent implements OnInit {
  status: string = 'Desactivé';
  selectedAgence = [];
  listAgence: any;
  usersData: User = new User(null, null, null, null, null, null);
  public response: any;
  listUsers = [];
  arrayPermissions: any = [];
  loaderText: string;
  API_URL: any;
  filter: any;
  p: number = 1;
  arrayUtilisateurs: any = [];
  permissionsSettingsUtilisateurs: GestionUtilisateurs = new GestionUtilisateurs(null, null, null, null, null, null, null, null);
  submitted = false;
  listInspection: any = [];
  formGroup = this.fb.group({
    file: [null, Validators.required]
  });
  constructor(private fb: FormBuilder, private eventEmitter: EventEmitterService, private variable: GlobalVariableService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, private http: HttpClient, private _router: Router, private _settings: SettingsService,
  private toastrService: ToastrService, private spinner: NgxSpinnerService, private excelService: ExcelService,
  ) { }

  addUser(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  detailsUser = function(data) {
    const modalRef = this.modalService.open(DetailsUtilisateurComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.user = data;
  };

  editUser = function(data) {
    const modalRef = this.modalService.open(EditUtilisateurComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.user = data;
  };

    // Upload file
    onFileChange(ev) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
        this.formGroup.patchValue({
          file: reader.result
        });
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        const dataStringParse = JSON.parse(dataString);
        this.arrayUtilisateurs = dataStringParse;
        // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
        // this.setDownload(dataString);
      }
      reader.readAsBinaryString(file);
    }
  
    addUserBulk(content) {
      this.activeModal = this.modalService.
      open(content, { backdrop: 'static', keyboard: false, centered: true  });
    }
  
    // Submit Form
    onSubmit() {
      this.submitted = true;
      // stop here if form is invalid 
      if (this.formGroup.invalid) {
        return;
      }
      this.saveAllUsers(this.arrayUtilisateurs);
    }
  
    // Save all metrique
  saveAllUsers(data) {
      this._settings.saveAllUsers(data).subscribe((data: {}) => {
        this.response = data;
        if (!this.response.error) {
          swal({
            title: 'CNAM SYSTEM',
            text: this.response.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.excelService.exportAsExcelFile(this.response.data, 'Rapport sauvegarde des utilisateurs');
              this.getAllUsers();
            }
          });
        } else {
          swal('CNAM SYSTEM', this.response.message, 'error')
        }
        // !this.response.error ? this.toastr.success(this.response.message) : swal.fire('CNAM SYSTEM', this.response.message, 'error');
      });
    }

  saveUser() {
      this._settings.createUsers(this.usersData)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            this.activeModal.close();
            this.usersData.nom_prenoms = '';
            this.usersData.code = '';
            this.usersData.email = '';
            this.usersData.telephone = '';
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.activeModal.close();
                this.getAllUsers();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    }

    
    lock_user(data){
      this._settings.disableUsers(data.id)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.refresh();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
  }
  
  deleteUser(data) {
      swal({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas revenir en arrière!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonText: 'Oui, supprimez-le!'
      }).then((result) => {
        if (result.value) {
          this._settings.deleteUsers(data.id)
          .subscribe(
            res => {
              console.log(res);
              if (res.error === false) {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'success',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                }).then((result)  => {
                  if (result) {
                    this.activeModal.close();
                    this.refresh();
                  }
                });
              } else {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }

  onSelectAgence(value) {
    this.usersData.agence_id = value;
  }

  onSelectService(value) {
    this.usersData.service = value;
  }

  closeModal() {
    this.activeModal.close();
  }

  getListPermissions() {
    this.arrayPermissions = this.variable.getPermissions();
    console.log(this.arrayPermissions);
    const create_utilisateur = this.arrayPermissions.find(x => x.slug === 'creation-dutilisateur');
    const update_utilisateur = this.arrayPermissions.find(x => x.slug === 'modification-dutilisateur');
    const suppression_utilisateur = this.arrayPermissions.find(x => x.slug === 'suppression-dutilisateur');
    const exportation_utilisateur = this.arrayPermissions.find(x => x.slug === 'exportation-de-la-liste-des-utilisateur');
    const creation_masse_utilisateur = this.arrayPermissions.find(x => x.slug === 'creation-en-masse-dutilisateur');
    this.permissionsSettingsUtilisateurs.creation_utilisateur = create_utilisateur.is_visible;
    this.permissionsSettingsUtilisateurs.modification_utilisateur = update_utilisateur.is_visible;
    this.permissionsSettingsUtilisateurs.suppression_utilisateur = suppression_utilisateur.is_visible;
    this.permissionsSettingsUtilisateurs.exportation_utilisateur = exportation_utilisateur.is_visible;
    this.permissionsSettingsUtilisateurs.creation_masse_utilisateur = creation_masse_utilisateur.is_visible;
  }


  // export to excel
  exportXls() {
    let data = [];
    this.listUsers.forEach(element => {
      data.push(
        {
          'Code': element.code,
          'Nom & Prénoms': element.nom_prenoms,
          'Email': element.email,
          'Zone': element.agence,
          'Service': element.service,
          'Télephone': element.telephone,
          'Statut': element.status ? 'Activé' : 'Desactivé'
        }
      )
    });
    this.listUsers.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste des utilisateurs') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

  // Get all users
  getAllUsers() {
    this._settings.getAllUsers().subscribe((data: {}) => {
      this.response = data;
      this.listUsers = this.response.data;
    });
  }

  // Get all agence
  getAllAgence() {
    this._settings.getAllAgence().subscribe((data: {}) => {
      this.response = data;
      this.listAgence = this.response.data;
    });
  }

  // Get all inspection
  getAllInspection() {
    this._settings.getAllInspection().subscribe((data: {}) => {
      this.response = data;
      this.listInspection = this.response.data;
    });
  }

  refresh() {
    this.getAllUsers();
  }

  ngOnInit(): void {
    this.getAllUsers();
    this.getAllAgence();
    this.getListPermissions();
    if (this.eventEmitter.subsVar === undefined) {
      this.eventEmitter.subsVar = this.eventEmitter.
      invokeOnGetAllUsers.subscribe((name: string) => {
        this.getAllUsers();
      });
    }
  }

}
