import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatButton, MatDialog, MatProgressBar, MatSnackBar} from '@angular/material';
import {matchingPasswords} from '../../shared/models/app-validator';
import {DashboardService} from '../../shared/services/dashboard.service';
import {AppConfirmService} from '../../shared/services/app-confirm/app-confirm.service';
import {AppLoaderService} from '../../shared/services/app-loader/app-loader.service';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;

  public id : number ;
  public key : number ;

  private sub: any;





  constructor(public formBuilder: FormBuilder, public snackBar: MatSnackBar , public router:Router , public dasboardservice : DashboardService,
              private dialog: MatDialog,
              private snack: MatSnackBar,
              private confirmService: AppConfirmService,
              private loader: AppLoaderService,
              private fb: FormBuilder, 
              private activatedRoute: ActivatedRoute) { }

  registerForm: FormGroup;


  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id =params['id'];
      this.key=params['key'];
      console.log("id : "+this.id+" ; ket : "+this.key);
    });

    this.registerForm = this.formBuilder.group({
      'password': ['', Validators.required],
      'confirmPassword': ['', Validators.required]
    },{validator: matchingPasswords('password', 'confirmPassword')});
  }



  redirectUrl(){
    this.router.navigate(['/seconnecter']);
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: "Votre mot de passe à été modifié avec succès ! » Vous pouvez vous connecter"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }


  signin(){
    this.submitButton.disabled = true;
    this.progressBar.mode = 'indeterminate';
    const signinData = this.registerForm.value
    console.log(signinData);
    let path : string ;
    console.log(path);

    if((this.id!=null) && (this.key!=null) && (this.id!=undefined) && (this.key!=undefined)  ){
      this.loader.open();
      this.dasboardservice.resetpassword(this.id+"/"+this.key+"/"+this.registerForm.controls['password'].value).subscribe(data => {
          console.log(data);
          let v : Boolean = data.body ;
          console.log(v);
          if(v==true){
            //this.snack.open(' Votre compte a été reinitialiser avec succes veuillez vous identifier afin de vous connecter ', 'OK', { duration: 4000 })
            this.openDialog();
            this.registerForm.reset() ;
          }else{
            this.snack.open('Echec veuillez verifier vos parametres ', 'OK', { duration: 4000 })
          }
          this.loader.close();

        }
      );

      this.submitButton.disabled = false;
      this.progressBar.mode = 'determinate';
    }else{
      this.redirectUrl();
    }

  }

}
