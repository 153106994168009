import { Caissiere } from './../../../shared/models/caissiere';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppLoaderService } from './../../../shared/services/app-loader/app-loader.service';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from './../../../shared/services/app-confirm/app-confirm.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Usercaisse } from 'app/shared/models/usercaisse';
import { Pmurequest } from 'app/shared/models/pmurequest';
import { Valeur } from 'app/shared/models/valeur';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss']
})
export class HistoriqueComponent implements OnInit {

  datedebut: string = "";

  datefin: string = "";

  public itemFormSearch: FormGroup;

  cumulTransac: Valeur = new Valeur();


  nbre: number = 0;
  total: number = 0;


  periodedebut: string = "";

  periodefin: string = "";

  public items: any[];
  temp = [];
  rows = [];
  columns = [];
  useUtcForDisplay = true;
  renderedData: any;
  okdisabled: boolean = false;


  public itemForm: FormGroup;

  public itemFormCumul: FormGroup;

  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy hh:mm a',
    defaultOpen: false,
    closeOnSelect: false
  }

  private _dateValue: Date;

  public getItemSub: Subscription;





  constructor(private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private fb: FormBuilder,
    private fbc: FormBuilder,
    private datePipe: DatePipe, ) { }

  ngOnInit() {

    this.itemFormSearch = this.fb.group({
      to: [''],
      from: [''],
    });

    this.getItems();
    this.getallcumultransactionforday();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }

  getItems() {
    this.getItemSub = this.dashboardService.getVente()
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            console.log("retour");
            console.log(data);
            this.items = this.temp = data.body;


            let nbrelocal: number = 0;
            let nbretotal: number = 0;

            this.items.forEach(function (value) {
              let vente: Caissiere = value;
              nbrelocal = vente.ventenombre + nbrelocal;
              nbretotal = nbretotal + vente.ventemotanttotal;

            });

            this.nbre = nbrelocal;
            this.total = nbretotal;

            this.loader.close();
            break;
          }
        }
      },
        error => {
          switch (error.status) {

            default: {
              this.loader.close();
              this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
              break;
            }
          }
        });

  }




  exportCsv() {
    if (this.items.length > 0) {
      let itemscsv: any[] = [];
      console.log(" nombre de items : " + this.items.length);
      this.items.forEach(function (element) {
        console.log(element);
        if (element.ventedatecreation.length > 17) {
          element.ventedatecreation = element.ventedatecreation.substr(0, 16).replace("T", " ");
        }

        var my_json = {
          "Date Transaction": element.ventedatecreation,
          "Code Timbre ": element.ventetransaction,
          "Type Demande": element.ventetypedemande,
          "Nom": element.ventenom,
          "Prenom": element.venteprenom,
          "Nombre Ticket": element.ventenombre,
          "Montant Total": element.ventemotanttotal
        };

        console.log("my_json");
        console.log(my_json);
        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'Vente Timbre');

    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




  download(req: Caissiere) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    let url: string;
    url = this.dashboardService.uploadrecutimbre;
    a.href = this.dashboardService.uploadrecutimbre + "/" + req.venteid;
    a.target = "_blank";
    a.download = "test.pdf";
    a.name = "test"
    a.click();
    document.body.removeChild(a);
  }





  allresearch() {
    let u = new Usercaisse();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      let to: string = "";
      let from: string = "";
      this.datedebut = "";
      this.datefin = "";

      console.log("itemFormCumul " + this.itemFormSearch.get("from").value.toString());

      let date = new Date(this.itemFormSearch.get("from").value);
      let date1 = new Date(this.itemFormSearch.get("to").value);
      console.log(" var date : " + date);
      console.log("message");
      console.log("" + date.toString() === 'Invalid Date');
      console.log(" fromc " + this.itemFormSearch.get("from"));
      console.log(" toc " + this.itemFormSearch.get("to"));
      console.log(this.itemFormSearch);
      if ((date.toString() != 'Invalid Date')) {
        from = this.itemFormSearch.get("from").value;
      }

      if (date1.toString() != null) {
        to = this.itemFormSearch.get("to").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);

      console.log(" date " + newDate);
      console.log(" date1 " + newDate1);

      if ((newDate.toDateString() === "Invalid Date") || (newDate1.toDateString() === "Invalid Date")) {
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      } else {
        if (newDate1 >= newDate) {
          this.loader.open();
          console.log("true");
          let cumulstat: Pmurequest = new Pmurequest();
          cumulstat.from = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
          this.datedebut = cumulstat.from;
          cumulstat.to = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
          this.datefin = "au " + cumulstat.to;

          this.periodedebut = this.getTimeByFormat(cumulstat.from);
          this.periodefin = this.getTimeByFormat(cumulstat.to);
          if (this.periodedebut.length > 17) {
            this.periodedebut = this.periodedebut.substring(0, 17);
          }
          if (this.periodefin.length > 17) {
            this.periodefin = this.periodefin.substring(0, 17);
          }

          cumulstat.oper = "gestionnaire";
          //cumulstat.userid = u.agence.agenceid;

          this.getItemSub = this.dashboardService.getStat(cumulstat)
            .subscribe(data => {
              switch (data.status) {
                case 200: {
                  console.log("retour");
                  console.log(data);
                  let caisse: Caissiere[] = data.body;
                  this.cumulDayTransac(caisse);
                  this.items = caisse;
                  this.loader.close();
                  break;
                }

              }
            },
              error => {
                switch (error.status) {

                  default: {
                    this.loader.close();
                    this.snack.open('erreur survenue !', 'OK', { duration: 4000 })
                    break;
                  }
                }
              });

        } else {
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }
    }

  }




  cumulDayTransac(vente: Caissiere[]) {

    let nbre: number = 0;
    let mnt: number = 0;

    for (let caisse of vente) {
      nbre++;
      mnt = mnt + caisse.ventemotanttotal;
    }

    this.cumulTransac.nombre = nbre;
    this.cumulTransac.montant = mnt;



  }



  getallcumultransactionforday() {
    let date = new Date();
    let dates: string = this.datePipe.transform(date, "yyyy-MM-dd");
    this.datedebut = dates.trim() + " 00:01:01";
    this.datefin = dates.trim() + " 23:59:59";
    let cumulstat: Pmurequest = new Pmurequest();
    cumulstat.from = this.datedebut;
    cumulstat.to = this.datefin;
    this.datedebut = this.getTimeByFormat(this.datedebut);
    this.datefin = this.getTimeByFormat(this.datefin);
    this.periodedebut = this.datedebut;
    this.periodefin = this.datefin;

    let u = new Usercaisse();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {
      cumulstat.oper = "gestionnaire";
      //   cumulstat.userid = u.agence.agenceid;
      console.log(" cumulstat ");
      console.log(cumulstat);
    }

    this.dashboardService.getStat(cumulstat)
      .subscribe(data => {
        switch (data.status) {
          case 200: {
            console.log(data);

            let caisse: Caissiere[] = data.body;
            this.cumulDayTransac(caisse);
            /*
            let vals: Valeur = new Valeur();

            vals = data.body;
            console.log("Valeur");
            //  console.log("Montant : "+vals.montant+" ; Nombre : "+vals.nombre);
            this.cumulTransac = vals;
            */
            this.loader.close();
            break;
          }

        }
      },
        error => {
          console.log('data');
          console.log(error);
          switch (error.status) {

            default: {
              this.loader.close();
              this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
              break;
            }
          }

        }
      );



  }




  getTimeByFormat(value: string) {
    let day: string = value.substring(8, 10);
    let mount: string = value.substring(5, 7);
    let year: string = value.substring(0, 4);
    let hours: string = value.substr(10);
    return day + "-" + mount + "-" + year + " " + hours;
  }


}
