import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../../shared/services/settings.service';
import { ExcelService } from '../../../../shared/services/excel.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-piste-audit',
  templateUrl: './piste-audit.component.html',
  styleUrls: ['./piste-audit.component.scss']
})
export class PisteAuditComponent implements OnInit {
  response: any;
  listPisteAudite: any = [];
  filter: any;
  p: number = 1;
  date_debut: any;
  date_fin: any;
  constructor(private _settings: SettingsService, private excelService: ExcelService) { }

  ngOnInit() {
    this.getAllPisteAudite();
  }

  // Get all piste audite
  getAllPisteAudite() {
    this._settings.getPisteAudite().subscribe((data: {}) => {
      this.response = data;
      this.listPisteAudite = this.response.data;
    });
  }

  // Filter audit by date
  filterAuditByDate() {
    this._settings.getAuditeByDate(this.date_debut, this.date_fin).subscribe((data: {}) => {
      this.response = data;
      this.listPisteAudite = this.response.data;
    });
  }

  // export to excel
  exportXls() {
    let data = [];
    this.listPisteAudite.forEach(element => {
      data.push(
        {
          'Utilisateurs': element.nom_prenoms,
          'Vente': element.vente ? element.vente : 'N/A',
          'Impression': element.impression ? element.impression : 'N/A',
          'Date/Heure': element.created_at,
        }
      )
    });
    this.listPisteAudite.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste piste audite') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

}
