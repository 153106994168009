import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-updateuser',
  templateUrl: './updateuser.component.html',
  styleUrls: ['./updateuser.component.scss']
})
export class UpdateuserComponent implements OnInit {

  public itemForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateuserComponent>,
    private fb: FormBuilder,
  ) { }


  ngOnInit() {
    this.buildItemForm(this.data.payload)
  }

  buildItemForm(item) {
    console.log(item);
    this.itemForm = this.fb.group({
      utilisateurid :[item.utilisateurid , Validators.compose([Validators.required])],
      utilisateurentreprise :[item.utilisateurentreprise , Validators.compose([Validators.required])],
      utilisateurname :[item.utilisateurname , Validators.compose([Validators.required])],
      utilisateurphone :[item.utilisateurphone , Validators.compose([Validators.required])]
    });
  }

  submit() {
    this.dialogRef.close(this.itemForm.value);
  }


}
