import { Component, OnInit } from "@angular/core";

import swal from "sweetalert2";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DepositService } from "app/shared/services/deposit.service";
import { DepositDetailsComponent } from "./components/deposit-details/deposit-details.component";
import { ExcelService } from "app/shared/services/excel.service";
import { Credit } from "app/shared/models/Credit";
import { GlobalVariableService } from "app/shared/services/global-variable.service";
import { ConfigService } from "ngx-envconfig";


@Component({
  selector: "app-deposit",
  templateUrl: "./deposit.component.html",
  styleUrls: ["./deposit.component.scss"],
 
  
})
export class DepositComponent implements OnInit {
  public depositList: any = [];
  public serviceBalance = 0;
  public loaderText: string;
  public page: number = 1;
  public depositAmount: number;
  public moyen: string;
  public depositEmitter: string;
  public filter = "";
  depositData:  Credit = new Credit(null, null, null);
  depositForm: FormGroup;
  loading: boolean = false;


  constructor(
    private depositService: DepositService,
    private excelService: ExcelService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private variable: GlobalVariableService,
    public configService: ConfigService
    
  ) {
    this.depositForm = this.formBuilder.group({
      amount: ['', [Validators.required,]],
      moyen: ['', [Validators.required,]],
      emetteur: ['', [Validators.required,]],
    });
  }

  ngOnInit() {
    this.getAllDeposits();
    this.getServiceBalance();
  }

  public getAllDeposits() {
    this.depositService.getAllDeposits().subscribe((data: {}) => {
      this.depositList = data;
    });
  }

   // Only Integer Numbers
   keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  public getServiceBalance() {
    this.depositService.getServiceBalance().subscribe((data) => {
      this.serviceBalance = data.solde;
    });
  }

  public refresh() {
    this.getAllDeposits();
  }

  // export to excel
  exportXls() {
    let data = [];
    this.depositList.forEach((element) => {
      data.push({
        Date: element.depositdate,
        Montant: element.depositemontant,
        Service: element.servicename,
        Banque: element.banque,
        Bordereau: element.numerobordereau,
      });
    });

    this.depositList.length > 0
      ? this.excelService.exportAsExcelFile(data, "Liste des deposits")
      : swal("CNAM SYSTEM", "Aucune donnée dans le tableau", "error");
  }

  public getDepositDetails = function (deposit) {
    const modalRef = this.modalService.open(DepositDetailsComponent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.deposit = deposit;
  };

  public openDialog(content){
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  public makeDeposit(){
    if (this.depositForm.valid) {
      this.loading = true;
      //console.log(this.depositForm.value.amount);
      this.depositAmount = this.depositForm.value.amount
      this.moyen = this.depositForm.value.moyen
      this.depositEmitter = this.depositForm.value.emetteur
      this.depositService.reloadPrepayAccount(this.depositAmount, this.moyen, this.depositEmitter ,  this.variable.getAccessToken()).subscribe(dataum =>{
        //Optionally: reset the form after submission
          this.resetForm();
          //Redirect to gateway
          this.handlePaymentForm(
            dataum.data.order_id,
            dataum.data.auth_token,
            this.depositAmount
          );
          this.loading = false;
          this.activeModal.close(); 
        });     
      this.depositForm.reset();
    }

    
  }
  
  private handlePaymentForm(orderId:string,jwtToken:string,operationAmount:number) {
    const form = document.getElementById('gateway') as HTMLFormElement;
    form.action = this.configService.get("GATEWAY_URL");
    this.createHiddenInput(form, 'currency', 'xof');
    this.createHiddenInput(form, 'operation_token', this.configService.get("OPERATION_TOKEN"));
    this.createHiddenInput(form, 'order',orderId);
    this.createHiddenInput(form, 'transaction_amount', operationAmount);
    this.createHiddenInput(form, 'jwt', jwtToken);
  
    form.submit();
  }

  private createHiddenInput(
    form: HTMLFormElement,
    name: string,
    value: any = ''
  ): void {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    form.appendChild(input);
  }


  closeModal() {
    this.activeModal.close();
    this.loading = false;
    this.resetForm;
  }

  resetForm() {
    this.depositForm.reset();
 }
}
