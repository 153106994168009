import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Caisseresponse } from 'app/shared/models/caisseresponse';
import { Caissereglement } from '../../shared/models/caissereglement';
import { Caissier } from '../../shared/models/caissier';
import { Caisserequest } from '../../shared/models/caisserequest';
import { Generaterecu } from 'app/shared/models/generaterecu';

@Component({
  selector: 'app-reglementvoucher',
  templateUrl: './reglementvoucher.component.html',
  styleUrls: ['./reglementvoucher.component.scss']
})
export class ReglementvoucherComponent implements OnInit {


  genvoucherForm: FormGroup;
  responseachat: Caisseresponse;
  statussend: Boolean = true;


  hideedan: string = 'password';
  vs: string = 'visibility_off';




  constructor(private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.genvoucherForm = new FormGroup({
      matricule: new FormControl('', Validators.required),
      montant: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })
  }





  getReglement() {

    let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
    if (valeur_nbre.trim().length > 0) {
      let u = new Caissier();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        if (this.genvoucherForm.valid) {
          let requestachat: Caisserequest = new Caisserequest();
          requestachat.typeoperation = "wOvclmAo";
          requestachat.reference = u.matricule;
          let valeur_nbre: string;
          valeur_nbre = this.genvoucherForm.controls['montant'].value;
          valeur_nbre = valeur_nbre.split(" ").join("");
          requestachat.montant = valeur_nbre;
          requestachat.montant = valeur_nbre;
          requestachat.password = this.genvoucherForm.controls['password'].value;
          requestachat.numerovoucher = this.genvoucherForm.controls['matricule'].value;


          this.loader.open();
          this.dashboardService.getencaissementvouncher(requestachat)
            .subscribe(data => {
              switch (data.status) {
                case 200: {
                  console.log(data);

                  let response: any = data.body;
                  let caissrep: Caisseresponse = new Caisseresponse();
                  caissrep = response;

                  if (caissrep.responsecode === '200') {
                    this.statussend = false;
                    this.responseachat = caissrep;
                    this.loader.close();
                  } else {
                    this.statussend = true;
                    this.loader.close();
                    this.snack.open('Echec de la transaction !!! veuillez verifier vos parametres !', 'OK', { duration: 10000 });
                  }

                  break;
                }
                case 201: {
                  console.log(data);

                  let response: any = data.body;
                  let caissrep: Caisseresponse = new Caisseresponse();
                  caissrep = response;

                  if (caissrep.responsecode === '200') {
                    this.statussend = false;
                    this.responseachat = caissrep;
                    this.loader.close();
                  } else {
                    this.statussend = true;
                    this.loader.close();
                    this.snack.open('Echec de la transaction !!! veuillez verifier vos parametres !', 'OK', { duration: 10000 });
                  }

                  break;
                }

              }
            },
              error => {
                console.log('data');
                console.log(error);
                switch (error.status) {

                  default: {
                    this.loader.close();
                    this.snack.open('Echec de la transaction ', 'OK', { duration: 40000 })
                    break;
                  }
                }
              }
            )

        }

      } else {
        this.router.navigate(['/seconnecter']);
      }
    } else {
      this.snack.open('Veuillez saisir un montant correct !', 'OK', { duration: 10000 });
    }
  }


  newtransaction() {
    this.genvoucherForm = new FormGroup({
      matricule: new FormControl('', Validators.required),
      montant: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })
    this.statussend = true;
  }





  getfacture() {

    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {

      if (this.responseachat != null) {

        let req: Generaterecu = new Generaterecu();
        req.matriculecaissier = u.matricule;
        req.coderecu = this.responseachat.responsevouchercode;
        req.typerecu = "2";

        this.loader.open();
        this.dashboardService.getfacturerecu(req)
          .subscribe(data => {
            switch (data.status) {
              case 200: {
                console.log(data);
                this.loader.close();
                break;
              }
              case 201: {
                console.log(data);
                this.loader.close();
                break;
              }

            }
          },
            error => {
              console.log('data');
              console.log(error);
              switch (error.status) {

                default: {
                  this.loader.close();
                  break;
                }
              }
            }
          )

      }
    }
  }





  onKey(event) { // without type info


    console.log(" key value : " + event.keyCode);

    console.log((event.keyCode > 95) && (event.keyCode < 106));

    var e = event || window.event;

    if ((event.keyCode > 95) && (event.keyCode < 106)) {

      let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
      if (valeur_nbre.trim().length > 0) {
        valeur_nbre = valeur_nbre.split(" ").join("");
        let gen: string = this.numberWithCommas(valeur_nbre);
        console.log(" gen : " + gen);
        this.genvoucherForm.controls['montant'].setValue(gen);
      }
    } if (event.keyCode === 8) {
      let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
      if (valeur_nbre.trim().length > 0) {
        valeur_nbre = valeur_nbre.split(" ").join("");
        let gen: string = this.numberWithCommas(valeur_nbre);
        console.log(" gen : " + gen);
        this.genvoucherForm.controls['montant'].setValue(gen);
      }

    } else {

      /*
      let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
      let length : number = valeur_nbre.length ;
    
      let value_final : string = valeur_nbre.substring(0, length-1);
      let gen : string =this.numberWithCommas(value_final) ;
      console.log(" gen : "+gen);
      this.genvoucherForm.controls['montant'].setValue(gen);
      */


      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();


    }

  }


  numberWithCommas(value: any) {
    if (value.length > 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }


  showorclosepassword() {
    if (this.hideedan === 'password') {
      this.hideedan = 'text';
      this.vs = 'visibility';
    } else if (this.hideedan === 'text') {
      this.hideedan = 'password';
      this.vs = 'visibility_off';
    } else {
      this.hideedan = 'password';
      this.vs = 'visibility_off';
    }
  }



  addtiret(event) { // without type info

      let valeur_mat: string = this.genvoucherForm.controls['matricule'].value;
      if (valeur_mat.trim().length > 0) {
        valeur_mat = valeur_mat.split(" ").join("");

        if(valeur_mat.trim().length===4){
          this.genvoucherForm.controls['matricule'].setValue(valeur_mat+"-");
        }else if(valeur_mat.trim().length===9){
          this.genvoucherForm.controls['matricule'].setValue(valeur_mat+"-");
        }else if(valeur_mat.trim().length===14){
          this.genvoucherForm.controls['matricule'].setValue(valeur_mat+"-");
        }

      }
  }



   
download() {

  console.log(" download ");
  let u = new Caissier();
  u = JSON.parse(localStorage.getItem("curentuser"));
  if (u != null) {

    console.log(" download u ");


    if (this.responseachat != null) {

      console.log(" download responseachat ");


      let req: Generaterecu = new Generaterecu();
      req.matriculecaissier = u.matricule;
      req.coderecu = this.responseachat.responsevouchercode;
      req.agence = u.agence;
      req.email = u.email;
      req.typerecu = "2";


      let a = document.createElement("a");
      document.body.appendChild(a);
      let url: string;
      url = this.dashboardService.uploadrecu;
      a.href =  this.dashboardService.uploadrecu + "/" + req.matriculecaissier + "/" + req.coderecu + "/" + req.typerecu + "/" + req.email + "/" + req.agence;
      a.target= "_blank";
      a.download = "test.pdf";
      a.name="test"
      a.click();
      document.body.removeChild(a);
    }
  }


}


}
