import { AgenceService } from './agence.service';
import { PopupagenceComponent } from './popupagence/popupagence.component';
import { Subscription } from 'rxjs';
import { AppLoaderService } from './../../../shared/services/app-loader/app-loader.service';
import { AppConfirmService } from './../../../shared/services/app-confirm/app-confirm.service';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { AppProgressbarService } from 'app/shared/services/app-progressbar/app-progressbar.service';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-agence',
  templateUrl: './agence.component.html',
  styleUrls: ['./agence.component.scss']
})
export class AgenceComponent implements OnInit {

  getItemSub: Subscription;
  public items: any[];
  temp = [];

  fileSelected: File;


  constructor(private snack: MatSnackBar, private agenceService: AgenceService,
    private dialog: MatDialog, private confirmService: AppConfirmService,
    private loader: AppLoaderService, private progressbar: AppProgressbarService) { }

  ngOnInit() {
    this.getData();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  getData() {
    this.snack.open('Recherche en cours...', 'OK', { duration: 4000 })

    this.getItemSub = this.agenceService.findAllAgence()
      .subscribe(data => {

        let status = data.status;
        switch (status) {
          case 200:
            console.log('DATA: ', data);
            this.items = data.body;
            this.temp = data.body;
            this.snack.open('Chargement terminé', 'OK', { duration: 4000 })
            break;

          case 204:
            this.snack.open('Aucune ligne trouvé', 'OK', { duration: 4000 })
            break;

          default:
            this.items = data.body;
            this.snack.open('Chargement terminé', 'OK', { duration: 4000 })
            break;
        }



      }, error => {
        this.snack.open('Une erreur survenue, reessayer SVP', 'OK', { duration: 4000 });

      })
  }

  creationAgence(data: any = {}, isNew?) {
    let title = isNew ? "Creation d'une agence" : "Mettre à jour l'agence";
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupagenceComponent, {
      disableClose: true,
      width: '720px',
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        } else {
          this.getData(); // Rafraichir données
        }
      });
  }

  deleteItem(row) {
    this.confirmService.confirm({ message: `Supprimé ${row.agencenom}?` })
      .subscribe(res => {
        if (res) {
          this.loader.open();
          this.agenceService.deleteAgence(row.agenceid)
            .subscribe(data => {
              let statuscode = data.status;

              switch (statuscode) {
                case 200: {
                  this.getData();
                  this.loader.close();
                  this.openDialog("Agence supprimée avec succes");
                  break;
                }
                default: {
                  this.loader.close();
                  this.openDialog("Agence supprimée avec succes");
                  break;
                }
              }

            },
              error => {
                let statuscode = error.status;

                switch (statuscode) {

                  case 500: {
                    this.loader.close();
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.loader.close();
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }


              });
        }


      })
  }





  public fileChangeEvent($event) {
    console.log('FILECHANGE');

    this.fileSelected = $event.target.files[0];
    let path = URL.createObjectURL($event.target.files[0]);
    console.log('PATH');
    console.log(path);




    const constFileSelected: File = $event.target.files[0];
    console.log(constFileSelected);
    console.log('FILENAME');
    console.log(constFileSelected.name);
    console.log(constFileSelected.type);
    console.log(constFileSelected.size);
    console.log(constFileSelected.lastModified);

    // Fonction pour afficher le contenu du fichier
    // this.check(path, constFileSelected.type);


  }

  chargerFichier() {
    // Verifier si le fichier a ete choisi
    if (this.fileSelected == null) {
      this.snack.open('Le choix du repertoire est obligatoire', 'OK', { duration: 7000 });
    } else {

      console.log('type fileSelected');
      console.log(this.fileSelected.type);
      let type = this.fileSelected.type;

      // Verifier si le type de fichier est conforme (.csv, .txt, .xlsx, .xls)
      // if(type === 'application/vnd.ms-excel' || type === 'text/csv' || type === 'text/plain' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      if (type === 'application/vnd.ms-excel' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {

        console.log('type fileSelected');
        console.log(this.fileSelected.size);
        let taille: number = this.fileSelected.size;

        console.log('Debut de la modif');
        // Send to MS
        this.progressbar.open('Progression...', 10);
        this.agenceService.pushFileToStorage(this.fileSelected)
          .subscribe(/*data => {
    let donnees = data;
    console.log('donnees');
    console.log(donnees);
    
    console.log('getItems');
    
    this.getItems();
    this.loader.close();
    this.snack.open('Repertoire cree', 'OK', {duration: 4000})
    
    
    
    }*/event => {

              if (event.type === HttpEventType.DownloadProgress) {
                // {
                // loaded:11, // Number of bytes uploaded or downloaded.
                // total :11 // Total number of bytes to upload or download
                // }
                // console.log('event1');
                // console.log(event.loaded);
              }

              if (event.type === HttpEventType.UploadProgress) {
                // console.log('event2');
                // console.log(event.loaded);


                // Calcul du pourcentage chargé
                const percentDone = Math.round(100 * event.loaded / event.total);
                // console.log(`File is ${percentDone}% uploaded.`);

                if (Number(percentDone) != 100) {
                  this.progressbar.update(Number(percentDone));

                } else {
                  this.progressbar.update(100, 'indeterminate', 'Chargement dans le cloud terminé, Enregistrement en cours...');

                }
                // this.progressbar.update(50);

              }

              if (event.type === HttpEventType.Response) {
                this.progressbar.update(100);

                console.log('DONE');
                // console.log(event.body);
                this.snack.open('Agence cree', 'OK', { duration: 4000 });
                this.getData();
                this.progressbar.close();

              }


            }, error => {
              let statuscode = error.status;
              console.log('error' + error.error, error);


              switch (statuscode) {
                case 404: {
                  this.progressbar.close();
                  this.snack.open('URL introuvable!, contacter le support', 'OK', { duration: 4000 })
                  break;
                }
                case 401: {
                  this.loader.close();
                  this.progressbar.close();
                  this.snack.open('Fichier invalide', 'OK', { duration: 4000 });

                  break;
                }
                case 409: {
                  this.progressbar.close();
                  this.snack.open('Conflit,information dupliquée!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.progressbar.close();
                  this.snack.open('Erreur, verifier votre repertoire', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.progressbar.close();
                  this.snack.open('Une erreur est survenue! verifier votre repertoire', 'OK', { duration: 4000 })
                  break;
                }

              }

            },
          );


      } else {
        this.snack.open('Format non supporté', 'OK', { duration: 7000 });
      }
    }
  }


  openDialog(value : string): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: value
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }


}
