import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import {SettingsService} from "../../../../shared/services/settings.service";
import {CaissierService} from "../../../../shared/services/caissier.service";

@Component({
  selector: 'app-edit-cashier',
  templateUrl: './edit-cashier.component.html',
  styleUrls: ['./edit-cashier.component.scss']
})
export class EditCashierComponent implements OnInit {
  @Input() cashier;
  response: any;
  listAgence: any = [];
  settingsAgence = {};
  agenceSelected: any;
  constructor(private activeModal: NgbActiveModal, private _caissier: CaissierService,
    private eventEmitter: EventEmitterService, private _settings: SettingsService) { }

  ngOnInit() {
    this.getAllAgence();
    this.settingsAgence = {
      singleSelection: true,
      text: 'Sélectionner l\'agence',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_agence',
      enableSearchFilter: true,
      searchBy: ['nom_agence'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune agence disponible'
    };
  }


    onSelectAgence(value){
        this.cashier.agence_id = value;
    }

  onSelectService(value){
    this.cashier.service = value;
  }

    // Get all agence
    getAllAgence() {
        this._settings.getAllAgence().subscribe((data: {}) => {
            this.response = data;
            this.listAgence = this.response.data;
        });
    }

  closeModal() {
    this.activeModal.close();
  }

  updateCaissier() {
    this._caissier.updateCaissier(this.cashier)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          this.activeModal.close();
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.eventEmitter.onGetAllCaissier();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }


}
