import { Component, ElementRef, OnInit, TestabilityRegistry, ViewChild } from '@angular/core';
import { HistoriqueVenteService } from '../../../shared/services/historique-vente.service';
import { ExcelService } from '../../../shared/services/excel.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import * as jspdf from 'jspdf'; 
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { SettingsService } from '../../../shared/services/settings.service';
import { AgencyService } from '../../../shared/services/agency.service';

@Component({
  selector: 'app-historique-vente',
  templateUrl: './historique-vente.component.html',
  styleUrls: ['./historique-vente.component.scss']
})
export class HistoriqueVenteComponent implements OnInit {
  @ViewChild('recuPaiement', {read: ElementRef}) recuPaiement: ElementRef;
  date_debut: any;
  date_fin: any;
  response: any;
  listHistorique: any = [];
  date_of_day: any;
  filter: any;
  page: number = 1;
  user: any;
  detailsCumulIndividual: any;
  detailsCumulFamilial; any;
  numero_recu: any;
  vueListHistorique: boolean = false;
  viewGenerationRecuPaiement: boolean = true;
  nom: any;
  prenom: any;
  selectedPaymentType: any;
  numero_secu: any;
  montant_ht: any;
  montant_ttc: any;
  paye_par: any;
  code_transaction: any;
  API_URL: any;
  settingsDirection = {};
  settingsAgence = {};
  selectedAgence: any;
  selectedDirection: any;
  agence_id: any;
  direction_id: any;
  listDirection: any = [];
  listAgence: any = [];
  constructor(private historiqueService: HistoriqueVenteService, private variable: GlobalVariableService,
    private excelService: ExcelService, private router: Router, private _http: HttpClient,
    private loadingBar: LoadingBarService, private configService: ConfigService, private _settings: SettingsService,
    private _agence: AgencyService) { 
      this.API_URL = this.configService.get('HOST_API_CNAM');
    }

  ngOnInit() {
    this.settingsDirection = {
      singleSelection: true,
      text: 'Sélectionner la direction',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom',
      enableSearchFilter: true,
      searchBy: ['nom'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune direction disponible'
    };
    this.settingsAgence = {
      singleSelection: true,
      text: 'Sélectionner l\'agence',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom_agence',
      enableSearchFilter: true,
      searchBy: ['nom_agence'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune agence disponible'
    };
    this.user = JSON.parse(this.variable.getUser());
    let date_fin;
    var today = new Date();
    var dd = String(today.getDate());
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    let date_debut = '1970' + '-' + '01' + '-' + '01';
    if (mm < 10) {
      date_fin = yyyy + '-' + '0' + mm + '-' + dd;
    } else {
      date_fin = yyyy + '-' + mm + '-' + dd;
    }
    this.date_of_day = date_fin;
    this.getLastTenHistoriqueVente(date_debut, date_fin);
    this.getAllAgence();
    this.getAllDirection();
  }

  range(min, max) {
    return '0000' + Math.floor(Math.random() * (max - min) + min);
  }

  // Get all Historique Vente
  getAllHistoriqueVente() {
    const data = {
      'code_transaction': this.code_transaction,
      'agence_id': this.agence_id,
      'direction_id': this.direction_id,
      'date_debut': this.date_debut,
      'date_fin': this.date_fin
    }
    this.historiqueService.getAllHistoriqueVenteGenerale(data).subscribe((data: {}) => {
      this.response = data;
      this.listHistorique = this.response.data;
      this.detailsCumulFamilial = this.response.data.cumul_familial;
      this.detailsCumulIndividual = this.response.data.cumul_individual;
    });
  }

  calculFraisMontant(data) {
    let new_montant = data.montant.split('FCFA');
    return (parseInt(new_montant[0].replace(/\s/g, '')) * (data.frais/100))
  }

  refresh() {
    if (!this.date_debut && !this.date_fin) {
      let date_debut = '1970' + '-' + '01' + '-' + '01';
      this.historiqueService.getAllHistoriqueVente(date_debut, this.date_of_day).subscribe((data: {}) => {
        this.response = data;
        this.listHistorique = this.response.data;
        this.detailsCumulFamilial = this.response.data.cumul_familial;
        this.detailsCumulIndividual = this.response.data.cumul_individual;
      });
    } else {
      this.getAllHistoriqueVente();
    }
  }

  // Get last ten Historique Vente
  getLastTenHistoriqueVente(date_debut, date_fin) {
    this.historiqueService.getAllHistoriqueVente(date_debut, date_fin).subscribe((data: {}) => {
      this.response = data;
      this.listHistorique = this.response.data;
      this.detailsCumulFamilial = this.response.data.cumul_familial;
      this.detailsCumulIndividual = this.response.data.cumul_individual;
    });
  }

  // Get all direction
  getAllDirection() {
    this._settings.getAllDirection().subscribe((data: {}) => {
      this.response = data;
      this.listDirection = this.response.data;
    });
  }

  // Get all agence
  getAllAgence() {
      this._agence.getAllAgency().subscribe((data: {}) => {
        this.response = data;
        this.listAgence = this.response.data;
      });
  }

  onSelectAgence(item) {
    this.agence_id = item.id;
  }

  onSelectDirection(item) {
    this.direction_id = item.id;
  }

  show(url) {
    this.router.navigate(['/' + url]);
    // this.urlView = url;
  }

  generateRecu(item) {
    this.code_transaction = item.code_transaction;
    this.vueListHistorique = !this.vueListHistorique;
    this.viewGenerationRecuPaiement = !this.viewGenerationRecuPaiement;
    this.numero_recu = this.range(0, 50);
    this.nom = item.nom;
    this.prenom = item.prenoms;
    this.selectedPaymentType= item.type_demande;
    this.numero_secu = item.numero_secure;
    let montant = item.montant.split('FCFA');
    let split_montant = montant[0] + montant[1];
    let montant_without_space = split_montant.split(' ');
    this.montant_ht = montant_without_space[0] + montant_without_space[1];
    this.montant_ttc = item.montant_total;
    this.paye_par = item.nom + ' ' + item.prenoms;
  }

  closeHistoriqueVente() {
    this.vueListHistorique = !this.vueListHistorique;
    this.viewGenerationRecuPaiement = !this.viewGenerationRecuPaiement;
  }

  printRecu() {
    this.loadingBar.start();
    var data = document.getElementById('recuPaiement');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(`reçu_paiement_${this.nom}_${this.prenom}_${this.numero_secu}.pdf`); // Generated PDF
      this.loadingBar.stop();
    });
  }

  exportExcel() {
    this.listHistorique.type = 'xls';
      const queryParams = undefined; // parameters of URL query
      this._http.post(`${this.API_URL}vente/download`, this.listHistorique, {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      }).subscribe(resp=>{
        //Resp: file stream
        this.downloadFile(resp, 'xls');
      })
    }

    exportPDF() {
      this.listHistorique.type = 'pdf';
        const queryParams = undefined; // parameters of URL query
        this._http.post(`${this.API_URL}vente/download`, this.listHistorique, {
          responseType: "blob",
          headers: new HttpHeaders().append("Content-Type", "application/json")
        }).subscribe(resp=>{
          //Resp: file stream
          this.downloadFile(resp, 'pdf');
        })
      }
  
  /**
   *Create the blob object, and use the browser to open the URL to download
  *@ param data file stream data
  */
  downloadFile(data, type) {
    let contentType;
    //Download type
    if (type === 'xls') {
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else {
      contentType = 'application/pdf';
    }
    //Download type: CSV
    const contentType2 = 'text/csv';
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    //Open a new window to download
    // window.open(url); 
    //Download by dynamically creating a tag
    const a = document.createElement('a');
    const fileName = `mes_ventes`;
    a.href = url;
    // a.download = fileName;
    if (type === 'xls') {
      a.download = fileName + '.xlsx';
    } else {
      a.download = fileName + '.pdf';
    }
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // export to excel
  // exportXls() {
  //   let data = [];
  //   this.listHistorique.ventes.forEach(element => {
  //     data.push(
  //       {
  //         'Date transaction': element.created_at,
  //         'Code transaction': element.code_transaction,
  //         'Nom': element.nom,
  //         'Prénoms': element.prenoms,
  //         'Montant': element.montant,
  //         'Télephone': element.telephone,
  //         'Numéro secu': element.numero_secure,
  //         'Nombre': element.nombre,
  //       }
  //     )
  //   });
  //   this.listHistorique.ventes.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste historique des ventes') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  // }

  // export to pdf 
  // exportPDF() {
  //   var columns = [
  //     {title: "Date", dataKey: "created_at"},
  //     {title: "Nom", dataKey: "nom"}, 
  //     {title: "Prénoms", dataKey: "prenoms"}, 
  //     {title: "Montant", dataKey: "montant"}, 
  //     {title: "Tel", dataKey: "telephone_paiement"}, 
  //     {title: "Numéro S", dataKey: "numero_secure"}, 
  //     {title: "Nombre", dataKey: "nombre"}, 
  //   ];
  //   var doc = new jspdf('p', 'pt');
  //   doc.autoTable(columns, this.listHistorique.ventes, {
  //       columnStyles: {
  //         id: {fillColor: 255}
  //       },
  //       margin: {top: 60},
  //       addPageContent: function(data) {
  //         doc.text("Historique des ventes", 40, 30);
  //       }
  //   });
  //   doc.save('historique_des_ventes.pdf');
  // } 

}
