export class Caissier {
  public  idCaissier : number ;
  public  pass : string ;
  public  dateCreate : string ;
  public  login : string ;
  public  email : string ;
  public  matricule : string ;
  public  nom : string ;
  public  phone : string ;
  public  prenom : string ;
  public  dateUpdate : string ;
  public  entre : string ;
  public  agence : string ;

}
