import {Newprofil} from './newprofil';
import { Profil } from './profil';

export class Utilisateur {

  public   utilisateurid: number ;
  public   profilid: number ;
  public   utilisateurlogin: string ;
  public   utilisateurpassword: string ;
  public   utilisateurphone: string ;
  public   utilisateuremail: string ;
  public   utilisateurname: string ;
  public   utilisateurentreprise: string ;
  public utilisateurdatecreation: any ;
  public   utilisateurstatus: string ;
  public  utilisateurboolean: any ;
  public   utilisateurtoken: string ;
	public profil : string ;
	public profiluser : Profil  ;


}
