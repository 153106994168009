export class Pmurequest {
  public  compte: string ;
  public  from: string ;
  public  to: string ;
  public  oper: string ;
  public  limit: string ;
  public  page: string ;
  public userid : number ;

}
