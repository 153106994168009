import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../../../shared/services/excel.service';
import swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EditAgencyComponent } from './edit-agency/edit-agency.component';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { DetailsAgencyComponent } from './details-agency/details-agency.component';
import {SettingsService} from "../../../shared/services/settings.service";
import {Agency} from "../../../shared/models/agency";
import {AgencyService} from "../../../shared/services/agency.service";
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { GestionAgences } from 'app/shared/models/permissions';
import { ListCaissierComponent } from './list-caissier/list-caissier.component';

@Component({
  selector: 'app-cashier',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss']
})
export class AgencyComponent implements OnInit {
  status: string = 'Desactivé';
  listAgence: any = [];
  agencyData: Agency = new Agency(null, null, null, null, null);
  public response: any;
  loaderText: string;
  API_URL: any;
  filter: any;
  p: number = 1;
  arrayAgence: any = [];
  submitted = false;
  arrayPermissions: any = [];
  listDirection: any = [];
  id_direction: any;
  settingsDirection = {};
  selectedDirection: any;
  permissionsAgence: GestionAgences = new GestionAgences(null, null, null, null, null, null, null)
  formGroup = this.fb.group({
    file: [null, Validators.required]
  });
  constructor(private fb: FormBuilder, private eventEmitter: EventEmitterService, private _settings: SettingsService,private variable: GlobalVariableService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, private http: HttpClient, private _router: Router, private _agence: AgencyService,
  private toastrService: ToastrService, private spinner: NgxSpinnerService, private excelService: ExcelService,
  ) { }

    addAgence(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  detailsAgence = function(data) {
    const modalRef = this.modalService.open(DetailsAgencyComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.agence = data;
  };

  editAgence = function(data) {
    const modalRef = this.modalService.open(EditAgencyComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.agence = data;
  };

  onSelectDirection(value) {
    this.agencyData.direction_id = value.id;
  }

  lockAgence(data){
    this._agence.disableAgency(data.id)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.refresh();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
}

    // Upload file
    onFileChange(ev) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
        this.formGroup.patchValue({
          file: reader.result
        });
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        const dataStringParse = JSON.parse(dataString);
        this.arrayAgence = dataStringParse;
        // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
        // this.setDownload(dataString);
      }
      reader.readAsBinaryString(file);
    }

    addAgenceBulk(content) {
      this.activeModal = this.modalService.
      open(content, { backdrop: 'static', keyboard: false, centered: true  });
    }
  
    // Submit Form
    onSubmit() {
      this.submitted = true;
      // stop here if form is invalid 
      if (this.formGroup.invalid) {
        return;
      }
      this.saveAllAgence(this.arrayAgence);
    }
  
    // Save all agence
  saveAllAgence(data) {
      this._agence.saveAllAgency(data).subscribe((data: {}) => {
        this.response = data;
        if (!this.response.error) {
          swal({
            title: 'CNAM SYSTEM',
            text: this.response.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.excelService.exportAsExcelFile(this.response.data, 'Rapport sauvegarde des agences');
              this.getAllAgence();
            }
          });
        } else {
          swal('CNAM SYSTEM', this.response.message, 'error')
        }
        // !this.response.error ? this.toastr.success(this.response.message) : swal.fire('CNAM SYSTEM', this.response.message, 'error');
      });
    }

  saveAgence() {
      this._agence.createAgency(this.agencyData)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            this.activeModal.close();
            this.agencyData.nom_agence = '';
            this.agencyData.code = '';
            this.agencyData.email = '';
            this.agencyData.telephone = '';
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.activeModal.close();
                this.getAllAgence();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    }

  deleteAgence(data) {
      swal({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas revenir en arrière!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonText: 'Oui, supprimez-le!'
      }).then((result) => {
        if (result.value) {
          this._agence.deleteAgency(data.id)
          .subscribe(
            res => {
              console.log(res);
              if (res.error === false) {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'success',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                }).then((result)  => {
                  if (result) {
                    this.activeModal.close();
                    this.refresh();
                  }
                });
              } else {
                this.spinner.hide();
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }


    // Get all agence
    getAllAgence() {
        this._agence.getAllAgency().subscribe((data: {}) => {
            this.response = data;
            this.listAgence = this.response.data;
        });
    }
  
    getListPermissions() {
      this.arrayPermissions = this.variable.getPermissions();
      const create_agence = this.arrayPermissions.find(x => x.slug === 'creation-dagence');
      const update_agence = this.arrayPermissions.find(x => x.slug === 'modification-dagence');
      const suppression_agence = this.arrayPermissions.find(x => x.slug === 'suppression-dagence');
      const exportation_agence = this.arrayPermissions.find(x => x.slug === 'exportation-de-la-liste-des-agences');
      const creation_masse_agence = this.arrayPermissions.find(x => x.slug === 'creation-en-masse-dagences');
      this.permissionsAgence.creation_agence = create_agence.is_visible;
      this.permissionsAgence.modification_agence = update_agence.is_visible;
      this.permissionsAgence.suppression_agence = suppression_agence.is_visible;
      this.permissionsAgence.exportation_agence = exportation_agence.is_visible;
      this.permissionsAgence.creation_masse_agence = creation_masse_agence.is_visible;
    }

  closeModal() {
    this.activeModal.close();
  }


  // export to excel
  exportXls() {
    let data = [];
    this.listAgence.forEach(element => {
      data.push(
        {
          'Code': element.code,
          'Nom agence': element.nom_prenoms,
          'Email': element.email,
          'Télephone': element.telephone,
          'Direction': element.direction,
          'Statut': element.status ? 'Activé' : 'Desactivé'
        }
      )
    });
    this.listAgence.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste des agences') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

  refresh() {
    this.getAllAgence();
  }

  // Get all direction
  getAllDirection() {
    this._settings.getAllDirection().subscribe((data: {}) => {
      this.response = data;
      this.listDirection = this.response.data;
    });
  }

  showListCaissier(data) {
    const modalRef = this.activeModal = this.modalService.
    open(ListCaissierComponent, { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.caissiers = data;
  }

  //

  ngOnInit(): void {
    this.getAllAgence();
    this.getAllDirection();
    this.getListPermissions();
    this.settingsDirection = {
      singleSelection: true,
      text: 'Sélectionner la direction',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout déselectionner',
      classes: 'myclass custom-class',
      labelKey: 'nom',
      enableSearchFilter: true,
      searchBy: ['nom'],
      searchPlaceholderText: 'Rechercher',
      noDataLabel: 'Aucune direction disponible'
    };
    if (this.eventEmitter.subsVar === undefined) {
      this.eventEmitter.subsVar = this.eventEmitter.
      invokeOnGetAllAgence.subscribe((name: string) => {
        this.getAllAgence();
      });
    }
  }

}
