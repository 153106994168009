import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'app/shared/services/settings.service';
import swal from 'sweetalert2';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';

@Component({
  selector: 'app-edit-utilisateur',
  templateUrl: './edit-utilisateur.component.html',
  styleUrls: ['./edit-utilisateur.component.scss']
})
export class EditUtilisateurComponent implements OnInit {
  @Input() user;
  response: any;
  listAgence = [];
  constructor(private activeModal: NgbActiveModal, private _settings: SettingsService,
    private eventEmitter: EventEmitterService) { }

  ngOnInit() {
    this.getAllAgence();
  }

  onSelectAgence(value){
    this.user.agence_id = value;
  }

  onSelectService(value){
    this.user.service = value;
  }

  closeModal() {
    this.activeModal.close();
  }

  // Get all agence
  getAllAgence() {
    this._settings.getAllAgence().subscribe((data: {}) => {
      this.response = data;
      this.listAgence = this.response.data;
    });
  }

  updateUser() {
    this._settings.updateUsers(this.user)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          this.activeModal.close();
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.eventEmitter.onGetAllUsers();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }


}
