import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Statresult} from '../../shared/models/statresult';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DashboardService} from '../../shared/services/dashboard.service';
import {AppConfirmService} from '../../shared/services/app-confirm/app-confirm.service';
import {AppLoaderService} from '../../shared/services/app-loader/app-loader.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {Servicce} from '../../shared/models/servicce';
import {Wallet} from '../../shared/models/wallet';
import {Granularite} from '../../shared/models/granularite';
import {Statrequest} from '../../shared/models/statrequest';
import {Operation} from '../../shared/models/operation';
import {Detailstat} from '../../shared/models/detailstat';
import { Compte } from 'app/shared/models/compte';
import { Servicse } from '../../shared/models/servicse';
import { Walet } from '../../shared/models/walet';

@Component({
  selector: 'app-detailop',
  templateUrl: './detailop.component.html',
  styleUrls: ['./detailop.component.scss']
})
export class DetailopComponent implements OnInit {

  statdetail : Detailstat = null;

  
  itemServicse : Servicse[] = [] ;

  itemWal : Walet[] = [] ;

  
  mois : string = "month" ;

  granularitejour : Boolean = false ;
  granularitemois : Boolean = false ;
  granulariteannee : Boolean = false ;


  public itemFormDay: FormGroup;
  public itemFormMonth: FormGroup;
  public itemFormYear: FormGroup;

  public service : string = "" ;

  public itemsWallet: Wallet[];
  public itemsServicce: Servicce[];
  public itemsGranularite: Granularite[];

  public dropdownGran : Compte[] =[];

  public selectedCompte : Compte = new Compte ();


  public typegran : string ="date";


  constructor(private dialog: MatDialog,
              private snack: MatSnackBar,
              private dashboardService: DashboardService,
              private confirmService: AppConfirmService,
              private loader: AppLoaderService,
              private cdr: ChangeDetectorRef,
              public router: Router,
              private fb: FormBuilder,
              private datePipe: DatePipe) {
    this.getService();
    this.getWallet();
    this.getGranularity();
    this.getGranulityForDropdown();
    this.getItemGetServces();
  }

  ngOnInit() {

    let c1 : Compte = new Compte();
    c1.libele="JOUR";
    c1.codelibele="day";
    this.selectedCompte=c1 ;
   
    this.getGranulityForDropdown();
    this.itemFormDay = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'debut': [null, Validators.compose([Validators.required])],
      'fin': [null, Validators.compose([Validators.required])],
      'granularite': ['day', Validators.compose([Validators.required])],
    });

    this.itemFormMonth = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'debut': [null, Validators.compose([Validators.required])],
      'fin': [null, Validators.compose([Validators.required])],
      'granularite': ['month', Validators.compose([Validators.required])],

    });

    this.itemFormYear = this.fb.group({
      'services': [null, Validators.compose([Validators.required])],
      'wallet': [null, Validators.compose([Validators.required])],
      'debut': [null, Validators.compose([Validators.required])],
      'fin': [null, Validators.compose([Validators.required])],
      'granularite': ['year', Validators.compose([Validators.required])],

    });

    this.itemFormDay.controls['granularite'].valueChanges.subscribe((value) => {       
      console.log(" granularitelibele " );
      console.log( this.itemFormDay.get('granularite').value);
      let c : Compte = this.itemFormDay.get('granularite').value ;
      console.log(" compte ");
      console.log(c);
      if(c.codelibele=="day"){
        this.typegran="date";
      }
      if(c.codelibele=="month"){
        this.typegran="month";
      }
      if(c.codelibele=="year"){
        this.typegran="number";
      }

    })

    this.itemFormDay.controls['services'].valueChanges.subscribe((value) => {       
      console.log(" services " ) ;
      console.log(this.itemFormDay.get('services').value);
      let srv : Servicse = this.itemFormDay.get('services').value ;
      console.log(" active_wallets " ) ;
     let ws : Walet[] = srv.active_wallets ;
     this.itemWal=[]     ;
      this.itemWal=ws ;
      console.log(this.itemWal);
    })

    this.getService();
    this.getWallet();
    this.getGranularity();
    this.getItemGetServces();
  }


  getItemGetServces() {
    let services : Servicse[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservics"));
    this.itemServicse=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservics"));
  }

  getService(){
    let services : Servicce[] ;
    services = [] ;
    services=JSON.parse(localStorage.getItem("allservice"));
    this.itemsServicce=services;
    console.log("services taille : "+this.itemsServicce.length);
    console.log(localStorage.getItem("allservice"));
  }

  getWallet(){
    let wallets : Wallet[] ;
    wallets = [] ;
    wallets=JSON.parse(localStorage.getItem("allwallet"));
    this.itemsWallet=wallets;
    let w : Wallet ;
    w = new Wallet();
    w.walletlibele="Tous les wallets"
    w.wallettoken="all";
    wallets.push(w);
  }

  getGranularity(){
    let granularites : Granularite[] ;
    granularites = [] ;
    granularites=JSON.parse(localStorage.getItem("allgranularity"));
    this.itemsGranularite=granularites;
  }


  sendRequestOpDetailDay() {
    this.statdetail=null;
    this.service="";
    if (this.itemFormDay.valid) {

      let date = new Date(this.itemFormDay.get("debut").value);
      let date1 = new Date(this.itemFormDay.get("fin").value);
      console.log("debut : "+date+" ; fin  : "+date1);
      let from : string ="";
      let to : string ="";
      if((date.toString()!='Invalid Date')){
        from  = this.itemFormDay.get("debut").value;
      }

      if(date1.toString()!=null){
        to  = this.itemFormDay.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);

      console.log(" date "+newDate);
      console.log(" date1 "+newDate1);

      if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      }else{
        if(newDate1>=newDate){
          console.log(" debut : "+this.datePipe.transform(newDate,"dd/MM/yyyy"));
          console.log(" fin : "+this.datePipe.transform(newDate1,"dd/MM/yyyy"));
          let req : Statrequest = new Statrequest();

          req.from=this.datePipe.transform(newDate,"dd/MM/yyyy");
          req.to=this.datePipe.transform(newDate1,"dd/MM/yyyy");
          req.granularity="day";

          let wall : Walet = this.itemFormDay.get("wallet").value;
          let serv : Servicse = this.itemFormDay.get("services").value; 
          req.service_token=serv.token;
          req.wallet_token=wall.authentication_token;



          //this.service=serv.servicelibele;


          this.loader.open();
          console.log(req);
          if(req!=null){
            this.dashboardService.sendOperationDetail(req)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      console.log(data);
                      let ts : Detailstat = new Detailstat();
                      ts=data.body;
                      this.statdetail = ts ;
                      if(this.statdetail && this.statdetail.fraisdeservngser==='Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.fraisdeservngser="0";
                      }
                      if(this.statdetail && this.statdetail.soldeaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.soldeaviraupart="0";
                      }
                      //Solde à virer au partenaire
                      if(this.statdetail && this.statdetail.montantaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.montantaviraupart="0";
                      }
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }
                    case 201: {
                      console.log(data);
                      let ts : Detailstat = new Detailstat();
                      ts=data.body;
                      this.statdetail = ts ;
                      if(this.statdetail && this.statdetail.fraisdeservngser==='Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.fraisdeservngser="0";
                      }
                      if(this.statdetail && this.statdetail.soldeaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.soldeaviraupart="0";
                      }
                      //Solde à virer au partenaire
                      if(this.statdetail && this.statdetail.montantaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.montantaviraupart="0";
                      }
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }

                  }
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch(error.status){

                    default: {
                      this.loader.close();
                      this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                      break;
                    }
                  }
                }
              )
          }
        }else{
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }


    }

  }

  sendRequestOpDetailMonth() {
    this.statdetail=null;
    console.log(this.itemFormDay.value);
    if (this.itemFormDay.valid) {

      let date = new Date(this.itemFormDay.get("debut").value);
      let date1 = new Date(this.itemFormDay.get("fin").value);

      console.log("debut : "+date+" ; fin  : "+date1);

      let from : string ="";
      let to : string ="";


      if((date.toString()!='Invalid Date')){
        from  = this.itemFormDay.get("debut").value;
      }

      if(date1.toString()!=null){
        to  = this.itemFormDay.get("fin").value;
      }

      let newDate = new Date(from);

      let newDate1 = new Date(to);


      console.log(" date "+newDate);
      console.log(" date1 "+newDate1);

      if((newDate.toDateString()==="Invalid Date") || (newDate1.toDateString()==="Invalid Date")){
        this.snack.open('Format de date invalide ', 'OK', { duration: 4000 });
      }else{
        if(newDate1>=newDate){
          console.log(" debut : "+this.datePipe.transform(newDate,"MM/yyyy"));
          console.log(" fin : "+this.datePipe.transform(newDate1,"MM/yyyy"));
          let req : Statrequest = new Statrequest();

          req.from=this.datePipe.transform(newDate,"MM/yyyy");
          req.to=this.datePipe.transform(newDate1,"MM/yyyy");

          /*
          let wall : Wallet = this.itemFormDay.get("wallet").value;
          let serv : Servicce = this.itemFormDay.get("services").value;
          this.service=serv.servicelibele;


          
          req.service_token=serv.servicetoken;
          req.wallet_token=wall.wallettoken;
          */

          req.granularity="month";


          let wall : Walet = this.itemFormDay.get("wallet").value;
          let serv : Servicse = this.itemFormDay.get("services").value; 
          req.service_token=serv.token;
          req.wallet_token=wall.authentication_token;

          this.loader.open();
          console.log(req);
          if(req!=null){
            this.dashboardService.sendOperationDetail(req)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      console.log(data);
                      let ts : Detailstat = new Detailstat();
                      ts=data.body;
                      this.statdetail = ts ;
                      if(this.statdetail && this.statdetail.fraisdeservngser==='Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.fraisdeservngser="0";
                      }
                      if(this.statdetail && this.statdetail.soldeaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.soldeaviraupart="0";
                      }
                      //Solde à virer au partenaire
                      if(this.statdetail && this.statdetail.montantaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.montantaviraupart="0";
                      }
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }
                    case 201: {
                      console.log(data);
                      let ts : Detailstat = new Detailstat();
                      ts=data.body;
                      this.statdetail = ts ;
                      if(this.statdetail && this.statdetail.fraisdeservngser==='Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.fraisdeservngser="0";
                      }
                      if(this.statdetail && this.statdetail.soldeaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.soldeaviraupart="0";
                      }
                      //Solde à virer au partenaire
                      if(this.statdetail && this.statdetail.montantaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.montantaviraupart="0";
                      }
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }

                  }
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch(error.status){

                    default: {
                      this.loader.close();
                      this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                      break;
                    }
                  }

                }

              )
          }

        }else{
          console.log("false");
          this.snack.open('Intervale de date invalide ', 'OK', { duration: 4000 });
        }
      }


    }
  }

  sendRequestOpDetailYear() {
    this.statdetail=null;
    console.log(this.itemFormDay.value);
    if (this.itemFormDay.valid) {

      let req : Statrequest = new Statrequest();

      if(this.itemFormDay.get("fin").value>=this.itemFormDay.get("debut").value){
        req.from=this.itemFormDay.get("debut").value;
        req.to=this.itemFormDay.get("fin").value;

        /*
        let wall : Wallet = this.itemFormDay.get("wallet").value;
        let serv : Servicce = this.itemFormDay.get("services").value;
        req.granularity="year";
        req.service_token=serv.servicetoken;
        req.wallet_token=wall.wallettoken;
        */

        req.granularity="year";

        let wall : Walet = this.itemFormDay.get("wallet").value;
        let serv : Servicse = this.itemFormDay.get("services").value; 
        req.service_token=serv.token;
        req.wallet_token=wall.authentication_token;


        this.loader.open();
        if(req!=null){
          if(req!=null){
            this.dashboardService.sendOperationDetail(req)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      console.log(data);
                      let ts : Detailstat = new Detailstat();
                      ts=data.body;
                      this.statdetail = ts ;
                      if(this.statdetail && this.statdetail.fraisdeservngser==='Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.fraisdeservngser="0";
                      }
                      if(this.statdetail && this.statdetail.soldeaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.soldeaviraupart="0";
                      }
                      //Solde à virer au partenaire
                      if(this.statdetail && this.statdetail.montantaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.montantaviraupart="0";
                      }
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }
                    case 201: {
                      console.log(data);
                      let ts : Detailstat = new Detailstat();
                      ts=data.body;
                      this.statdetail = ts ;
                      if(this.statdetail && this.statdetail.fraisdeservngser==='Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.fraisdeservngser="0";
                      }
                      if(this.statdetail && this.statdetail.soldeaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.soldeaviraupart="0";
                      }
                      //Solde à virer au partenaire
                      if(this.statdetail && this.statdetail.montantaviraupart==='-Infinity'){
                        console.log(" it's Infinity ");
                        this.statdetail.montantaviraupart="0";
                      }
                      this.loader.close();
                      this.snack.open('Succes !', 'OK', { duration: 4000 });
                      break;
                    }

                  }
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch(error.status){

                    default: {
                      this.loader.close();
                      this.snack.open('Une erreur statistic!', 'OK', { duration: 4000 })
                      break;
                    }
                  }
                }
              )
          }

        }
      }else{
        this.snack.open('saisie d année incorrecte !', 'OK', { duration: 4000 });

      }

    }
  }


  getGranulityForDropdown(){
    let cptes : Compte[];
    cptes=[];
    let c : Compte = new Compte();
    c.libele="JOUR";
    c.codelibele="day";

    let c1 : Compte = new Compte();
    c1.libele="MOIS";
    c1.codelibele="month";

    let c2 : Compte = new Compte();
    c2.libele="ANNEE";
    c2.codelibele="year";

    cptes.push(c);
    cptes.push(c1);
    cptes.push(c2);
    this.dropdownGran= cptes;
  }

  sendRequestOpDetail(){
    this.selectedCompte=this.itemFormDay.get('granularite').value ;

    if (this.itemFormDay.valid) {
      if(this.selectedCompte!=null && (this.selectedCompte.codelibele=="month" || this.selectedCompte.codelibele=="day" ) ){

        if(this.selectedCompte.codelibele=="month"){
          console.log("month");
          console.log(this.selectedCompte.codelibele);
          this.sendRequestOpDetailMonth();
        }else if(this.selectedCompte.codelibele=="day"){
          console.log(" day");
          console.log(this.selectedCompte.codelibele);
          this.sendRequestOpDetailDay();
        }
      }else if(this.selectedCompte!=null && (this.selectedCompte.codelibele=="year")){
        console.log(" year");
        console.log(this.selectedCompte.codelibele);
        this.sendRequestOpDetailYear();
      }
    }
  }

  }
