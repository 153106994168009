import { Component, OnInit } from '@angular/core';
import { GestionProfils, GestionUtilisateurs } from 'app/shared/models/permissions';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { GestionDirections, GestionPisteAudite, GestionInspections } from '../../../shared/models/permissions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  urlView: any;
  arrayPermissions: any;
  permissionsSettingsUtilisateurs: GestionUtilisateurs = new GestionUtilisateurs(null, null, null, null, null, null, null, null);
  permissionsSettingsDirections: GestionDirections = new GestionDirections(null, null, null, null, null, null, null);
  permissionsSettingsInspections: GestionInspections = new GestionInspections(null, null, null, null, null, null, null);
  permissionsSettingsPisteAudit: GestionPisteAudite = new GestionPisteAudite(null);
  permissionsSettingsProfil: GestionProfils = new GestionProfils(null, null, null, null, null, null);
  constructor(private variable: GlobalVariableService) { }

  ngOnInit() {
    this.show('piste-audit');
    this.getListPermissions();
  }

  getListPermissions() {
    this.arrayPermissions = this.variable.getPermissions();
    const visualisation_liste_utilisateurs = this.arrayPermissions.find(x => x.slug === 'visualisation-de-la-liste-utilisateurs');
    const visualisation_liste_profils = this.arrayPermissions.find(x => x.slug === 'visualisation-de-la-liste-des-profils');
    const visualisation_lier_profil = this.arrayPermissions.find(x => x.slug === 'lier-un-utilisateur-a-un-profil');
    const visualisation_liste_directions = this.arrayPermissions.find(x => x.slug === 'visualisation-de-la-liste-des-directions');
    const visualisation_liste_inspections = this.arrayPermissions.find(x => x.slug === 'visualisation-de-la-liste-des-inspections');
    const visualisation_liste_piste_audite = this.arrayPermissions.find(x => x.slug === 'visualisation-piste-audit');
    this.permissionsSettingsUtilisateurs.visualisation_liste_utilisateur = visualisation_liste_utilisateurs.is_visible;
    this.permissionsSettingsProfil.visualisation_liste_profil = visualisation_liste_profils.is_visible;
    this.permissionsSettingsUtilisateurs.lier_utilisateur = visualisation_lier_profil.is_visible;
    this.permissionsSettingsDirections.visualisation_liste_direction = visualisation_liste_directions.is_visible;
    this.permissionsSettingsInspections.visualisation_liste_inspection = visualisation_liste_inspections.is_visible;
    this.permissionsSettingsPisteAudit.visualisation_piste_audit = visualisation_liste_piste_audite.is_visible;
  }


  show(url) {
    // this.router.navigate([url]);
    this.urlView = url;
  }

}
