import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {Utilisateur} from '../../shared/models/utilisateur';
import {DashboardService} from '../../shared/services/dashboard.service';
import {AppConfirmService} from '../../shared/services/app-confirm/app-confirm.service';
import {AppLoaderService} from '../../shared/services/app-loader/app-loader.service';
import {CreateuserComponent} from './createuser/createuser.component';
import {UpdateuserComponent} from './updateuser/updateuser.component';

@Component({
  selector: 'app-cpteuser',
  templateUrl: './cpteuser.component.html',
  styleUrls: ['./cpteuser.component.scss']
})
export class CpteuserComponent implements OnInit {

  public items: any[];
  temp = [];
  rows = [];

  columns = [];

  creercompteutilisateur : boolean = false ;
  modifiercompteutilisateur : boolean = false ;
  supprimercompteutilisateur : boolean = false ;


  public getItemSub: Subscription;
  dataSource: MatTableDataSource<Utilisateur> = new MatTableDataSource([]);

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    this.creercompteutilisateur=this.verifyMyMenu("creercompteutilisateur");
    this.modifiercompteutilisateur=this.verifyMyMenu("modifiercompteutilisateur");
    this.supprimercompteutilisateur=this.verifyMyMenu("supprimercompteutilisateur");
    this.getItems();
  }





  getItems() {

    this.getItemSub = this.dashboardService.findallusers()
      .subscribe(data => {
        console.log("resultat");
        console.log(data);
        this.items = this.temp = data;
        this.dataSource = new MatTableDataSource(this.items);

      });

  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


  convertDateHeure(d: any) {
    /*
    if (d != null) {
      let value: string = d.toString();
      return value.substring(11, 16);
    } else {
      return null;
    }
    */
    let date : string = this.convertToDate(d);
    let heur : string = this.convertToHeure(d);
    return date+" "+heur;

  }


  openAddPup(data: any){
    let dialogRef: MatDialogRef<any> = this.dialog.open(CreateuserComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        this.loader.open();
        let val : Utilisateur = res ;

        console.log(localStorage.getItem('curentuser'));
        let u = new Utilisateur() ;
        u=JSON.parse(localStorage.getItem("curentuser"));

        if(u!=null){
          val.profilid=u.profilid;
          console.log(" profil id ");
          console.log(val);
          this.dashboardService.createUser(val).subscribe(data => {
              switch (data.status) {
                case 200: {
                  this.getItems();
                  this.loader.close();
                  this.snack.open('utilisateur crée avec succes !', 'OK', { duration: 4000 })
                  break;
                }
                case 201: {

                  this.getItems();
                  this.loader.close();
                  this.snack.open('utilisateur crée avec succes', 'OK', { duration: 4000 })
                  break;
                }
                case 409: {
                  this.loader.close();
                  this.snack.open('Cet email est deja utilise!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }
            },
            error => {
              console.log('data');
              console.log(error);
              switch(error.status){
                case 400: {
                  this.loader.close();
                  this.snack.open('Mauvaise requete!', 'OK', { duration: 4000 })
                  break;
                }
                case 409: {
                  this.loader.close();
                  this.snack.open('Cet email est deja utilise!', 'OK', { duration: 4000 })
                  break;
                }
                case 500: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
        }
      });
  }

  openPopUp(data: any = {}, isNew?) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(UpdateuserComponent, {
      width: '720px',
      disableClose: true,
      data: { title: '', payload: data }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        this.loader.open();
        let val : Utilisateur = res ;

        console.log(localStorage.getItem('curentuser'));
        let u = new Utilisateur() ;
        u=JSON.parse(localStorage.getItem("curentuser"));

        if(u!=null){
          this.dashboardService.updateUtilisateur(val).subscribe(data => {
              switch (data.status) {
                case 200: {
                  this.getItems();
                  this.loader.close();
                  this.snack.open('utilisateur mis a jour avec succes !', 'OK', { duration: 4000 })
                  break;
                }
                case 201: {

                  this.getItems();
                  this.loader.close();
                  this.snack.open('utilisateur mis a jour', 'OK', { duration: 4000 })
                  break;
                }

                case 500: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }
            },
            error => {
              console.log('data');
              console.log(error);
              switch(error.status){
                case 400: {
                  this.loader.close();
                  this.snack.open('Mauvaise requete!', 'OK', { duration: 4000 })
                  break;
                }

                case 500: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }

            });
        }

      })
  }


  convertToHeure(d: any) {
    if (d != null) {
      let value: string = d.toString();
      return value.substring(11, 16);
    } else {
      return null;
    }
  }


  convertToDate(d: any) {
    if (d != null) {
      let value: string = d.toString();
      return value.substring(0, 10);
    } else {
      return null;
    }
  }

  deleteItem(row) {
    this.confirmService.confirm({message: `Voulez vous supprimer : ${row.utilisateurlogin}?`})
      .subscribe(res => {
        if (res) {
          this.loader.open();
          this.dashboardService.deleteUser(row.utilisateurid)
            .subscribe(data => {
                switch (data.status) {
                  case 200: {
                    console.log('data');
                    console.log(data);

                    this.loader.close();
                    this.snack.open('utilisateur Supprimé!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.loader.close();
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.loader.close();
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }
                this.getItems();
              },
              error => {
                console.log('data');
                console.log(error);
                switch (error.status) {
                  case 400: {
                    this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
                    break;
                  }
                  case 500: {
                    this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                    break;
                  }
                  default: {
                    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                    break;
                  }
                }
                this.loader.close();
                this.snack.open('Erreur!', 'OK', { duration: 4000 })
              });
        }

      })
  }





  verifyMyMenu(value : string){
    console.log(localStorage.getItem(value.trim()));
    if(localStorage.getItem(value.trim())!=null){
      return true ;
    }else{
      return false ;
    }
  }

}
