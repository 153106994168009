import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreerprofileComponent } from './creerprofile.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatSelectModule, MatInputModule, MatIconModule, MatCardModule, MatMenuModule, MatButtonModule, MatChipsModule, MatListModule, MatTooltipModule, MatDialogModule, MatSnackBarModule, MatSlideToggleModule, MatCheckboxModule } from '@angular/material';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    SharedModule,
  ],
  declarations: [CreerprofileComponent]
})
export class CreerprofileModule { }
