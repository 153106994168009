import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ResetpasswordComponent} from './resetpassword.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule, MatCardModule, MatCheckboxModule, MatIconModule, MatInputModule, MatProgressBarModule} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';


export const routes = [
  { path: ':id/:key', component: ResetpasswordComponent }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    FlexLayoutModule,
    RouterModule.forChild(routes),

  ],
  declarations: [ResetpasswordComponent]
})
export class ResetpasswordModule { }
