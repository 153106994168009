import { Fonctionalite } from "./fonctionalite";

export class Profil {
     
      profilid : number;
	  profillibele : string;
	  profildatecreatiion : any ;
	  profilenable : boolean ;
	  fonctionnalites : Fonctionalite [];


}
