import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {Utilisateur} from '../../models/utilisateur';

@Injectable({
  providedIn: 'root'
})
export class AuthpasswdGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let u = new Utilisateur() ;
    u=JSON.parse(localStorage.getItem("curentuser"));
    if (u!=null) {
      this.router.navigate(['/seconnecter']);
      return false
    }
    return true;
  }
}
