import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Utilisateur } from '../../shared/models/utilisateur';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { matchingPasswords } from '../../shared/models/app-validator';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../shared/services/dashboard.service';
import { AppConfirmService } from '../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from '../../shared/services/app-loader/app-loader.service';
import { Caissier } from '../../shared/models/caissier';
import { Passwdform } from '../../shared/models/passwdform';
import { Apiresponse } from '../../shared/models/apiresponse';
import { Caissiere } from 'app/shared/models/caissiere';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  user: Caissiere;
  passwordForm: FormGroup;

  constructor(private router: ActivatedRoute, public formBuilder: FormBuilder, private dashboard: DashboardService, public snackbar: MatSnackBar,
    private dialog: MatDialog,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private snack: MatSnackBar) { }

  ngOnInit() {
    this.getUserParameter();

    // Formulaire de modification de mot de passe
    this.passwordForm = this.formBuilder.group({
      'oldpassword': new FormControl('', Validators.required),
      'newpassword': new FormControl('', Validators.required),
      'confirmpassword': new FormControl('', Validators.required)
    },
      {
        validator: matchingPasswords('newpassword', 'confirmpassword')

      });
  }

  getUserParameter() {
    this.user = new Caissiere();
    this.user = JSON.parse(localStorage.getItem("curentuser"));
    console.log(this.user);
  }


  // Modifier le mot de passe
  public submit(values: Object): void {
    if (this.passwordForm.valid) {

      let oldpassword: string = this.passwordForm.get('oldpassword').value;
      let newpassword = this.passwordForm.get('confirmpassword').value;
      let u = new Caissiere();
      u = JSON.parse(localStorage.getItem("curentuser"));
      let userform: Passwdform = new Passwdform();
      userform.id = u.caissiereid;
      userform.oldpassword = oldpassword;
      userform.newpassword = newpassword;
      this.loader.open();

      this.dashboard.updatePasswordByForm(userform).subscribe(
        data => {
          console.log(data);
          switch (data.status) {
            case 200: {
              this.loader.close();
              // this.snack.open('Mot de passe  mis à jour !', 'OK', { duration: 4000 })
              this.openDialog();
              this.passwordForm.reset();
              break;
            }
          }

        },

        error => {
          console.log(error);
          switch (error.status) {

            default: {
              this.snack.open('Ancien mot de passe incorrecte ', 'OK', { duration: 4000 })
              break;
            }
          }
          this.loader.close();
        }
      );

    }

  }


  public getuser(id: number) {
    this.dashboard.getUserById(id).subscribe(
      data => {
        console.log(data);
        let u: Utilisateur = data;
        localStorage.setItem('currentuser', JSON.stringify(u));
      }
    );
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: "la modification de votre mot de passe  à bien été enregistré"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }

}
