
export class User {
  public nom_prenoms: string;
  public code: string;
  public email: string;
  public telephone: string;
  public agence_id: string;
  public service: string;
  constructor(nom_prenoms: string, code: string, email: string, telephone: string, agence_id: string, service: string) {
    this.nom_prenoms = nom_prenoms;
    this.code = code;
    this.email = email;
    this.telephone = telephone;
    this.agence_id = agence_id;
    this.service = service;
  }
}

export class PermissionsUser {
  public title: string;
  public menus_id: string;
  constructor(title: string, menus_id: string) {
    this.title = title;
    this.menus_id = menus_id;
  }
}


export class Profil {
  title: string;
  permissions_id: any = [];
  constructor(title: string, permissions_id: any) {
      this.title = title;
      this.permissions_id = permissions_id;
  }
}

