export class Credit {
	public montant: string;
	public emetteur: string;
	public moyen: string;
  
  
	constructor(amount:string,emetteur:string,moyen:string) {
	  this.montant = amount;
	  this.emetteur = emetteur;
	  this.moyen = moyen;
	}
  }