import { Agence } from "./agence";

export class Usercaisse {

    public caissiereid: number;
    public caissierelogin: String;
    public caissierepassword: String;
    public agence : Agence ;
    public caissiereemail : string ;
    public caissieretelephone : string ;
    public status : string ; 

}
