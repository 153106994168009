import {Transactionitem} from './transactionitem';
import {Transactiontotal} from './transactiontotal';

export class Statresult {

  constructor(){
    this.total = new Transactiontotal();
    this.transactions = [];
  }
  transactions : Transactionitem[] ;
  total : Transactiontotal ;
}
