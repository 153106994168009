import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { SecuService } from '../../../shared/services/secu.service';
import swal from 'sweetalert2';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'app-recherche-numero-secu',
  templateUrl: './recherche-numero-secu.component.html',
  styleUrls: ['./recherche-numero-secu.component.scss']
})
export class RechercheNumeroSecuComponent implements OnInit {
  numero_secu: any;
  response: any;
  token: any;
  httpOptions;
  user: any;
  constructor(private router: Router, private _auth: AuthenticationService, private _secu: SecuService
  , private variable: GlobalVariableService, private configService: ConfigService) {
   }

  ngOnInit() {
    this.user = JSON.parse(this.variable.getUser());
    this.login();
  }

  goTo(url) {
    this.router.navigate([url]);
  }

  searchNumeroSecu() {
    const data = {
      'secu_number': this.numero_secu
    }
    this._secu.getDetailsAssure(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.variable.setDetailsSecu(res.data);
          this.router.navigate(['/details-secu', { numero_secu: this.numero_secu }]);
        }
        else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  login() {
    const data = {
        "auth": {
            "api_key": this.configService.get('API_KEY_CNAM'),
            "api_secret": this.configService.get('API_SECRET_CNAM')
        }
    };
    this._auth.loginCNAM(data)
      .subscribe(
        res => {
          if (res.api_code === 200) {
            this.token = res.auth_token;
            // Http Options
            this.httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
              })
            };
          }
          else {
            console.log('error');
          }
        },
        err => {
          console.log(err);
        }
    )
  }

}
