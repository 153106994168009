export class Caissiere {

    public  venteid : number ;
    public  ventetransaction : string ;
    public  ventedatecreation : Date;
    public  ventedatenaiss : Date;
    public  ventenom : string ;
    public  venteprenom : string ;
    public  ventedatenaissance : string ;
    public  ventelieunaiss : string ;
    public  ventenommere : string ;
    public  venteprenommere : string ;
    public  ventetypedemande : string ;
    public  venteemail : string ;
    public  ventetelephone : string ;
    public  ventelienrecu : string ;
    public  venteenable : boolean ;
    public  ventenombre : number ;
    public  ventemontantunitaire : number ;
    public  ventemotanttotal : number ;
    public  caissiereid   : number ;
    public ventecodesecret : string ;
    public auth_token : string ;
    public gateway_url : string ;
    public operation_token : string ;
}
