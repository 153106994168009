import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { SettingsService } from 'app/shared/services/settings.service';

@Component({
  selector: 'app-details-cashier',
  templateUrl: './details-cashier.component.html',
  styleUrls: ['./details-cashier.component.scss']
})
export class DetailsCashierComponent implements OnInit {
  @Input() cashier;
  response: any;
  listAgence: any = [];
  constructor(private activeModal: NgbActiveModal, private _settings: SettingsService,
    private eventEmitter: EventEmitterService) { }

  ngOnInit() {
    this.getAllAgence();
  }

    // Get all agence
    getAllAgence() {
        this._settings.getAllAgence().subscribe((data: {}) => {
            this.response = data;
            this.listAgence = this.response.data;
        });
    }

  onSelectService(value){
    this.cashier.service = value;
  }

  onSelectAgence(value){
    this.cashier.agence_id = value;
  }

  closeModal() {
    this.activeModal.close();
  }


}
