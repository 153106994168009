import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import {DashboardService} from '../../services/dashboard.service';
import {Router} from '@angular/router';
import swal from 'sweetalert2';
import { GlobalVariableService } from '../../services/global-variable.service';
import { VueGlobale } from 'app/shared/models/permissions';

@Component({
  selector: 'app-header-cnam-profile',
  templateUrl: './header-cnam-profile.component.html',
  styleUrls: ['./header-cnam-profile.component.scss']
})
export class HeaderCnamProfileComponent implements OnInit {
  @Input() notificPanel;
  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]

  urlView: any;
  public egretThemes;
  permissionsModule: VueGlobale = new VueGlobale(null, null, null);
  public layoutConf: any;
  public nom_prenoms: any;
  user: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private dashboardservice : DashboardService,
    public router: Router,
    private variable: GlobalVariableService
  ) {}
  ngOnInit() {
    this.user = JSON.parse(this.variable.getUser());
    this.nom_prenoms = this.user.nom_prenoms;
    let url = window.location.href.split('/');
    this.urlView = '/' + url[4];
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }
  setLang(e) {
    console.log(e)
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }
  
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  sign_out() {
      swal({
          title: 'Êtes-vous sûr?',
          text: 'Vous allez être déconnecter',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Non',
          confirmButtonText: 'Oui'
      }).then((result)  => {
          if (result.value) {
              this.router.navigate(['/portail']);
          }
      });
  }


  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true});
    }
    console.log('compact');

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true})
  }

  goTo(url) {
    if(url === '/historique') {
        swal({
            title: 'CNAM SYSTEM',
            text: 'Implémentation en cours',
            type: 'info',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
    } else {
        this.router.navigate([url]);
        this.urlView = url;
    }
  }


}
