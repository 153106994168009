import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'app/shared/models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../../../shared/services/excel.service';
import swal from 'sweetalert2';
import { SettingsService } from 'app/shared/services/settings.service';
import { FormBuilder, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EditManagerComponent } from './edit-manager/edit-manager.component';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { DetailsManagerComponent } from './details-manager/details-manager.component';
import { Manager } from '../../../shared/models/manager';
import { GestionnaireService } from '../../../shared/services/gestionnaire.service';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { GestionGestionnaires } from '../../../shared/models/permissions';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {
  status: string = 'Desactivé';
  selectedAgence = [];
  listAgence: any;
  managerData: Manager = new Manager(null, null, null, null, null);
  public response: any;
  listManager = [];
  loaderText: string;
  API_URL: any;
  filter: any;
  p: number = 1;
  arrayManager: any = [];
  arrayPermissions: any = [];
  permissionsManager: GestionGestionnaires = new GestionGestionnaires(null, null, null, null, null, null, null);
  submitted = false;
  formGroup = this.fb.group({
    file: [null, Validators.required]
  });
  constructor(private fb: FormBuilder, private eventEmitter: EventEmitterService, private variable: GlobalVariableService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, private http: HttpClient, private _router: Router, private _manager: GestionnaireService,
  private toastrService: ToastrService, private spinner: NgxSpinnerService, private excelService: ExcelService,
  ) { }

  addManager(content) {
    this.activeModal = this.modalService.
    open(content, { backdrop: 'static', keyboard: false, centered: true  });
  }

  detailsManager = function(data) {
    const modalRef = this.modalService.open(DetailsManagerComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.manager = data;
  };

  editManager = function(data) {
    const modalRef = this.modalService.open(EditManagerComponent,
    { backdrop: 'static', keyboard: false, centered: true  });
    modalRef.componentInstance.manager = data;
  };

  lockGestionnaire(data){
    this._manager.disableGestionnaire(data.id)
    .subscribe(
      res => {
        console.log(res);
        if (res.error === false) {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.refresh();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
}

    // Upload file
    onFileChange(ev) {
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.onload = (event) => {
        this.formGroup.patchValue({
          file: reader.result
        });
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        const dataString = JSON.stringify(jsonData);
        const dataStringParse = JSON.parse(dataString);
        this.arrayManager = dataStringParse;
        // document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
        // this.setDownload(dataString);
      }
      reader.readAsBinaryString(file);
    }
  
    addManagerBulk(content) {
      this.activeModal = this.modalService.
      open(content, { backdrop: 'static', keyboard: false, centered: true  });
    }
  
    // Submit Form
    onSubmit() {
      this.submitted = true;
      // stop here if form is invalid 
      if (this.formGroup.invalid) {
        return;
      }
      this.saveAllGestionnaire(this.arrayManager);
    }
  
    // Save all gestionnaire
  saveAllGestionnaire(data) {
      this._manager.saveAllGestionnaire(data).subscribe((data: {}) => {
        this.response = data;
        if (!this.response.error) {
          swal({
            title: 'CNAM SYSTEM',
            text: this.response.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.excelService.exportAsExcelFile(this.response.data, 'Rapport sauvegarde des gestionnaires');
              this.getAllManager();
            }
          });
        } else {
          swal('CNAM SYSTEM', this.response.message, 'error')
        }
        // !this.response.error ? this.toastr.success(this.response.message) : swal.fire('CNAM SYSTEM', this.response.message, 'error');
      });
    }

  saveGestionnaire() {
      this._manager.createGestionnaire(this.managerData)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            this.activeModal.close();
            this.managerData.nom_prenoms = '';
            this.managerData.code = '';
            this.managerData.email = '';
            this.managerData.telephone = '';
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.activeModal.close();
                this.getAllManager();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    }

  deleteManager(data) {
      swal({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas revenir en arrière!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonText: 'Oui, supprimez-le!'
      }).then((result) => {
        if (result.value) {
          this._manager.deleteGestionnaire(data.id)
          .subscribe(
            res => {
              console.log(res);
              if (res.error === false) {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'success',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                }).then((result)  => {
                  if (result) {
                    this.activeModal.close();
                    this.refresh();
                  }
                });
              } else {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }

  onSelectService(value){
    this.managerData.service = value;
  }

  closeModal() {
    this.activeModal.close();
  }

  getListPermissions() {
    this.arrayPermissions = this.variable.getPermissions();
    const create_gestionnaire = this.arrayPermissions.find(x => x.slug === 'creation-dun-gestionnaire');
    const update_gestionnaire = this.arrayPermissions.find(x => x.slug === 'modification-de-gestionnaire');
    const suppression_gestionnaire = this.arrayPermissions.find(x => x.slug === 'suppression-de-gestionnaire');
    const exportation_gestionnaire = this.arrayPermissions.find(x => x.slug === 'exportation-de-la-liste-des-gestionnaires');
    const creation_masse_gestionnaire = this.arrayPermissions.find(x => x.slug === 'creation-en-masse-des-gestionnaires');
    this.permissionsManager.creation_gestionnaire = create_gestionnaire.is_visible;
    this.permissionsManager.modification_gestionnaire = update_gestionnaire.is_visible;
    this.permissionsManager.suppression_gestionnaire = suppression_gestionnaire.is_visible;
    this.permissionsManager.exportation_gestionnaire = exportation_gestionnaire.is_visible;
    this.permissionsManager.creation_masse_gestionnaire = creation_masse_gestionnaire.is_visible;
  }


  // export to excel
  exportXls() {
    let data = [];
    this.listManager.forEach(element => {
      data.push(
        {
          'Code': element.code,
          'Nom & Prénoms': element.nom_prenoms,
          'Email': element.email,
          'Service': element.service,
          'Télephone': element.telephone,
          'Statut': element.status ? 'Activé' : 'Desactivé'
        }
      )
    });
    this.listManager.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste des gestionnaires') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

  // Get all gestionnaire
  getAllManager() {
    this._manager.getAllGestionnaire().subscribe((data: {}) => {
      this.response = data;
      this.listManager = this.response.data;
    });
  }

  refresh() {
    this.getAllManager();
  }

  ngOnInit(): void {
    this.getListPermissions();
    this.getAllManager();
    if (this.eventEmitter.subsVar === undefined) {
      this.eventEmitter.subsVar = this.eventEmitter.
      invokeOnGetAllManager.subscribe((name: string) => {
        this.getAllManager();
      });
    }
  }

}
