import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { SettingsService } from 'app/shared/services/settings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-inspection',
  templateUrl: './edit-inspection.component.html',
  styleUrls: ['./edit-inspection.component.scss']
})
export class EditInspectionComponent implements OnInit {
  @Input() inspection;
  constructor(private activeModal: NgbActiveModal,
    private _settings: SettingsService, private eventEmitter: EventEmitterService) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

  updateInspection() {
    this._settings.updateInspection(this.inspection)
    .subscribe(
      res => {
        if (res.error === false) {
          this.activeModal.close();
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result)  => {
            if (result) {
              this.activeModal.close();
              this.eventEmitter.onGetAllInspection();
            }
          });
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

}
