export class Detailstat {
  public   montantrecu: string ;
  public   montantenvoye: string ;
  public   fraisserviceop: string ;
  public   montantarecevdeop: string ;
  public   virementrecuop: string ;
  public   soldearecevdeop: string ;
  public   fraisdeservngser: string ;
  public   montantaviraupart: string ;
  public   montantviraupart: string ;
  public   soldeaviraupart: string ;
}
