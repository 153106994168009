import {Component, OnInit, ViewChild} from '@angular/core';
import {MatButton, MatDialog, MatProgressBar, MatSnackBar} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../shared/services/dashboard.service';
import {AppConfirmService} from '../../shared/services/app-confirm/app-confirm.service';
import {AppLoaderService} from '../../shared/services/app-loader/app-loader.service';
import {Utilisateur} from '../../shared/models/utilisateur';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;

  signinForm: FormGroup;

  constructor(public formBuilder: FormBuilder, public snackBar: MatSnackBar , public router:Router , public dasboardservice : DashboardService,
              private dialog: MatDialog,
              private snack: MatSnackBar,
              private confirmService: AppConfirmService,
              private loader: AppLoaderService,
              private fb: FormBuilder) { }

  ngOnInit() {
    localStorage.removeItem('curentuser');
    this.signinForm = this.fb.group({
      username :['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  signin() {
    this.submitButton.disabled = true;
    this.progressBar.mode = 'indeterminate';
    const signinData = this.signinForm.value
    console.log(signinData);
    let path : string ;
    console.log(path);

    this.loader.open();
    this.dasboardservice.verifyemail(this.signinForm.controls['username'].value).subscribe(data => {
        console.log(data);
        let v : Boolean = data.body ;
        console.log(v);
        if(v==true){
          this.snack.open(' Un mail de reinitialisation vous a été envoyez sur votre mail ', 'OK', { duration: 4000 })
          this.signinForm.reset() ;
        }else{
          this.snack.open('Echec veuillez verifier vos parametres ', 'OK', { duration: 4000 })
        }
        this.loader.close();

      }
    );

    this.submitButton.disabled = false;
    this.progressBar.mode = 'determinate';
    //this.router.navigate(['/dashboard']);
  }



  redirectUrl(){
    this.router.navigate(['/seconnecter']);
  }

}
