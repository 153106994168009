import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Utilisateur } from 'app/shared/models/utilisateur';
import { Newprofil } from 'app/shared/models/newprofil';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Profil } from '../../shared/models/profil';

@Component({
  selector: 'app-lierprofileuser',
  templateUrl: './lierprofileuser.component.html',
  styleUrls: ['./lierprofileuser.component.scss']
})
export class LierprofileuserComponent implements OnInit {


 

  
  public selectedUser: Utilisateur ;
  public itemsUs: any[];
  public items: any[];

  public itemsProf: any[];
  public selectedProf: Profil ;

  public itemForm: FormGroup;



  temp = [];
  public getItemSub: Subscription;

  constructor( private fb: FormBuilder,
               private dashboardservice:DashboardService,
               private dialog: MatDialog,
               private snack: MatSnackBar,
               private confirmService: AppConfirmService,
               private loader: AppLoaderService,
               private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.getItemsProfil();
    this.getItemsUtilisateur();
    this.itemForm = this.fb.group({
      'utilisateur': [null, Validators.compose([Validators.required])],
      'newprofil': [null, Validators.compose([Validators.required])],
    });
  }


  getItemsUtilisateur() {
    this.getItemSub = this.dashboardservice.findallusers()
      .subscribe(data => {
        this.itemsUs = data;
      })
  }


  getItemsProfil() {
    this.getItemSub = this.dashboardservice.getalusers()
      .subscribe(data => {
        this.itemsProf = data;
      })
  }


  public submit(): void {
    console.log(this.itemForm.value);
    if (this.itemForm.valid) {
      let us : Utilisateur = this.itemForm.get('utilisateur').value;
      let np : Profil = this.itemForm.get('newprofil').value;
      this.dashboardservice.lieruserprofil(us.utilisateurid,np.profilid).subscribe(data => {
          switch (data.status) {
            case 200: {
              this.loader.close();
              this.snack.open('operation effectuée avec succes !', 'OK', { duration: 4000 })
              break;
            }
            case 201: {

              this.loader.close();
              this.snack.open('operation effectuée avec succes', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }
        },
        error => {
          console.log('data');
          console.log(error);
          switch(error.status){
            case 400: {
              this.loader.close();
              this.snack.open('Mauvaise requete!', 'OK', { duration: 4000 })
              break;
            }
            case 500: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
            default: {
              this.loader.close();
              this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
              break;
            }
          }

        });
    }
  }



}
