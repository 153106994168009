import { CaissiereService } from './caissiere.service';
import { Subscription } from 'rxjs';
import { AppConfirmService } from './../../../shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from './../../../shared/services/app-loader/app-loader.service';
import { PopupcaissierComponent } from './popupcaissier/popupcaissier.component';
import { MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-caissier',
  templateUrl: './caissier.component.html',
  styleUrls: ['./caissier.component.scss']
})
export class CaissierComponent implements OnInit {

  getItemSub: Subscription;
  public items: any[];
  temp = [];


  constructor(private snack: MatSnackBar, private caissiereService: CaissiereService,
    private dialog: MatDialog, private confirmService: AppConfirmService,
    private loader: AppLoaderService) { }

  ngOnInit() {
    this.getData();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }


  getData() {
    this.snack.open('Recherche en cours...', 'OK', { duration: 4000 })

    this.getItemSub = this.caissiereService.findAllCaissiere()
      .subscribe(data => {

        let status = data.status;
        switch (status) {
          case 200:
            console.log('DATA: ', data);
            this.items = data.body;
            this.temp = data.body;
            this.snack.open('Chargement terminé', 'OK', { duration: 4000 })
            break;

          case 204:
            this.snack.open('Aucune ligne trouvé', 'OK', { duration: 4000 })
            break;

          default:
            this.items = data.body;
            this.snack.open('Chargement terminé', 'OK', { duration: 4000 })
            break;
        }



      }, error => {
        this.snack.open('Une erreur survenue, reessayer SVP', 'OK', { duration: 4000 });

      })
  }

  creationCaissier(data: any = {}, isNew?) {
    let title = isNew ? "Creation d'une caissiere" : "Mettre à jour la Caissiere";
    let dialogRef: MatDialogRef<any> = this.dialog.open(PopupcaissierComponent, {
      disableClose: true,
      width: '720px',
      data: { title: title, payload: data }
    })
    dialogRef.beforeClose()
      .subscribe(res => {
        if (!res) {
          // If user press cancel
          return;
        } else {
          this.getData(); // Rafraichir données
        }
      });
  }

  deleteItem(row) {
      this.confirmService.confirm({message: `Supprimé ${row.caissierelogin}?`})
      .subscribe(res => {
        if (res) {
          this.loader.open();
          this.caissiereService.deleteCaissiere(row.caissiereid)
            .subscribe(data => {
              let statuscode = data.status;

              switch (statuscode) {
                case 200: {
                  this.getData();
                  this.loader.close();
                  this.openDialog("le caissier a été supprimé avec succes");
                  break;
                }
                default: {
                  this.loader.close();
                  this.openDialog("le caissier a été supprimé avec succes");
                  break;
                }
              }

            },
            error => {
              let statuscode = error.status;

              switch (statuscode) {

                case 500: {
                  this.loader.close();
                  this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                  break;
                }
                default: {
                  this.loader.close();
                  this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                  break;
                }
              }


            });
        }


      })
  }


  openDialog(value : string): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: value
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }

}
