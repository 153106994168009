export class Caisseresponse {

	public  responsecode: string ;
	public  responsebody: string ;
	public  responsevouchercode: string ;
	public  responsetransactionid: string ;
	public  responsetransactiondate: string ;
	public  responsetransactionexpiration : string;

    
}
