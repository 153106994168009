import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gatewayrsp',
  templateUrl: './gatewayrsp.component.html',
  styleUrls: ['./gatewayrsp.component.scss']
})
export class GatewayrspComponent implements OnInit {


  private sub: any;

  order: string;

  order_id: string;
  status_id: string;
  transaction_id: string;
  transaction_amount: string;
  currency: string;
  paid_transaction_amount: string;
  paid_currency: string;
  change_rate: string;
  conflictual_transaction_amount: string;
  conflictual_currency: string;
  wallet: string;

  response: string;

  notsucces: boolean = false;

  constructor(public router:Router , private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.response = 'Votre commande est en cours de traitement ';
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.order_id = params['order_id'];
        this.status_id = params['status_id'];
        this.transaction_id = params['transaction_id'];
        this.transaction_amount = params['transaction_amount'];
        this.currency = params['currency'];
        this.paid_transaction_amount = params['paid_transaction_amount'];
        this.paid_currency = params['paid_currency'];
        this.change_rate = params['change_rate'];
        this.conflictual_transaction_amount = params['conflictual_transaction_amount'];
        this.conflictual_currency = params['conflictual_currency'];
        this.wallet = params['wallet'];
      });

  

    if (decodeURIComponent(this.status_id).trim() === "1") {
      this.notsucces = true;
      this.response = "PAIEMENT EFFECTUEE AVEC SUCCES";
    } else {
      this.response = "Echec !!! Erreur survenue lors de la transaction";
      this.notsucces=false ;
    }
  }

  redirect(value:any){
    this.router.navigate([value]);
  }

}
