import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Generaterecu } from 'app/shared/models/generaterecu';


const httpOptions = {
  headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
    .set("content-Type", "application/json")
};

@Injectable({
  providedIn: 'root'
})
export class AgencecaisseService {

  constructor(private http: HttpClient) { }

  public uploadrecutimbre = 'http://crossroadtest.net:9393/oneci/caissiere/uploadrecu/';
  private findallvente = 'http://crossroadtest.net:9393/oneci/caissiere/allventebyagence/';
  public uploadrecu = 'http://crossroadtest.net:8888/wallet/uploadrecu';



  public getAllVente(id: number): Observable<HttpResponse<any>> {
    console.log(this.findallvente + id);
    return this.http.get(this.findallvente + id, {
      observe: 'response'
    });
  }



  public getfacturerecu(value: Generaterecu) {

    return this.http.get<any>(this.uploadrecu + "/" + value.matriculecaissier + "/" + value.coderecu + "/" + value.typerecu + "/" + value.email + "/" + value.agence, httpOptions);
  }



  export(value: Generaterecu): Observable<HttpResponse<Blob>> {
    return this.http.get(this.uploadrecu + "/" + value.matriculecaissier + "/" + value.coderecu + "/" + value.typerecu + "/" + value.email + "/" + value.agence, { responseType: 'blob', observe: 'response' });
  }
}
