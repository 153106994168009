import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { SettingsService } from 'app/shared/services/settings.service';

@Component({
  selector: 'app-details-manager',
  templateUrl: './details-manager.component.html',
  styleUrls: ['./details-manager.component.scss']
})
export class DetailsManagerComponent implements OnInit {
  @Input() manager;
  response: any;
  constructor(private activeModal: NgbActiveModal, private _settings: SettingsService,
    private eventEmitter: EventEmitterService) { }

  ngOnInit() {
  }

  onSelectService(value){
    this.manager.service = value;
  }

  closeModal() {
    this.activeModal.close();
  }


}
