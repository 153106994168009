import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Profil } from '../../shared/models/profil';
import { Fonctionalite } from '../../shared/models/fonctionalite';

@Component({
  selector: 'app-creerprofile',
  templateUrl: './creerprofile.component.html',
  styleUrls: ['./creerprofile.component.scss']
})
export class CreerprofileComponent implements OnInit {

  public itemForm: FormGroup;
  mapNewprofil: Map<string, Fonctionalite>;


listercompteutilisateur : boolean = false ;
creercompteutilisateur : boolean = false ;
modifiercompteutilisateur : boolean = false ;
supprimercompteutilisateur : boolean = false ;
listerprofil : boolean = false ;
creerprofil : boolean = false ;
modifierprofil : boolean = false ;
lierutilisateuraprofil : boolean = false ;
reportingoperations : boolean = false ;
detailscompensations : boolean = false ;
supprimerprofil : boolean = false ;

virementrecuoperateur : boolean = false ;
montantvireaupartenaire : boolean = false ;
pourcentage : boolean = false ;



  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _router:Router,
    private dashboardservice : DashboardService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router:Router) { }

  ngOnInit() {


    this.listercompteutilisateur  = this.verifyMyMenu("listercompteutilisateur");
    this.creercompteutilisateur  = this.verifyMyMenu("creercompteutilisateur");
    this.modifiercompteutilisateur  = this.verifyMyMenu("modifiercompteutilisateur");
    this.supprimercompteutilisateur  = this.verifyMyMenu("supprimercompteutilisateur");
    this.listerprofil  = this.verifyMyMenu("listerprofil");
    this.creerprofil  = this.verifyMyMenu("creerprofil");
    this.modifierprofil  = this.verifyMyMenu("modifierprofil");
    this.lierutilisateuraprofil  = this.verifyMyMenu("lierutilisateuraprofil");
    this.reportingoperations  = this.verifyMyMenu("reportingoperations");
    this.detailscompensations  = this.verifyMyMenu("detailscompensations");
    this.supprimerprofil  = this.verifyMyMenu("supprimerprofil");

    this.virementrecuoperateur  = this.verifyMyMenu("virementrecuoperateur");
    this.montantvireaupartenaire  = this.verifyMyMenu("montantvireaupartenaire");

    this.pourcentage  = this.verifyMyMenu("pourcentage");




    this.itemForm = this.fb.group({
      profillibele :[ '', Validators.compose([Validators.required])],
      listercompteutilisateur: new FormControl(false),
      creercompteutilisateur: new FormControl(false),
      modifiercompteutilisateur: new FormControl(false),
      supprimercompteutilisateur: new FormControl(false),
      listerprofil: new FormControl(false),
      creerprofil: new FormControl(false),
      modifierprofil: new FormControl(false),
      lierutilisateuraprofil: new FormControl(false),
      reportingoperations: new FormControl(false),
      detailscompensations: new FormControl(false),
      supprimerprofil: new FormControl(false),
      virementrecuoperateur: new FormControl(false),
      montantvireaupartenaire: new FormControl(false),
      pourcentage: new FormControl(false)

    });
  }




  verifyMyMenu(value : string){
    console.log(localStorage.getItem(value.trim()));
    if(localStorage.getItem(value.trim())!=null){
      return true ;
    }else{
      return false ;
    }
  }


  redirectProfil(){
    this.router.navigate(['/listprofil']);
  }



  public submit(): void {
    console.log(this.itemForm.value);
    let b : Boolean = this.itemForm.get('listercompteutilisateur').value ;
    let c : Boolean = this.itemForm.get('creercompteutilisateur').value ;
    let d : Boolean = this.itemForm.get('modifiercompteutilisateur').value ;
    let e : Boolean = this.itemForm.get('supprimercompteutilisateur').value ;
    let f : Boolean = this.itemForm.get('listerprofil').value ;
    let g : Boolean = this.itemForm.get('creerprofil').value ;
    let h : Boolean = this.itemForm.get('modifierprofil').value ;
    let i : Boolean = this.itemForm.get('lierutilisateuraprofil').value ;
    let j : Boolean = this.itemForm.get('reportingoperations').value ;
    let k : Boolean = this.itemForm.get('detailscompensations').value ;
    let l : Boolean = this.itemForm.get('supprimerprofil').value ;
    let m : Boolean = this.itemForm.get('virementrecuoperateur').value ;
    let n : Boolean = this.itemForm.get('montantvireaupartenaire').value ;
    let o : Boolean = this.itemForm.get('pourcentage').value ;


    console.log("reportingoperations : "+j);
    console.log("detailscompensations : "+k);
    console.log("listercompteutilisateur : "+b);



    let npf : Profil = new Profil();
    npf.profillibele = this.itemForm.get('profillibele').value ;

    let fonctionalites : Fonctionalite[] = [] ;

    if(b==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('listercompteutilisateur');
      fonctionalites.push(fc);
    }

      if(c==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('creercompteutilisateur');
      fonctionalites.push(fc);
    }

     if(d==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('modifiercompteutilisateur');
      fonctionalites.push(fc);
    }

     if(e==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('supprimercompteutilisateur');
      fonctionalites.push(fc);
    }





      if(f==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('listerprofil');
      fonctionalites.push(fc);
    }

      if(g==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('creerprofil');
      fonctionalites.push(fc);
    }

     if(h==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('modifierprofil');
      fonctionalites.push(fc);
    }

     if(i==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('lierutilisateuraprofil');
      fonctionalites.push(fc);
    }





    if(j==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('reportingoperations');
      fonctionalites.push(fc);
    }

     if(k==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('detailscompensations');
      fonctionalites.push(fc);
      console.log("fc");
      console.log(fonctionalites);

    }

     if(l==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('supprimerprofil');
      fonctionalites.push(fc);
    }


    if(m==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('virementrecuoperateur');
      fonctionalites.push(fc);
      console.log("fc");
      console.log(fonctionalites);

    }

     if(n==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('montantvireaupartenaire');
      fonctionalites.push(fc);
    }
    if(o==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('pourcentage');
      fonctionalites.push(fc);
    }
    npf.fonctionnalites= fonctionalites ;

    console.log(" npf ");
    console.log(npf);   
    
    this.dashboardservice.createProfil(npf).subscribe(
      data => {
        switch (data.status) {
          case 200: {
            this.loader.close();
            this.snack.open('profil crée avec succes !', 'OK', { duration: 4000 })
            this.itemForm.reset();
            break;
          }
          case 201: {
            this.itemForm.reset();
            this.loader.close();
            this.snack.open('profil crée avec succes', 'OK', { duration: 4000 })
            break;
          }
          case 500: {
            this.loader.close();
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
      error => {
        console.log('data');
        console.log(error);
        switch(error.status){
          case 400: {
            this.loader.close();
            this.snack.open('Mauvaise requete!', 'OK', { duration: 4000 })
            break;
          }
          case 409: {
            this.loader.close();
            this.snack.open('Ce profil est deja utilise!', 'OK', { duration: 4000 })
            break;
          }
          case 500: {
            this.loader.close();
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
          default: {
            this.loader.close();
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }

      }
    );
  }


  


  foncMap(){
    this.mapNewprofil  = new Map();
    for (let f of this.generatefonctionalite()) this.mapNewprofil.set(f.fonctionalitelibele, f);
  }

  foncGetNom(val : string){
    return this.mapNewprofil.get(val);
  }

  public  generatefonctionalite(){
    let fs : Fonctionalite[] =[] ;
    fs = JSON.parse(localStorage.getItem("allfonctionnatite"));
    console.log("toutes mes fonctionalites ");
    console.log(fs);
    return fs;
  }


  convertDateInString(data : string){
    return data.replace("T"," ").substring(0, 16);
  }





}
