import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/services/auth.service';
import { SecuService } from 'app/shared/services/secu.service';
import swal from 'sweetalert2';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { Assure } from '../../../shared/models/assure';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'app-view-details-secu',
  templateUrl: './view-details-secu.component.html',
  styleUrls: ['./view-details-secu.component.scss']
})
export class ViewDetailsSecuComponent implements OnInit {
  urlView: any;
  numero_secu: any;
  listAssureCouvert: any = [];
  listCotisations: any = [];
  montant_du_assure: any;
  montant_du_ayant_droit: number = 0;
  montant_total_regle: any;
  montant_cotisation_individuelle: number = 0;
  montant_cotisation_familiale: number = 0;
  filter: any;
  pageAssureCouvert: number = 1;
  pageCotisations: number = 1;
  token: any;
  httpOptions;
  detailsSecu: any;
  assure: Assure = new Assure(null, null, null, null, null, null, null, null);
  response: any;
  constructor(private router: Router, private _auth: AuthenticationService,
    private _secu: SecuService, private variable: GlobalVariableService, private configService: ConfigService) { }

  ngOnInit() {
    let routing = this.router.url.split(';');
    let numero_secu = routing[1].split('=');
    this.numero_secu = numero_secu[1];
    this.show('assure-details');
    this.login();
    this.detailsSecu = this.variable.getDetailsSecu();
    this.assure.numero_secu = this.detailsSecu.secu_number;
    this.assure.civilite = this.detailsSecu.subscriber.libelle_civilite
    this.assure.nom = this.detailsSecu.subscriber.nom;
    this.assure.prenom = this.detailsSecu.subscriber.prenom;
    this.assure.date_naissance = this.detailsSecu.subscriber.date_naissance;
    this.assure.date_affiliation = this.detailsSecu.subscriber.date_affiliation;
    this.assure.genre = this.detailsSecu.subscriber.genre;
    this.assure.email = this.detailsSecu.subscriber.email;
  }

  show(vue) {
    this.urlView = vue;
    if (vue === 'liste-assures-couvert') {
      this.getListAssureCouvert(this.numero_secu);
    } else if (vue === 'cotisations') {
      this.getMontantCotisationFamilial();
      this.getMontantCotisationIndividuel();
      this.montant_du_ayant_droit = this.montant_cotisation_familiale - this.montant_cotisation_individuelle;
      this.getListCotisations(this.numero_secu);
    }
  }

  getListAssureCouvert(numero_secu) {
    const data = {
      'secu_number': numero_secu
    }
    this._secu.getListAssureCouvert(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.listAssureCouvert = res.data.fellows_list;
        }
        else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  getListCotisations(numero_secu) {
    const data = {
      'secu_number': numero_secu
    }
    this._secu.getListCotisations(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.montant_du_assure = res.data.amount_unpaid;
        }
        else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  searchNumeroSecu() {
    const data = {
      'secu_number': this.numero_secu
    }
    this._secu.getDetailsAssure(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.response = res.data;
          this.show('assure-details');
          this.assure.numero_secu = this.response.secu_number;
          this.assure.civilite = this.response.subscriber.libelle_civilite;
          this.assure.nom = this.response.subscriber.nom;
          this.assure.prenom = this.response.subscriber.prenom;
          this.assure.date_naissance = this.response.subscriber.date_naissance;
          this.assure.date_affiliation = this.response.subscriber.date_affiliation;
          this.assure.genre = this.response.subscriber.genre;
          this.assure.email = this.response.subscriber.email;
        }
        else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  getMontantCotisationIndividuel() {
    const data = {
      'secu_number': this.numero_secu
    }
    this._secu.getMontantCotisationIndividuel(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.montant_cotisation_individuelle = res.data.amount;
          this.montant_total_regle = res.data.amount;
        }
        else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  getMontantCotisationFamilial() {
    const data = {
      'secu_number': this.numero_secu
    }
    this._secu.getMontantCotisationFamilial(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.montant_cotisation_familiale = res.data.amount;
        }
        else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
        })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  login() {
    const data = {
      "auth": {
        "api_key": this.configService.get('API_KEY_CNAM'),
        "api_secret": this.configService.get('API_SECRET_CNAM')
      }
    };
    this._auth.loginCNAM(data)
      .subscribe(
        res => {
          if (res.api_code === 200) {
            this.token = res.auth_token;
            // Http Options
            this.httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
              })
            };
          }
          else {
            console.log('error');
          }
        },
        err => {
          console.log(err);
        }
    )
  }

  /**
   * Routing
   */

  valider() {
    this.router.navigate(['/form-paiement', { numero_secu: this.numero_secu }]);
  }

  cancel() {
    this.router.navigate(['/recherche-secu']);
  }

}
