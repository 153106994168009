import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from '../../../../shared/services/excel.service';
import swal from 'sweetalert2';
import { SettingsService } from 'app/shared/services/settings.service';
import { FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { Profile } from 'app/shared/models/profile';
import { GlobalVariableService } from '../../../../shared/services/global-variable.service';
import { GestionProfils } from 'app/shared/models/permissions';

@Component({
  selector: 'app-creation-profil',
  templateUrl: './creation-profil.component.html',
  styleUrls: ['./creation-profil.component.scss']
})
export class CreationProfilComponent implements OnInit {
  listProfile: any;
  listAgence: any;
  listPermissions: any;
  profileData: Profile = new Profile(null, null, null);
  public response: any;
  loaderText: string;
  listMenu: any = [];
  filter: any;
  p: number = 1;
  vueListProfil: boolean = true;
  vueFormGestionProfil: boolean = false;
  vueListPermissionsByProfil: boolean = false;
  operation: string;
  arrayPermissions: any = [];
  arrayPermissionsProfil: any = [];
  listPermissionsProfil: any = [];
  permissionsSettingsProfil: GestionProfils = new GestionProfils(null, null, null, null, null, null);
  titleProfil: string;
    typeProfile: string;
    idProfil;
  constructor(private fb: FormBuilder, private eventEmitter: EventEmitterService, private variable: GlobalVariableService,
    private modalService: NgbModal, private activeModal: NgbActiveModal, private http: HttpClient, private _router: Router, private _settings: SettingsService,
  private toastrService: ToastrService, private spinner: NgxSpinnerService, private excelService: ExcelService,
  ) { }

  showVueGestionProfil(operation, data) {
    this.operation = operation;
    this.vueListProfil = !this.vueListProfil;
    this.vueFormGestionProfil = !this.vueFormGestionProfil;
    if (operation === 'modification') {
      this.profileData.title = data.title;
      this.idProfil = data.id;
      data.is_agence ? this.profileData.is_agence = true : this.profileData.is_agence = false;
      // data.permissions.forEach((element) => {
      //     this.arrayPermissions.push({
      //         'permissions_id': element.id,
      //         'menus_id': element.menus_id,
      //     });
      // })
      // this.profileData.permissions = data.permissions;
      data.is_agence ? this.typeProfile = 'oui' : this.typeProfile = 'non';
      this.getAllProfileById(data.id);
    } else {
        this.profileData.title = null;
        this.getAllMenu();
    }
  }

  showDetailsPermissions(data, libelle) {
    console.log(data);
      if(data.permissions.length) {
        this.vueListProfil = !this.vueListProfil;
        this.vueListPermissionsByProfil = !this.vueListPermissionsByProfil;
        // this.listPermissionsProfil = data;
        this.getAllProfileById(data.id);
        this.titleProfil = libelle;
      } else {
          swal({
              title: 'CNAM SYSTEM',
              text: 'Aucune permission enregistrée pour ce profil',
              type: 'info',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
          })
      }
    }

    closeVueListPermissionsByProfil() {
        this.vueListProfil = !this.vueListProfil;
        this.vueListPermissionsByProfil = !this.vueListPermissionsByProfil;
    }

  closeVueFormGestionProfil() {
    this.vueListProfil = !this.vueListProfil;
    this.vueFormGestionProfil = !this.vueFormGestionProfil;
  }

  onSelectTypeProfil(value) {
    console.log(value);
    value === 'oui' ? this.profileData.is_agence = true : this.profileData.is_agence = false;
    console.log(this.profileData.is_agence);
  }

    // containsObject(id_row) {
    //     let i;
    //     for (i = 0; i < this.arrayPermissions.length; i++) {
    //         if (this.arrayPermissions[i].permissions_id === id_row) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }


    // isCheckedPermissions(state, permission, menu, index) {
    // console.log(state);
    // console.log(this.arrayPermissions);
    // const is_exist = this.containsObject(permission.id);
    //   if(!is_exist) {
    //       this.arrayPermissions.push({
    //           'permissions_id': permission.id,
    //           'menus_id': menu.id,
    //       });
    //   } else {
    //       this.arrayPermissions.splice(index, 1);
    //   }
    // }

  saveProfil(data) {
    let arrayPermissionsSave = [];
      data.forEach((element) => {
        element.permissions.forEach((item) => {
          if(item.is_checked) {
              arrayPermissionsSave.push({
                  'permissions_id': item.id,
                  'menus_id': item.menus_id,
              });
          }
        })
      });
      this.profileData.permissions = arrayPermissionsSave;
      this._settings.createProfile(this.profileData)
      .subscribe(
        res => {
          console.log(res);
          if (res.error === false) {
            this.activeModal.close();
            this.profileData.title = '';
            this.profileData.permissions = [];
            this.profileData.is_agence = null;
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result)  => {
              if (result) {
                this.activeModal.close();
                this.closeVueFormGestionProfil();
                this.getAllProfile();
              }
            });
          } else {
            swal({
              title: 'CNAM SYSTEM',
              text: res.message,
              type: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
      );
    }

    updateProfil(data) {
      let arrayPermissionsUpdate = [];
        data.forEach((element) => {
            element.permissions.forEach((item) => {
                if(item.is_checked) {
                    arrayPermissionsUpdate.push({
                        'permissions_id': item.id,
                        'menus_id': item.menus_id,
                    });
                }
            })
        });
        this.profileData.permissions = arrayPermissionsUpdate;
        this._settings.updateProfile(this.idProfil, this.profileData)
            .subscribe(
                res => {
                    console.log(res);
                    if (res.error === false) {
                        this.activeModal.close();
                        this.profileData.title = '';
                        this.profileData.permissions = [];
                        this.profileData.is_agence = null;
                        swal({
                            title: 'CNAM SYSTEM',
                            text: res.message,
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        }).then((result)  => {
                            if (result) {
                                this.activeModal.close();
                                this.closeVueFormGestionProfil();
                              this.getAllProfile();
                            }
                        });
                    } else {
                        swal({
                            title: 'CNAM SYSTEM',
                            text: res.message,
                            type: 'error',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok'
                        });
                    }
                },
                err => {
                    console.log(err);
                }
            );
    }

    deleteProfile(data) {
      swal({
        title: 'Êtes-vous sûr?',
        text: 'Vous ne pourrez pas revenir en arrière!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Non, Annuler!',
        confirmButtonText: 'Oui, supprimez-le!'
      }).then((result) => {
        if (result.value) {
          this._settings.deleteProfile(data.id)
          .subscribe(
            res => {
              console.log(res);
              if (res.error === false) {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'success',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                }).then((result)  => {
                  if (result) {
                    this.activeModal.close();
                    this.refresh();
                  }
                });
              } else {
                swal({
                  title: 'CNAM SYSTEM',
                  text: res.message,
                  type: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok'
                });
              }
            },
            err => {
              console.log(err);
            }
          );
        }
      });
    }

  closeModal() {
    this.activeModal.close();
  }

  getListPermissions() {
    this.arrayPermissionsProfil = this.variable.getPermissions();
    const create_profil = this.arrayPermissionsProfil.find(x => x.slug === 'creation-de-profil');
    const update_profil = this.arrayPermissionsProfil.find(x => x.slug === 'modification-de-profil');
    const suppression_profil = this.arrayPermissionsProfil.find(x => x.slug === 'suppression-de-profil');
    const exportation_profil = this.arrayPermissionsProfil.find(x => x.slug === 'exportation-des-profils');
    this.permissionsSettingsProfil.creation_profil = create_profil.is_visible;
    this.permissionsSettingsProfil.modification_profil = update_profil.is_visible;
    this.permissionsSettingsProfil.suppression_profil = suppression_profil.is_visible;
    this.permissionsSettingsProfil.exportation_profil = exportation_profil.is_visible;
  }


  // export to excel
  exportXls() {
    let data = [];
    this.listProfile.forEach(element => {
      data.push(
        {
          'Titre': element.title,
          'Agence': element.is_agence ? 'oui' : 'non',
        }
      )
    });
    this.listProfile.length > 0 ? this.excelService.exportAsExcelFile(data, 'Liste des profiles') : swal('CNAM SYSTEM', 'Aucune donnée dans le tableau', 'error');
  }

  // Get all profile
  getAllProfile() {
    this._settings.getAllProfil().subscribe((data: {}) => {
      this.response = data;
      this.listProfile = this.response.data;
    });
  }

    // Get all profile by Identifiant
    getAllProfileById(id) {
        this._settings.getAllProfilById(id).subscribe((data: {}) => {
            this.response = data;
            this.listMenu = this.response.data.menus;
        });
    }

    // Get all menu
    getAllMenu() {
        this._settings.getAllMenu().subscribe((data: {}) => {
            this.response = data;
            this.listMenu = this.response.data;
        });
    }

  // Get all agence
  getAllAgence() {
    this._settings.getAllAgence().subscribe((data: {}) => {
      this.response = data;
      this.listAgence = this.response.data;
    });
  }

  // Get all permissions
  getAllPermissions() {
    this._settings.getAllPermissions().subscribe((data: {}) => {
      this.response = data;
      this.listPermissions = this.response.data;
    });
  }

  refresh() {
    this.getAllProfile();
  }

  ngOnInit() {
    this.getListPermissions();
    this.getAllProfile();
    this.getAllAgence();
  }

}
