import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class AgenceService {


  private apiMS = 'http://crossroadtest.net:9393/oneci/';

  constructor(
    private http: HttpClient,
  ) { }

  public findAllAgence(): Observable<HttpResponse<any>> {
    return this.http.get(this.apiMS + 'caissiere/getallagence', {
      observe: 'response'
    });
  }

  public createAgence(value): Observable<HttpResponse<any>> {
    return this.http.post(this.apiMS + 'caissiere/createagence', value, {
      observe: 'response'
    });
  }

  public deleteAgence(value): Observable<HttpResponse<any>> {
    return this.http.delete(this.apiMS + 'caissiere/deleteagence/' + value, {
      observe: 'response'
    });
  }

  public updateAgence(value): Observable<HttpResponse<any>> {
    return this.http.put(this.apiMS + 'caissiere/updateagence', value, {
      observe: 'response'
    });
  }



  pushFileToStorage(file: File): Observable<HttpEvent<{}>> {

    //this.logger.trace("FileNull " + (file == null));
    let formdata: FormData = new FormData();
    formdata.append('file', file);


    let Params = new HttpParams();
    Params = Params.set('formdata', 'value');

    const req = new HttpRequest('POST', this.apiMS + 'caissiere/addrepertoire', formdata, {
      // const req = new HttpRequest('POST', this.repertoireUrl + '/' + 'add' + '/' + repertoire.repertoirename + '/' + file, {

      reportProgress: true,
      responseType: 'text',

    });
    /*this.logger.trace('REQ');
    this.logger.trace(req);*/
    return this.http.request(req);
  }

}
