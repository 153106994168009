export class Montantvireaupart {
      montantvireaupartid : number;
	  wallettoken : string;
	  ecomtoken : string;
	  montant : string;
	  dateinsertion : any;
	  datevalidemontant : any;
	  dateannee : number;
	  datemois : string;
	  wallet : string;
	  ecom : string;
	  dateclient:string;

}
