import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Fonctionalite } from '../../shared/models/fonctionalite';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Profil } from '../../shared/models/profil';
import { DISABLED } from '@angular/forms/src/model';

@Component({
  selector: 'app-modifierprofile',
  templateUrl: './modifierprofile.component.html',
  styleUrls: ['./modifierprofile.component.scss']
})
export class ModifierprofileComponent implements OnInit {


  public itemForm: FormGroup;
  mapNewprofil: Map<string, Fonctionalite>;


  listercompteutilisateur: boolean = false;
  creercompteutilisateur: boolean = false;
  modifiercompteutilisateur: boolean = false;
  supprimercompteutilisateur: boolean = false;
  listerprofil: boolean = false;
  creerprofil: boolean = false;
  modifierprofil: boolean = false;
  lierutilisateuraprofil: boolean = false;
  reportingoperations: boolean = false;
  detailscompensations: boolean = false;
  supprimerprofil: boolean = false;


  virementrecuoperateur: boolean = false;
  montantvireaupartenaire: boolean = false;
  pourcentage: boolean = false;



  listercompteutilisateurcb: boolean = false;
  creercompteutilisateurcb: boolean = false;
  modifiercompteutilisateurcb: boolean = false;
  supprimercompteutilisateurcb: boolean = false;
  listerprofilcb: boolean = false;
  creerprofilcb: boolean = false;
  modifierprofilcb: boolean = false;
  lierutilisateuraprofilcb: boolean = false;
  reportingoperationscb: boolean = false;
  detailscompensationscb: boolean = false;
  supprimerprofilcb: boolean = false;


  virementrecuoperateurcb: boolean = false;
  montantvireaupartenairecb: boolean = false;
  pourcentagecb: boolean = false;


  profilselect: Profil;

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private dashboardservice: DashboardService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router: Router) { }

  ngOnInit() {
    this.listercompteutilisateur = this.verifyMyMenu("listercompteutilisateur");
    this.creercompteutilisateur = this.verifyMyMenu("creercompteutilisateur");
    this.modifiercompteutilisateur = this.verifyMyMenu("modifiercompteutilisateur");
    this.supprimercompteutilisateur = this.verifyMyMenu("supprimercompteutilisateur");
    this.listerprofil = this.verifyMyMenu("listerprofil");
    this.creerprofil = this.verifyMyMenu("creerprofil");
    this.modifierprofil = this.verifyMyMenu("modifierprofil");
    this.lierutilisateuraprofil = this.verifyMyMenu("lierutilisateuraprofil");
    this.reportingoperations = this.verifyMyMenu("reportingoperations");
    this.detailscompensations = this.verifyMyMenu("detailscompensations");
    this.supprimerprofil = this.verifyMyMenu("supprimerprofil");

    this.virementrecuoperateur = this.verifyMyMenu("virementrecuoperateur");
    this.montantvireaupartenaire = this.verifyMyMenu("montantvireaupartenaire");
    this.pourcentage = this.verifyMyMenu("pourcentage");


    this.profilselect = JSON.parse(localStorage.getItem("currentprofiltoupdate"));
    localStorage.removeItem("currentprofiltoupdate");
    console.log(" profilselect ");
    console.log(this.profilselect);

    if (this.profilselect == null) {
      //this.loader.close();
      this.router.navigate(['/seconnecter']);
    }

    //this.loader.open();
    //this.getOneNewProfil(this.profilselect.profilid);
    this.getOneProfil();
  }

  verifyMyMenu(value: string) {
    console.log(localStorage.getItem(value.trim()));
    if (localStorage.getItem(value.trim()) != null) {
      return true;
    } else {
      return false;
    }
  }

  getOneProfil() {
    console.log(" profil ");
    console.log(this.profilselect.fonctionnalites.length);

    if (this.profilselect != null) {
      for (let f of this.profilselect.fonctionnalites) {

        if (f.fonctionalitelibele.trim() == "listercompteutilisateur") {
          console.log("ok");
          this.listercompteutilisateurcb = true;
        }

        if (f.fonctionalitelibele.trim() == "creercompteutilisateur") {
          console.log("ok");
          this.creercompteutilisateurcb = true;
        }

        if (f.fonctionalitelibele.trim() == "modifiercompteutilisateur") {
          console.log("ok");
          this.modifiercompteutilisateurcb = true;
        }


        if (f.fonctionalitelibele.trim() == "supprimercompteutilisateur") {
          console.log("ok");
          this.supprimercompteutilisateurcb = true;
        }

        if (f.fonctionalitelibele.trim() == "listerprofil") {
          console.log("ok");
          this.listerprofilcb = true;
        }

        if (f.fonctionalitelibele.trim() == "creerprofil") {
          console.log("ok");
          this.creerprofilcb = true;
        }

        if (f.fonctionalitelibele.trim() == "modifierprofil") {
          console.log("ok");
          this.modifierprofilcb = true;
        }

        if (f.fonctionalitelibele.trim() == "lierutilisateuraprofil") {
          console.log("ok");
          this.lierutilisateuraprofilcb = true;
        }

        if (f.fonctionalitelibele.trim() == "reportingoperations") {
          console.log("ok");
          this.reportingoperationscb = true;
        }

        if (f.fonctionalitelibele.trim() == "detailscompensations") {
          console.log("ok");
          this.detailscompensationscb = true;
        }

        if (f.fonctionalitelibele.trim() == "supprimerprofil") {
          console.log("ok");
          this.supprimerprofilcb = true;
        }





        if (f.fonctionalitelibele.trim() == "virementrecuoperateur") {
          console.log("ok");
          this.virementrecuoperateurcb = true;
        }
        if (f.fonctionalitelibele.trim() == "montantvireaupartenaire") {
          console.log("ok");
          this.montantvireaupartenairecb = true;
        }
        if (f.fonctionalitelibele.trim() == "pourcentage") {
          console.log("ok");
          this.pourcentagecb = true;
        }

      }




      console.log(" profil des fonctionalite cb ");

      this.itemForm = this.fb.group({
        profillibele: [this.profilselect.profillibele, Validators.compose([Validators.required])],
        listercompteutilisateur: new FormControl(this.listercompteutilisateurcb),
        creercompteutilisateur: new FormControl(this.creercompteutilisateurcb),
        modifiercompteutilisateur: new FormControl(this.modifiercompteutilisateurcb),
        supprimercompteutilisateur: new FormControl(this.supprimercompteutilisateurcb),
        listerprofil: new FormControl(this.listerprofilcb),
        creerprofil: new FormControl(this.creerprofilcb),
        modifierprofil: new FormControl(this.modifierprofilcb),
        lierutilisateuraprofil: new FormControl(this.lierutilisateuraprofilcb),
        reportingoperations: new FormControl(this.reportingoperationscb),
        detailscompensations: new FormControl(this.detailscompensationscb),
        supprimerprofil: new FormControl(this.supprimerprofilcb),

        virementrecuoperateur: new FormControl(this.virementrecuoperateurcb),
        montantvireaupartenaire: new FormControl(this.montantvireaupartenairecb),
        pourcentage: new FormControl(this.pourcentagecb)
      });

      


     

      console.log(" itemForm  cb ");


      console.log(" this.itemForm ");
      console.log(this.itemForm);

    }
  }



  getOneNewProfil(id: number) {
    this.dashboardservice.findoneprofil(id)
      .subscribe(data => {
        console.log("dashboard np ");

        let np: any = data.body;
        this.profilselect = new Profil();
        this.profilselect = np;

        console.log(np);



        for (let f of this.profilselect.fonctionnalites) {

          if (f.fonctionalitelibele.trim() == "listercompteutilisateur") {
            this.listercompteutilisateur = true;
          }

          if (f.fonctionalitelibele.trim() == "creercompteutilisateur") {
            this.creercompteutilisateur = true;
          }

          if (f.fonctionalitelibele.trim() == "modifiercompteutilisateur") {
            this.modifiercompteutilisateur = true;
          }


          if (f.fonctionalitelibele.trim() == "supprimercompteutilisateur") {
            this.supprimercompteutilisateur = true;
          }

          if (f.fonctionalitelibele.trim() == "listerprofil") {
            this.listerprofil = true;
          }

          if (f.fonctionalitelibele.trim() == "creerprofil") {
            this.creerprofil = true;
          }

          if (f.fonctionalitelibele.trim() == "modifierprofil") {
            this.modifierprofil = true;
          }

          if (f.fonctionalitelibele.trim() == "lierutilisateuraprofil") {
            this.lierutilisateuraprofil = true;
          }

          if (f.fonctionalitelibele.trim() == "reportingoperations") {
            this.reportingoperations = true;
          }

          if (f.fonctionalitelibele.trim() == "detailscompensations") {
            this.detailscompensations = true;
          }

          if (f.fonctionalitelibele.trim() == "supprimerprofil") {
            this.supprimerprofil = true;
          }

        }
        this.itemForm = this.fb.group({
          profillibele: [this.profilselect.profillibele, Validators.compose([Validators.required])],
          listercompteutilisateur: new FormControl(this.listercompteutilisateur),
          creercompteutilisateur: new FormControl(this.creercompteutilisateur),
          modifiercompteutilisateur: new FormControl(this.modifiercompteutilisateur),
          supprimercompteutilisateur: new FormControl(this.supprimercompteutilisateur),
          listerprofil: new FormControl(this.listerprofil),
          creerprofil: new FormControl(this.creerprofil),
          modifierprofil: new FormControl(this.modifierprofil),
          lierutilisateuraprofil: new FormControl(this.lierutilisateuraprofil),
          reportingoperations: new FormControl(this.reportingoperations),
          detailscompensations: new FormControl(this.detailscompensations),
          supprimerprofil: new FormControl(this.supprimerprofil)
        });


      });
  }





  foncMap() {
    this.mapNewprofil = new Map();
    for (let f of this.generatefonctionalite()) this.mapNewprofil.set(f.fonctionalitelibele, f);
  }

  foncGetNom(val: string) {
    return this.mapNewprofil.get(val);
  }

  public generatefonctionalite() {
    let fs: Fonctionalite[] = [];
    fs = JSON.parse(localStorage.getItem("allfonctionnatite"));
    console.log("toutes mes fonctionalites ");
    console.log(fs);
    return fs;
  }


  redirectProfil() {
    this.router.navigate(['/listprofil']);
  }




  
  public submit(): void {
    console.log(this.itemForm.value);
    let b : Boolean = this.itemForm.get('listercompteutilisateur').value ;
    let c : Boolean = this.itemForm.get('creercompteutilisateur').value ;
    let d : Boolean = this.itemForm.get('modifiercompteutilisateur').value ;
    let e : Boolean = this.itemForm.get('supprimercompteutilisateur').value ;
    let f : Boolean = this.itemForm.get('listerprofil').value ;
    let g : Boolean = this.itemForm.get('creerprofil').value ;
    let h : Boolean = this.itemForm.get('modifierprofil').value ;
    let i : Boolean = this.itemForm.get('lierutilisateuraprofil').value ;
    let j : Boolean = this.itemForm.get('reportingoperations').value ;
    let k : Boolean = this.itemForm.get('detailscompensations').value ;
    let l : Boolean = this.itemForm.get('supprimerprofil').value ;
    let m : Boolean = this.itemForm.get('virementrecuoperateur').value ;
    let n : Boolean = this.itemForm.get('montantvireaupartenaire').value ;
    let o : Boolean = this.itemForm.get('pourcentage').value ;


    console.log("reportingoperations : "+j);
    console.log("detailscompensations : "+k);
    console.log("listercompteutilisateur : "+b);



    let npf : Profil = new Profil();
    npf.profilid=this.profilselect.profilid;
    npf.profillibele = this.itemForm.get('profillibele').value ;

    let fonctionalites : Fonctionalite[] = [] ;

    if(b==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('listercompteutilisateur');
      fonctionalites.push(fc);
    }

      if(c==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('creercompteutilisateur');
      fonctionalites.push(fc);
    }

     if(d==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('modifiercompteutilisateur');
      fonctionalites.push(fc);
    }

     if(e==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('supprimercompteutilisateur');
      fonctionalites.push(fc);
    }





      if(f==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('listerprofil');
      fonctionalites.push(fc);
    }

      if(g==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('creerprofil');
      fonctionalites.push(fc);
    }

     if(h==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('modifierprofil');
      fonctionalites.push(fc);
    }

     if(i==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('lierutilisateuraprofil');
      fonctionalites.push(fc);
    }





    if(j==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('reportingoperations');
      fonctionalites.push(fc);
    }

     if(k==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('detailscompensations');
      fonctionalites.push(fc);
      console.log("fc");
      console.log(fonctionalites);

    }

     if(l==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('supprimerprofil');
      fonctionalites.push(fc);
    }


    if(m==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('virementrecuoperateur');
      fonctionalites.push(fc);
      console.log("fc");
      console.log(fonctionalites);

    }

     if(n==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('montantvireaupartenaire');
      fonctionalites.push(fc);
    }
    if(o==true){
      console.log(" is true ");
      this.foncMap();
      let fc : Fonctionalite = this.foncGetNom('pourcentage');
      fonctionalites.push(fc);
    }
    npf.fonctionnalites= fonctionalites ;

    console.log(" npf ");
    console.log(npf);   
    this.loader.open();

    this.dashboardservice.updateProfil(npf).subscribe(
      data => {
        switch (data.status) {
          case 200: {
            this.loader.close();
            this.snack.open('profil mis à avec succes !', 'OK', { duration: 4000 })
            this.itemForm.reset();
            break;
          }
          case 201: {
            this.itemForm.reset();
            this.loader.close();
            this.snack.open('profil mis à avec succes', 'OK', { duration: 4000 })
            break;
          }
          case 500: {
            this.loader.close();
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
          default: {
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
      error => {
        console.log('data');
        console.log(error);
        switch(error.status){
          case 400: {
            this.loader.close();
            this.snack.open('Mauvaise requete!', 'OK', { duration: 4000 })
            break;
          }
          case 409: {
            this.loader.close();
            this.snack.open('Ce profil est deja utilise!', 'OK', { duration: 4000 })
            break;
          }
          case 500: {
            this.loader.close();
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
          default: {
            this.loader.close();
            this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }

      }
    );
  }



}
