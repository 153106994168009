import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-caissier',
  templateUrl: './list-caissier.component.html',
  styleUrls: ['./list-caissier.component.scss']
})
export class ListCaissierComponent implements OnInit {
  @Input() caissiers;
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

}
