import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Utilisateur } from 'app/shared/models/utilisateur';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Profil } from '../../shared/models/profil';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listprofil',
  templateUrl: './listprofil.component.html',
  styleUrls: ['./listprofil.component.scss']
})
export class ListprofilComponent implements OnInit {

  public items: any[];
  temp = [];
  rows = [];
  columns = [];

  creerprofil : boolean = false ;
  modifierprofil : boolean = false ;
  supprimerprofil : boolean = false ;



  public getItemSub: Subscription;
  dataSource: MatTableDataSource<Profil> = new MatTableDataSource([]);

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router:Router
  ) {
  }

  ngOnInit() {
    this.creerprofil=this.verifyMyMenu("creerprofil");
    this.modifierprofil=this.verifyMyMenu("modifierprofil");
    this.supprimerprofil=this.verifyMyMenu("supprimerprofil");

    this.getItems();
  }


  
  getItems() {

    this.getItemSub = this.dashboardService.getalusers()
      .subscribe(data => {
        console.log("resultat");
        console.log(data);
        this.items = this.temp = data;
        this.dataSource = new MatTableDataSource(this.items);

      });

  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  redirectProfil(){
    this.router.navigate(['/creerprofil']);
  }

  
  convertDateInString(data : string){
    if(data!=null){
      return data.replace("T"," ").substring(0, 16);
    }else{
      return "";
    }  }






    deleteItem(row) {
      this.confirmService.confirm({message: `Voulez vous supprimer : ${row.intitule}?`})
        .subscribe(res => {
          if (res) {
            console.log(" item row selected ");
            console.log(row);

            this.loader.open();
            this.dashboardService.deleteProfil(row.profilid)
              .subscribe(data => {
                  switch (data.status) {
                    case 200: {
                      console.log('data');
                      console.log(data);
  
                      this.loader.close();
                      this.snack.open('profil Supprimé!', 'OK', { duration: 4000 })
                      break;
                    }
                    case 500: {
                      this.loader.close();
                      this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                      break;
                    }
                    default: {
                      this.loader.close();
                      this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                      break;
                    }
                  }
                  this.getItems();
                },
                error => {
                  console.log('data');
                  console.log(error);
                  switch (error.status) {
                    case 400: {
                      this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
                      break;
                    }
                    case 500: {
                      this.snack.open('Internal server error!', 'OK', { duration: 4000 })
                      break;
                    }
                    default: {
                      this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
                      break;
                    }
                  }
                  this.loader.close();
                  this.snack.open('Erreur!', 'OK', { duration: 4000 })
                });
          }
  
        })
    }


    redirectForUpdate(row:Profil){
      let val : Profil = row ;
      console.log(" redirectForUpdate ");
      console.log(val);

      localStorage.setItem("currentprofiltoupdate",JSON.stringify(val));
      this.router.navigate(['/modifierprofil']);
    }



    verifyMyMenu(value : string){
      console.log(localStorage.getItem(value.trim()));
      if(localStorage.getItem(value.trim())!=null){
        return true ;
      }else{
        return false ;
      }
    }

}
