export class Assure {
  numero_secu: any;
  civilite: any;
  nom: any;
  prenom: any;
  date_naissance: any;
  date_affiliation: any;
  genre: any;
  email: any;
  constructor(numero_secu: any, civilite: any, nom: any, prenom: any, date_naissance: any,
    date_affiliation: any, genre: any, email: any) {
    this.numero_secu = numero_secu;
    this.civilite = civilite;
    this.nom = nom;
    this.prenom = prenom;
    this.date_naissance = date_naissance;
    this.date_affiliation = date_affiliation;
    this.genre = genre;
    this.email = email;
  }
}