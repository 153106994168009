import { egretAnimations } from './../../shared/animations/egret-animations';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DashboardService } from 'app/shared/services/dashboard.service';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Caisseresponse } from 'app/shared/models/caisseresponse';
import { Caisserequest } from '../../shared/models/caisserequest';
import { Caissier } from '../../shared/models/caissier';
import { Generaterecu } from 'app/shared/models/generaterecu';
import { Usercaisse } from 'app/shared/models/usercaisse';
import { Caissiere } from 'app/shared/models/caissiere';

@Component({
  selector: 'app-generationvoucher',
  templateUrl: './generationvoucher.component.html',
  styleUrls: ['./generationvoucher.component.scss'],
  animations: egretAnimations
})
export class GenerationvoucherComponent implements OnInit {


  genvoucherForm: FormGroup;
  paymodeForm: FormGroup;
  responseachat: Caisseresponse;
  tokenFormGroup: FormGroup;
  statussend: Boolean = true;

  hideedan: string = 'password';
  vs: string = 'visibility_off';

  prixunitaire: number = 5000;

  typedemande: string[] = ['Nouvelle CNI', 'Renouvelement CNI'];
  selectedPaiement: any;

  moyenpaiement: string[] = ['Espece', 'Paiement Mobile'];

  selectedTypedemande: any;

  verifypaiement: boolean = false;
  modepaiement: boolean = false;
  confirmationpaiement: boolean = false;

  ventenow: Caissiere = null;


  constructor(private dialog: MatDialog,
    private snack: MatSnackBar,
    private dashboardService: DashboardService,
    private confirmService: AppConfirmService,
    private loader: AppLoaderService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe) { }

  ngOnInit() {
    this.genvoucherForm = new FormGroup({
      ventenom: new FormControl('', Validators.required),
      venteprenom: new FormControl('', Validators.required),
      //ventedatenaissance: new FormControl('', Validators.required),
      ventedatenaiss: new FormControl('', Validators.required),
      ventenommere: new FormControl(''),
      venteprenommere: new FormControl(''),
      ventetypedemande: new FormControl('', Validators.required),
      ventetelephone: new FormControl('', Validators.required),
      venteemail: new FormControl(''),
      ventenombre: new FormControl(1),
      ventelieunaiss: new FormControl('', Validators.required),
      ventecodesecret: new FormControl('', Validators.required)
    })


    this.paymodeForm = new FormGroup({
      venteid: new FormControl('', Validators.required),
      modepaiement: new FormControl('', Validators.required)
    });
    /*
    this.genvoucherForm.controls['montant'].valueChanges.subscribe((value) => {
      console.log(" valueChanges  ");
      let valeur_nbre : string = this.genvoucherForm.controls['montant'].value;
      if(valeur_nbre.trim().length>0 ){
        console.log(" most 0  ");
        valeur_nbre = valeur_nbre.split(" ").join("")
        this.genvoucherForm.controls['montant'].setValue(this.numberWithCommas(valeur_nbre));
      }
    })
    */
  }

  verifyStringInput(value: string) {
    if (value.trim().length > 0) {
      var ar = value.split('')
      console.log("finale value");
      console.log(ar);
      let stringfinal: string = "";

      ar.forEach(function (element) {
        stringfinal = stringfinal + element.trim;
      });

      console.log(" valueur finale : ");
      console.log(stringfinal);


      return stringfinal;
    } else {
      return "";
    }
  }

  onKey(event) { // without type info


    console.log(" key value : " + event.keyCode);

    console.log((event.keyCode > 95) && (event.keyCode < 106));

    var e = event || window.event;

    if ((event.keyCode > 95) && (event.keyCode < 106)) {

      let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
      if (valeur_nbre.trim().length > 0) {
        valeur_nbre = valeur_nbre.split(" ").join("");
        let gen: string = this.numberWithCommas(valeur_nbre);
        console.log(" gen : " + gen);
        this.genvoucherForm.controls['montant'].setValue(gen);
      }
    } if (event.keyCode === 8) {
      let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
      if (valeur_nbre.trim().length > 0) {
        valeur_nbre = valeur_nbre.split(" ").join("");
        let gen: string = this.numberWithCommas(valeur_nbre);
        console.log(" gen : " + gen);
        this.genvoucherForm.controls['montant'].setValue(gen);
      }

    } else {

      /*
      let valeur_nbre: string = this.genvoucherForm.controls['montant'].value;
      let length : number = valeur_nbre.length ;

      let value_final : string = valeur_nbre.substring(0, length-1);
      let gen : string =this.numberWithCommas(value_final) ;
      console.log(" gen : "+gen);
      this.genvoucherForm.controls['montant'].setValue(gen);
      */


      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();


    }

  }



  numberWithCommas(value: any) {
    if (value.length > 0) {
      return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }

  sendAchat() {
    let u = new Usercaisse();
    u = JSON.parse(localStorage.getItem("curentuser"));
    console.log(" curentuser : ", JSON.parse(localStorage.getItem("curentuser")));
    if (u != null) {

      let vente: Caissiere = this.genvoucherForm.value;
      vente.caissiereid = u.caissiereid;

      if(!vente.venteemail){
        vente.venteemail="   ";
      }

      if(!vente.ventenommere){
        vente.ventenommere="   ";
      }

      if(!vente.venteprenommere){
        vente.venteprenommere="   ";
      }

      console.log("venteForm");
      console.log(vente);

      let message: string = " vous vous appretez à effectuer un achat de " + vente.ventenombre + " timbre(s) de montant  total : " + this.prixunitaire * vente.ventenombre + " fcfa, Voulez vous confirmer l'opération ";

      this.confirmService.confirm({ message: message })
        .subscribe(res => {
          if (res) {
            this.loader.open();
            this.dashboardService.sendTimbre(vente)
              .subscribe(data => {
                switch (data.status) {
                  case 200: {
                    console.log(data);
                    this.verifypaiement = true;
                    this.modepaiement = true;
                    this.ventenow = data.body;
                    this.paymodeForm = new FormGroup({
                      venteid: new FormControl(this.ventenow.venteid, Validators.required),
                      modepaiement: new FormControl('', Validators.required)
                    });
                    this.loader.close();
                    break;
                  }
                  case 201: {
                    console.log(data);
                    this.loader.close();
                    break;
                  }

                }
              },
                error => {
                  console.log('data');
                  console.log(error);
                  switch (error.status) {
                    case 423: {
                      this.snack.open('Echec !!! code secret incorrect ', 'OK', { duration: 4000 })
                      this.loader.close();
                      break;
                    }
                    default: {
                      this.loader.close();
                      break;
                    }
                  }
                }
              )
          }
        });


    }
  }


  sendPaiement() {
    let value: any = this.paymodeForm.value;
    console.log(value);

    if (value.modepaiement === "Espece") {
      this.loader.open();

      this.dashboardService.sendAchat(value)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              this.verifypaiement = true;
              this.modepaiement = false;
              this.confirmationpaiement = true;
              this.ventenow = data.body;
              this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              this.loader.close();
              break;
            }

          }
        },
          error => {
            console.log('data');
            console.log(error);
            switch (error.status) {
              case 423: {
                this.snack.open('Echec !!! code secret incorrect ', 'OK', { duration: 4000 })
                this.loader.close();
                break;
              }
              default: {
                this.loader.close();
                break;
              }
            }
          }
        )
    }else if(value.modepaiement === "Paiement Mobile"){
      this.loader.open();

      this.dashboardService.sendAchat(value)
        .subscribe(data => {
          switch (data.status) {
            case 200: {
              console.log(data);
              
              this.ventenow = data.body;

              this.tokenFormGroup = this.fb.group({
                'currency': ['xof'],
                'operation_token': this.ventenow.operation_token,
                'order': this.ventenow.ventetransaction,
                'transaction_amount': this.ventenow.ventemotanttotal,
                'jwt': this.ventenow.auth_token
              });
    
              this.post(this.tokenFormGroup.value, this.ventenow.gateway_url);
              
              this.loader.close();
              break;
            }
            case 201: {
              console.log(data);
              this.loader.close();
              break;
            }

          }
        },
          error => {
            console.log('data');
            console.log(error);
            switch (error.status) {
              case 423: {
                this.snack.open('Echec !!! code secret incorrect ', 'OK', { duration: 4000 })
                this.loader.close();
                break;
              }
              default: {
                this.loader.close();
                break;
              }
            }
          }
        )
    }
  }


  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  newtransaction() {
    this.genvoucherForm = new FormGroup({
      ventenom: new FormControl('', Validators.required),
      venteprenom: new FormControl('', Validators.required),
      //ventedatenaissance: new FormControl('', Validators.required),
      ventedatenaiss: new FormControl('', Validators.required),
      ventenommere: new FormControl(''),
      venteprenommere: new FormControl(''),
      ventetypedemande: new FormControl('', Validators.required),
      ventetelephone: new FormControl('', Validators.required),
      venteemail: new FormControl(''),
      ventenombre: new FormControl(1),
      ventelieunaiss: new FormControl('', Validators.required),
      ventecodesecret: new FormControl('', Validators.required)
    })

    this.paymodeForm = new FormGroup({
      venteid: new FormControl('', Validators.required),
      modepaiement: new FormControl('', Validators.required)
    });   
    
    this.verifypaiement = !this.verifypaiement;
    this.ventenow = null;
  }

  getfacture() {

    let u = new Caissier();
    u = JSON.parse(localStorage.getItem("curentuser"));
    if (u != null) {

      console.log(" response achat ");
      console.log(this.responseachat);

      if (this.responseachat != null) {

        let req: Generaterecu = new Generaterecu();
        req.matriculecaissier = u.matricule;
        req.coderecu = this.responseachat.responsevouchercode;
        req.typerecu = "1";

        this.loader.open();
        this.dashboardService.getfacturerecu(req)
          .subscribe(data => {
            switch (data.status) {
              case 200: {
                console.log(data);
                this.loader.close();
                break;
              }
              case 201: {
                console.log(data);
                this.loader.close();
                break;
              }

            }
          },
            error => {
              console.log('data');
              console.log(error);
              switch (error.status) {

                default: {
                  this.loader.close();
                  break;
                }
              }
            }
          )

      }
    }
  }


  reinitAchatTimbre() {
    this.verifypaiement = false;
    this.modepaiement = false;
    this.confirmationpaiement = false;
    //this.genvoucherForm.reset();
    this.genvoucherForm = new FormGroup({
      ventenom: new FormControl('', Validators.required),
      venteprenom: new FormControl('', Validators.required),
      //ventedatenaissance: new FormControl('', Validators.required),
      ventedatenaiss: new FormControl('', Validators.required),
      ventenommere: new FormControl(''),
      venteprenommere: new FormControl(''),
      ventetypedemande: new FormControl('', Validators.required),
      ventetelephone: new FormControl('', Validators.required),
      venteemail: new FormControl(''),
      ventenombre: new FormControl(1),
      ventelieunaiss: new FormControl('', Validators.required),
      ventecodesecret: new FormControl('', Validators.required)
    })

    this.paymodeForm = new FormGroup({
      venteid: new FormControl('', Validators.required),
      modepaiement: new FormControl('', Validators.required)
    }); 
  }



  showorclosepassword() {
    if (this.hideedan === 'password') {
      this.hideedan = 'text';
      this.vs = 'visibility';
    } else if (this.hideedan === 'text') {
      this.hideedan = 'password';
      this.vs = 'visibility_off';
    } else {
      this.hideedan = 'password';
      this.vs = 'visibility_off';
    }
  }






  download(req: Caissiere) {
    /*
    let a = document.createElement("a");
    document.body.appendChild(a);
    let url: string;
    url = this.dashboardService.uploadrecutimbre;
    a.href = this.dashboardService.uploadrecutimbre + req.venteid;
    a.target = "_blank";
    a.download = "test.pdf";
    a.name = "test"
    a.click();
    document.body.removeChild(a);
    */
   var form = document.createElement("form");
   form.method = "GET";
   form.action = this.dashboardService.uploadrecutimbre + req.venteid;
   form.target = "_blank";
   document.body.appendChild(form);
   form.submit();
  }


}
