import { HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/services/auth.service';
import { SecuService } from 'app/shared/services/secu.service';
import Swal from 'sweetalert2';
import { GlobalVariableService } from '../../../shared/services/global-variable.service';
import { Assure } from '../../../shared/models/assure';
import * as jspdf from 'jspdf'; 
import html2canvas from 'html2canvas';
import { ConfigService } from 'ngx-envconfig';
import swal from 'sweetalert2';

@Component({
  selector: 'app-formulaire-paiement',
  templateUrl: './formulaire-paiement.component.html',
  styleUrls: ['./formulaire-paiement.component.scss']
})
export class FormulairePaiementComponent implements OnInit {
  @ViewChild('recuPaiement', {read: ElementRef}) recuPaiement: ElementRef;
  viewFormulairePaiement: boolean = false;
  viewResumeOperation: boolean = true;
  viewLoginInfo: boolean = true;
  viewModePaiement: boolean = true;
  viewRecuConfirmationPaiement: boolean = true;
  viewGenerationRecuPaiement: boolean = true;
  urlView: any;
  numero_secu: any;
  token: any;
  httpOptions;
  detailsSecu: any;
  selectedPaymentType: any;
  selectedModePaiement: any;
  isBtnValiderPaymentLoading: boolean = false;
  montant_ttc: any;
  montant_ht: any;
  numero_telephone: any;
  paye_par: string;
  response: any;
  username: any;
  password: any;
  numero_recu: any;
  date_of_day: string;
  idTransactions: any;
  referenceId: any;
  nom: any;
  prenom: any;
  date_naissance: any;
  lieu_naissance: any;
  user: any;
  constructor(private router: Router, private _auth: AuthenticationService,
    private _secu: SecuService, private variable: GlobalVariableService,
    private auth: AuthenticationService, private configService: ConfigService) { }

  ngOnInit() {
    let routing = this.router.url.split(';');
    let numero_secu = routing[1].split('=');
    this.numero_secu = numero_secu[1];
    this.login();
    var today = new Date();
    var dd = String(today.getDate());
    var mm = String(today.getMonth() + 1); 
    var yyyy = today.getFullYear();
    this.date_of_day = dd + '/' + mm + '/' + yyyy;
    this.user = JSON.parse(this.variable.getUser());

    
  }

//   public downloadRecu() {
//     var data = document.getElementById('recuPaiement');
//     html2canvas(data).then(canvas => {
//         // Few necessary setting options
//         var imgWidth = 208;
//         var pageHeight = 295;
//         var imgHeight = canvas.height * imgWidth / canvas.width;
//         var heightLeft = imgHeight;
//         const contentDataURL = canvas.toDataURL('image/png')
//         let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
//         var position = 0;
//         pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
//         pdf.save('Reçu_Paiement.pdf'); // Generated PDF
//     });
// }
  
public generateRecu() {
var data = document.getElementById('recuPaiement');
  html2canvas(data).then(canvas => {
    // Few necessary setting options
    const contentDataURL = canvas.toDataURL('image/png')
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    var position = 0;
    // Few necessary setting options
    var imgWidth = 208;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    pdf.save(`reçu_paiement_${this.nom}_${this.prenom}_${this.numero_secu}.pdf`); // Generated PDF
  });
}

  onSelectPaymentType(value) {
    this.selectedPaymentType = value;
  }

  show(url) {
    this.router.navigate(['/' + url]);
    // this.urlView = url;
  }

  onSelectModePaiement(value) {
    this.selectedModePaiement = value;
  }

  checkIfMontantIsMultiple(montant) {
    if (montant % 1000 == 0) return true
    else return false
  }

  goToHome() {
    this.router.navigate(['/mes-ventes']);
  }

  range(min, max) {
    return '0000' + Math.floor(Math.random() * (max - min) + min);
  }

  calculMontantTTC() {
    this.montant_ttc = this.montant_ht + (this.montant_ht * 5) / 100;
  }

  closeResume() {
    this.viewFormulairePaiement = !this.viewFormulairePaiement;
    this.viewResumeOperation = !this.viewResumeOperation;
  }

  closeLoginInfo() {
    this.viewLoginInfo = !this.viewLoginInfo;
    this.viewResumeOperation = !this.viewResumeOperation;
  }

  closeViewModePaiement() {
    this.viewLoginInfo = !this.viewLoginInfo;
    this.viewModePaiement = !this.viewModePaiement;
  }

  validerPayment() {
    this.viewLoginInfo = !this.viewLoginInfo;
    this.viewResumeOperation = !this.viewResumeOperation;
  }

  voirRecuPaiement() {
    this.viewRecuConfirmationPaiement = !this.viewRecuConfirmationPaiement;
    this.viewGenerationRecuPaiement = !this.viewGenerationRecuPaiement;
  }

  valider(montant) {
    if (!this.checkIfMontantIsMultiple(montant)) {
      swal({
        title: 'CNAM SYSTEM',
        text: 'Le montant HT doit être un multiple de 1000',
        type: 'info',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      });
    } else {
      this.viewResumeOperation = !this.viewResumeOperation;
      this.viewFormulairePaiement = !this.viewFormulairePaiement;
      this.getDetailsAssure();
    }
  }

  sign_in() {
    const data = {
      'username': this.username,
      'password': this.password,
      'stay_connected': false,
      'service': 'cnam'
    }
    this.auth.sign_in(data).subscribe(data => {
      switch (data.error) {
        case true:
          swal({
            title: 'CNAM SYSTEM',
            text: data.message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
          break;
        default: {
          swal({
            title: '<span style="color:#009DDF;">Confirmation de paiement</span>',
            text: `Vous vous apprêtez à effectuer un paiement de ${this.montant_ht} FCFA , montant total ${this.montant_ttc} FCFA. Voulez vous confirmez l'opération ?`,
            imageUrl: 'https://i.ibb.co/HDmW91w/icone-wallet.png',
            imageWidth: 175,
            showCancelButton: true,
            confirmButtonColor: '#009DDF',
            cancelButtonText: 'Annuler',
            cancelButtonClass: 'btn__cancel',
            confirmButtonText: 'Confirmer'
          }).then((result) => {
            if (result.value) {
              this.viewLoginInfo = !this.viewLoginInfo;
              this.viewModePaiement = !this.viewModePaiement;
            }
          })
          break;
        }
      }
    },
      error => {
        console.error(error);
      }
    );
  }

  getDetailsAssure() {
    const data = {
      'secu_number': this.numero_secu
    }
    this._secu.getDetailsAssure(data, this.httpOptions)
    .subscribe(
      res => {
        if (res.api_code === 200) {
          this.response = res.data;
          this.numero_telephone = this.response.subscriber.coordonnees.telephone;
          this.paye_par = this.response.subscriber.nom + ' ' + this.response.subscriber.prenom;
          this.nom = this.response.subscriber.nom;
          this.prenom = this.response.subscriber.prenom;
          this.date_naissance = this.response.subscriber.date_naissance;
          this.lieu_naissance = this.response.subscriber.lieu_naissance;
        } else {
          swal({
            title: 'CNAM SYSTEM',
            text: res.api_message,
            type: 'error',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      },
      err => {
        console.error(err);
      }
    )
  }

  payCotisations() {
    this.idTransactions = new Date().getTime();
    this.isBtnValiderPaymentLoading = true;
    const data = {
      "secu_number": this.numero_secu,
      "amount": this.montant_ht,
      "payment_type": this.selectedPaymentType,
      "msisdn": this.numero_telephone,
      "currency": 'xof',
      "transaction_id": this.idTransactions
    };
    this._secu.payCotisations(data, this.httpOptions)
      .subscribe(
        res => {
          this.isBtnValiderPaymentLoading = false;
            this.idTransactions = res.transactionId;
            this.referenceId = res.refId;
            swal({
              title: '<span style="color:#009DDF;">Paiement effectué avec succès</span>',
              text: `Vous venez d'effectuer un paiement de ${this.montant_ht} FCFA montant total  ${this.montant_ttc} FCFA avec succès.`,
              imageUrl: 'https://i.ibb.co/sqR80y9/icone-success.png',
              imageWidth: 175,
              showCancelButton: false,
              confirmButtonColor: '#009DDF',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((result) => {
              
            })
            this.numero_recu = this.range(0, 50);
            this.saveVente(res.msg);
        },
        err => {
          this.isBtnValiderPaymentLoading = false;
          if(err && err.error){
            swal({
              type:"error",
              title: "Erreur d'authentification",
              html: `<span style="font-weight: bold; color:#0a76d6">${err.error}</span>`,
              showCancelButton: false,
              confirmButtonColor: '#7D7D7D',
              confirmButtonText: 'OK'
            })
          } else{
            swal({
              type:"error",
              title: 'Votre solde est insuffisant',
              html: `Le solde de votre compte caisse est de :<span style="font-weight: bold; color:#0a76d6">  ${err}  FCFA.</span>`,
              showCancelButton: false,
              confirmButtonColor: '#7D7D7D',
              confirmButtonText: 'OK'
            })
          }
          
        },
        
    )
  }

  saveVente(statut) {
    const data = {
      "code_transaction": this.idTransactions,
      "numero_secure": this.numero_secu,
      "type_demande": this.selectedPaymentType,
      "mode_paiement": this.selectedModePaiement,
      "nom": this.nom,
      "prenoms": this.prenom,
      "date_naissance": this.date_naissance,
      "lieu_naissance": this.lieu_naissance,
      "telephone": this.numero_telephone,
      "montant": this.montant_ht,
      "frais": "5",
      "montant_total": this.montant_ttc,
      "telephone_paiement": this.numero_telephone,
      "transaction_id": this.idTransactions,
      "prepaid_reference_id":this.referenceId,
      "nombre": "1",
      "statut": statut
    };
    this._secu.saveVente(data)
      .subscribe(
        res => {
          if (res.error === false) {
            this.viewModePaiement = !this.viewModePaiement;
            this.viewRecuConfirmationPaiement = !this.viewRecuConfirmationPaiement;
          } else {
            swal({
              title: 'Erreur rencontrée',
              text: res.message,
              type: 'error',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          console.log(err);
        }
    )
  }

  login() {
    const data = {
        "auth": {
          "api_key": this.configService.get('API_KEY_CNAM'),
          "api_secret": this.configService.get('API_SECRET_CNAM')
        }
    };
    this._auth.loginCNAM(data)
      .subscribe(
        res => {
          if (res.api_code === 200) {
            this.token = res.auth_token;
            // Http Options
            this.httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token
              })
            };
          }
          else {
            console.log('error');
          }
        },
        err => {
          console.log(err);
        }
    )
  }

  /**
   * Routing
   */

  cancel() {
    this.router.navigate(['/details-secu', { numero_secu: this.numero_secu }]);
  }

}
