import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { CaissiereService } from '../../caissier/caissiere.service';
import { AgenceService } from '../../agence/agence.service';
import { DialognotifComponent } from 'app/shared/services/dialognotif/dialognotif.component';

@Component({
  selector: 'app-popupgest',
  templateUrl: './popupgest.component.html',
  styleUrls: ['./popupgest.component.scss']
})
export class PopupgestComponent implements OnInit {


  public itemForm: FormGroup;
  public getItemSub: Subscription;

  public itemsAgence: any[];



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupgestComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private caissiereService: CaissiereService,
    private snackbar: MatSnackBar,
    private agenceService: AgenceService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getAgence();
    this.buildItemForm(this.data.payload);
  }

  OnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe();
    }
  }

  buildItemForm(item) {

    this.itemForm = this.fb.group({
      caissiereid: [item.caissiereid || ''],
      caissierenom: [item.caissierenom || '', [Validators.required]],
      caissieretelephone: [item.caissieretelephone || '', [Validators.required]],
      caissiereemail: [item.caissiereemail || '', [Validators.required]],
      caissierelogin: [item.caissierelogin],
      //caissierepassword: [ item.caissierepassword || '', [Validators.required]],
      //agenceid: [item.agenceid || '', [Validators.required]]

    });
  }

  getAgence() {
    this.snack.open('Recherche en cours...', 'OK', { duration: 4000 })

    this.getItemSub = this.agenceService.findAllAgence()
      .subscribe(data => {

        let status = data.status;
        switch (status) {
          case 200:
            console.log('DATA: ', data);
            this.itemsAgence = data.body;
            break;

          case 204:
            this.snack.open('Aucune ligne trouvé', 'OK', { duration: 4000 })
            break;

          default:
            this.itemsAgence = data.body;
            break;
        }



      }, error => {
        this.snack.open('Une erreur survenue, reessayer SVP', 'OK', { duration: 4000 });

      })
  }

  submit() {
    // Verifier si Ajout ou mise a jour
    if (this.data.payload.caissiereid) {

      let value: any = this.itemForm.value;
      value.caissierelogin = value.caissiereemail;
      //this.itemForm.value

      this.getItemSub = this.caissiereService.updateCaissiere(value)
        .subscribe(data => {

          switch (data.status) {

            case 201: {
              /*
              this.snackbar.open('gestionnaire modifié', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                this.openDialog("La modification a été effectuée avec succes");
                */

              this.dialogRef.close(true);
              this.openDialog("La modification a été effectuée avec succes");


              break;
            }

            case 204: {
              /*
              this.snackbar.open('gestionnaire non trouvé, reessayé SVP', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close();
              */
             this.openDialog("La modification a été effectuée avec succes");


              break;
            }

            default: {

              break;
            }


          }
        },
          error => {

            switch (error.status) {

              case 409: {
                this.snackbar.open('gestionnaire existe deja', 'OK',
                  { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                break;
              }
              default: {
                this.snackbar.open('Un erreur est survenue', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });


    } else {

      let value: any = this.itemForm.value;
      value.caissierelogin = value.caissiereemail;

      console.log(this.itemForm.value);
      this.getItemSub = this.caissiereService.createAdmin(value)
        .subscribe(data => {

          console.log(" status ");
          console.log(data);


          switch (data.status) {
            case 200: {
              this.snackbar.open(' Félicitation ,le gestionnaire a été créé avec succès ! \n Consultez votre boîte email  ou votre courrier indésirable pour accéder  à vos acces de connexion. \n  L équipe POSTE CI', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close(true);
              break;
            }

            case 201: {
              this.snackbar.open('gestionnaire crée', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close(true);

              break;
            }

            case 204: {
              this.snackbar.open('gestionnaire non trouvé, reessayé SVP', 'OK',
                { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
              this.dialogRef.close();

              break;
            }

            default: {

              break;
            }


          }
        },
          error => {

            switch (error.status) {

              case 409: {
                this.snackbar.open('ce gestionnaire existe deja', 'OK',
                  { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                break;
              }
              default: {
                this.snackbar.open('Un erreur est survenue', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          });
    }
  }

  openDialog(value : string): void {
    const dialogRef = this.dialog.open(DialognotifComponent, {
      width: '410px',
      data: value
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        // DO SOMETHING
      }
    });
  }


}
