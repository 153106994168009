import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SettingsComponent } from "./settings/settings.component";
import { AnnulationOperationComponent } from "./settings/annulation-operation/annulation-operation.component";
import { CreationProfilComponent } from "./settings/creation-profil/creation-profil.component";
import { CreationUtilisateurComponent } from "./settings/creation-utilisateur/creation-utilisateur.component";
import { LierProfilComponent } from "./settings/lier-profil/lier-profil.component";
import { PisteAuditComponent } from "./settings/piste-audit/piste-audit.component";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination"; // <-- import the module
import { OrderModule } from "ngx-order-pipe";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ExcelService } from "../../shared/services/excel.service";
import { SettingsService } from "../../shared/services/settings.service";
import { EditUtilisateurComponent } from "./settings/creation-utilisateur/edit-utilisateur/edit-utilisateur.component";
import { DetailsUtilisateurComponent } from "./settings/creation-utilisateur/details-utilisateur/details-utilisateur.component";
import { SharedModule } from "app/shared/shared.module";
import { ManagerComponent } from "./manager/manager.component";
import { EditManagerComponent } from "./manager/edit-manager/edit-manager.component";
import { DetailsManagerComponent } from "./manager/details-manager/details-manager.component";
import { CashierComponent } from "./cashier/cashier.component";
import { DetailsCashierComponent } from "./cashier/details-cashier/details-cashier.component";
import { EditCashierComponent } from "./cashier/edit-cashier/edit-cashier.component";
import { AgencyComponent } from "./agency/agency.component";
import { EditAgencyComponent } from "./agency/edit-agency/edit-agency.component";
import { DetailsAgencyComponent } from "./agency/details-agency/details-agency.component";
import { ProfileComponent } from "./profile/profile.component";
import { RechercheNumeroSecuComponent } from "./recherche-numero-secu/recherche-numero-secu.component";
import { ViewDetailsSecuComponent } from "./view-details-secu/view-details-secu.component";
import { FormulairePaiementComponent } from "./formulaire-paiement/formulaire-paiement.component";
import { HistoriqueVenteComponent } from "./historique-vente/historique-vente.component";
import { DirectionComponent } from "./settings/direction/direction.component";
import { EditDirectionComponent } from "./settings/direction/edit-direction/edit-direction.component";
import { HistoriqueVenteGeneraleComponent } from "./historique-vente-generale/historique-vente-generale.component";
import { MesVentesComponent } from "./mes-ventes/mes-ventes.component";
import { InputMaxLengthDirective } from "./input-max-length.directive";
import { InspectionComponent } from "./settings/inspection/inspection.component";
import { EditInspectionComponent } from "./settings/inspection/edit-inspection/edit-inspection.component";
import { ListAgenceComponent } from "./settings/direction/list-agence/list-agence.component";
import { ListCaissierComponent } from "./agency/list-caissier/list-caissier.component";
import { DepositComponent } from "./deposit/deposit.component";
import { DepositDetailsComponent } from "./deposit/components/deposit-details/deposit-details.component";
import { GatewayrspComponent } from "./gatewayrsp/gatewayrsp.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    AngularMultiSelectModule,
    NgbModule.forRoot(),
    NgxPaginationModule,
    OrderModule,
    Ng2SearchPipeModule,
  ],
  declarations: [
    InputMaxLengthDirective,
    SettingsComponent,
    AnnulationOperationComponent,
    DirectionComponent,
    EditDirectionComponent,
    InspectionComponent,
    EditInspectionComponent,
    CreationProfilComponent,
    ManagerComponent,
    EditManagerComponent,
    DetailsManagerComponent,
    HistoriqueVenteComponent,
    CreationUtilisateurComponent,
    LierProfilComponent,
    PisteAuditComponent,
    EditUtilisateurComponent,
    DetailsUtilisateurComponent,
    CashierComponent,
    DetailsCashierComponent,
    HistoriqueVenteGeneraleComponent,
    MesVentesComponent,
    EditCashierComponent,
    AgencyComponent,
    EditAgencyComponent,
    DetailsAgencyComponent,
    ProfileComponent,
    RechercheNumeroSecuComponent,
    ViewDetailsSecuComponent,
    FormulairePaiementComponent,
    ListAgenceComponent,
    ListCaissierComponent,
    DepositComponent,
    DepositDetailsComponent,
    GatewayrspComponent
  ],
  entryComponents: [
    EditUtilisateurComponent,
    DetailsUtilisateurComponent,
    EditManagerComponent,
    DetailsManagerComponent,
    EditCashierComponent,
    DetailsCashierComponent,
    EditAgencyComponent,
    DetailsAgencyComponent,
    EditDirectionComponent,
    EditInspectionComponent,
    ListAgenceComponent,
    ListCaissierComponent,
    DepositDetailsComponent,
    GatewayrspComponent
  ],
  providers: [
    ExcelService,
    NgbActiveModal,
    ToastrService,
    SettingsService,
    NgxSpinnerService,
  ],
})
export class CnamModule {}
